import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  Card,
  Typography,
  FormControlLabel,
  Box,
  TextField,
  Input,
  IconButton,
  CircularProgress,
  Collapse,
  Checkbox,
} from '@mui/material';

import axios from '../../config/axiosConfig';
import { baseURLImage } from '../../utils/constants.js';
import TooltipButton from '../../utils/ui/TooltipComponent.jsx';
import AddEmailForm from '../forms/AddEmailForm.jsx';
import InputWithCurrency from '../../utils/ui/InputWithCurrency.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import { handleChangeFileInputArray } from '../../utils/functions/handleChangeFileInput';
import performRefreshUser from '../../utils/requests/refreshUser';
import { selectUser } from '../../store/features/userSlice';
import AddressFields from '../../utils/ui/AddressFieldsComponent';
import { trimObjectValues } from '../../utils/functions/trimObjectValues';

import {
  CustomInputs,
  CustomAutocomplete,
  CustomButton,
  CustomTelInputs,
} from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import fileIcon from '../../assets/icons/fileIcon.svg';
import dumpIcon from '../../assets/icons/dumpIcon.svg';
import fileIconDM from '../../assets/icons/fileIconDM.svg';
import dumpIconDM from '../../assets/icons/dumpIconDM.svg';
import congratulationsIcon from '../../assets/icons/congratulationsIcon.svg';
import bagImg from '../../assets/images/bag.png';
import storageboxImg from '../../assets/images/storagebox.png';
import flatImg from '../../assets/images/flat.png';
import packetImg from '../../assets/images/packet.png';
import packetReviImg from '../../assets/images/packet-revi.png';

function CreateAuctionAndRafflePageComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isSgMaxScreen, isSgScreen, isMdScreen } = useMediaQueries();
  const { productId: localProductId } = useParams();

  const user = useSelector(selectUser);

  const imperialUnitsCountries = ['United States of America', 'Liberia', 'Myanmar'];
  const imperialUnits = imperialUnitsCountries.includes(user?.country);
  const MAX_DESC_LENGTH = 1500;

  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryCheckbox, setSelectedCategoryCheckbox] = useState();
  const [userAbbrName, setUserAbbrName] = useState(user?.abbr_name);

  const [selectedDate, setSelectedDate] = useState();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [oldMedia, setOldMedia] = useState([]);

  const [amountOfTickets, setAmountOfTickets] = useState(0);
  const [salesTargetOfTickets, setSalesTargetOfTickets] = useState(0);
  const [price, setPrice] = useState(0);
  const [buyNow, setBuyNow] = useState();
  const [estimatedEarnings, setEstimatedEarnings] = useState();
  const [createCategoryValue, setCreateCategoryValue] = useState('');
  const [productId, setProductId] = useState(localProductId);
  const [newCategory, setNewCategory] = useState();
  const [minimalBid, setMinimalBid] = useState();
  const [step, setStep] = useState();
  const [reservePrice, setReservePrice] = useState();
  const [itemPrice, setItemPrice] = useState();
  const [itemsAmount, setItemsAmount] = useState();
  const [sellerPhone, setSellerPhone] = useState(user?.phone);

  const [sellerAddress, setSellerAddress] = useState({
    country: user?.shipping_address?.country,
    city: user?.shipping_address?.city,
    street: user?.shipping_address?.street,
    state: user?.shipping_address?.state,
    postalCode: user?.shipping_address?.postalCode,
  });

  const [sellerAddressErrors, setSellerAddressErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });

  const [parcel, setParcel] = useState({
    length: '',
    width: '',
    height: '',
    distanceUnit: imperialUnits ? 'inch' : 'centimeter',
    weight: '',
    massUnit: imperialUnits ? 'pound' : 'kilogram',
    isFragile: false,
    isInsured: false,
    isMultiple: false,
  });

  const [parcelErrors, setParcelErrors] = useState({
    length: '',
    width: '',
    height: '',
    weight: '',
  });

  const [activeParcelPreset, setActiveParcelPreset] = useState();

  const [createCategoryDialogOpen, setCreateCategoryDialogOpen] = useState(false);
  const [openSuccessForm, setOpenSuccessForm] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showAddEmailForm, setShowAddEmailForm] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCreateCategoryBtn, setLoadingCreateCategoryBtn] = useState(false);

  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [amountOfTicketsError, setAmountOfTicketsError] = useState('');

  const [selectedDateError, setSelectedDateError] = useState('');
  const [minimalBidError, setMinimalBidError] = useState('');
  const [buyItNowError, setBuyItNowError] = useState('');
  const [stepError, setStepError] = useState('');
  const [sellerPhoneError, setSellerPhoneError] = useState('');
  const [itemPriceError, setItemPriceError] = useState('');
  const [itemsError, setItemsError] = useState('');

  const [isRaffle, setIsRaffle] = useState(true);
  const [isMarketplace, setIsMarketplace] = useState(false);
  const [isQuantityEditOnly, setIsQuantityEditOnly] = useState(false);

  const titleRef = useRef(null);
  const sellerAddressRef = useRef(null);
  const selectedCategoryRef = useRef(null);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();

  const parcelPresets = useMemo(
    () => [
      {
        preset: 'Mini Box',
        length: imperialUnits ? 8 : 20,
        width: imperialUnits ? 4 : 10,
        height: imperialUnits ? 2 : 5,
        weight: imperialUnits ? 0.6 : 0.25,
        alt: 'mini box',
        src: packetReviImg,
      },
      {
        preset: 'Thin Box',
        length: imperialUnits ? 13 : 33,
        width: imperialUnits ? 9 : 23,
        height: imperialUnits ? 1 : 3,
        weight: imperialUnits ? 1 : 0.5,
        alt: 'thin box',
        src: flatImg,
      },
      {
        preset: 'Small Box',
        length: imperialUnits ? 13 : 33,
        width: imperialUnits ? 9 : 23,
        height: imperialUnits ? 2 : 6,
        weight: imperialUnits ? 4 : 2,
        alt: 'small box',
        src: bagImg,
      },
      {
        preset: 'Ord. Box',
        length: imperialUnits ? 16 : 40,
        width: imperialUnits ? 10 : 25,
        height: imperialUnits ? 8 : 20,
        weight: imperialUnits ? 9 : 4,
        alt: 'ord box',
        src: packetImg,
      },
      {
        preset: 'Moving Box',
        length: imperialUnits ? 28 : 70,
        width: imperialUnits ? 16 : 40,
        height: imperialUnits ? 16 : 40,
        weight: imperialUnits ? 55 : 25,
        alt: 'moving box',
        src: storageboxImg,
      },
    ],
    [imperialUnits]
  );

  const parcelOptions = parcelPresets.map((p, index) => (
    <Box
      className="flex flex-col items-center px-2 py-2"
      style={{
        width: isSgMaxScreen ? '60%' : '20%',
        cursor: 'pointer',
        textAlign: 'center',
        borderLeft: isSgMaxScreen ? 'none' : '1px solid #e9ecef',
        borderRight: isSgMaxScreen ? 'none' : '1px solid #e9ecef',
        borderTop: isSgMaxScreen ? '1px solid #e9ecef' : 'none',
        borderBottom: isSgMaxScreen ? '1px solid #e9ecef' : 'none',
        fontWeight: activeParcelPreset === index ? 'bold' : null,
        color: activeParcelPreset === index ? '#efcd23' : null,
      }}
      key={index}
      onClick={() => handleParcelPrepopulation(p)}
    >
      <img
        style={{
          maxWidth: '90%',
          height: '80px',
        }}
        alt={p.alt}
        src={p.src}
      />
      <p>
        {p.preset}
        <br />
        {p.length} x {p.width} x {p.height} {parcel.distanceUnit}
        <br />
        Up to {p.weight} {parcel.massUnit}
      </p>
    </Box>
  ));

  const handleSellerAddressChange = (field, value) => {
    setSellerAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));

    setSellerAddressErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleParcelPrepopulation = (preset) => {
    handleParcelChange('length', preset.length);
    handleParcelChange('width', preset.width);
    handleParcelChange('height', preset.height);
    handleParcelChange('weight', preset.weight);
  };

  const handleParcelChange = (field, value) => {
    setParcel((prevParcel) => ({
      ...prevParcel,
      [field]: value,
    }));
    setParcelErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  useEffect(() => {
    const parcelPresetMatchIndex = parcelPresets.findIndex(
      (el) =>
        el.length === Number(parcel.length) &&
        el.width === Number(parcel.width) &&
        el.height === Number(parcel.height) &&
        el.weight === Number(parcel.weight)
    );
    setActiveParcelPreset(parcelPresetMatchIndex >= 0 ? parcelPresetMatchIndex : null);
  }, [parcel, parcelPresets]);

  const handleFileInputChange = (event) => {
    handleChangeFileInputArray(event, selectedFiles, setSelectedFiles, showSnackbar);
  };

  const handleRemoveFile = (indexToRemove) => {
    if (indexToRemove < oldMedia.length) setOldMedia(oldMedia.filter((_, index) => index !== indexToRemove));
    else setSelectedFiles(selectedFiles.filter((_, index) => index !== indexToRemove - oldMedia.length));
    showSnackbar(null, 'Success', 'success');
  };

  const handleCreateCategory = () => {
    setLoadingCreateCategoryBtn(true);
    axios
      .post('category', {
        parent: null,
        name: {
          en: createCategoryValue.trim(),
        },
      })
      .then((response) => {
        const newCategoryFromServer = {
          _id: response.data._id,
          name: {
            en: createCategoryValue.trim(),
          },
        };
        setSelectedCategoryCheckbox(newCategoryFromServer._id);
        setIsCheckboxChecked(true);
        setCreateCategoryValue('');
        setNewCategory(newCategoryFromServer);
        setLoadingCreateCategoryBtn(true);
        setCreateCategoryDialogOpen(false);
        setCategoryError('');
        showSnackbar(null, 'Success', 'success');
      })
      .catch((error) => showSnackbar(error));
  };

  const handleDeleteCategory = () => {
    setSelectedCategoryCheckbox(null);
    setIsCheckboxChecked(false);
    setNewCategory(null);
    showSnackbar(null, 'Success', 'success');
  };

  const handlePriceChange = (newPrice) => {
    setPrice(newPrice);
    const newEstimatedEarnings = newPrice * amountOfTickets;

    setEstimatedEarnings(newEstimatedEarnings);
  };

  const handleAmountOfTicketsChange = (newAmount) => {
    setAmountOfTickets(newAmount);
    const newEstimatedEarnings = price * newAmount;

    setEstimatedEarnings(newEstimatedEarnings);
  };

  const chooseDateAutocompleteOptions = () => {
    const calcTime = (amountOfDays) => new Date(Date.now() + amountOfDays * 24 * 60 * 60 * 1000);
    const options = [];
    if (!isRaffle) {
      for (let i = 1; i <= 7; i++) {
        options.push({
          label: `${i} ${i === 1 ? 'day' : 'days'}`,
          value: calcTime(i),
        });
      }
      options.push(
        { label: '14 days', value: calcTime(14) },
        { label: '1 month', value: calcTime(30) },
        { label: '3 month', value: calcTime(90) },
        { label: '6 month', value: calcTime(180) },
        { label: '1 year', value: calcTime(365) }
      );
    } else
      for (let i = 1; i <= 21; i++) {
        options.push({
          label: `${i} ${i === 1 ? 'day' : 'days'}`,
          value: calcTime(i),
        });
      }
    return options;
  };

  const setProductData = (p) => {
    setIsRaffle(p.productInfo.kind === 'Raffle');
    setIsMarketplace(p.productInfo.kind === 'Marketplace');
    setIsQuantityEditOnly(p.productInfo.kind === 'Marketplace' && p.status === 'accepted');
    setTitle(p.title);
    setDescription(p.description);
    p.category && setSelectedCategory(p.category);

    p.productInfo?.price && setPrice(p.productInfo.price);
    p.productInfo?.amount && setAmountOfTickets(p.productInfo?.amount);
    p.productInfo?.salesTarget && setSalesTargetOfTickets(p.productInfo?.salesTarget);

    p.productInfo?.buy_now && setBuyNow(p.productInfo?.buy_now);
    p.productInfo?.minimum && setMinimalBid(p.productInfo?.minimum);
    p.productInfo?.step && setStep(p.productInfo?.step);
    p.productInfo?.reservePrice && setReservePrice(p.productInfo?.reservePrice);

    p.productInfo?.item_price && setItemPrice(p.productInfo?.item_price);
    p.productInfo?.items_amount && setItemsAmount(p.productInfo?.items_amount);

    if (p.sellerAddressId) {
      const sellerAddressInfo = {};

      p.sellerAddressId.country && (sellerAddressInfo.country = p.sellerAddressId.country);
      p.sellerAddressId.city && (sellerAddressInfo.city = p.sellerAddressId.city);
      p.sellerAddressId.street && (sellerAddressInfo.street = p.sellerAddressId.street);
      p.sellerAddressId.state && (sellerAddressInfo.state = p.sellerAddressId.state);
      p.sellerAddressId.postalCode && (sellerAddressInfo.postalCode = p.sellerAddressId.postalCode);

      setSellerAddress((prevSellerAddress) => ({
        ...prevSellerAddress,
        ...sellerAddressInfo,
      }));
    }

    if (p.parcelId) {
      const parcelInfo = {};

      p.parcelId.length && (parcelInfo.length = p.parcelId.length);
      p.parcelId.width && (parcelInfo.width = p.parcelId.width);
      p.parcelId.height && (parcelInfo.height = p.parcelId.height);
      p.parcelId.distanceUnit && (parcelInfo.distanceUnit = p.parcelId.distanceUnit);
      p.parcelId.weight && (parcelInfo.weight = p.parcelId.weight);
      p.parcelId.massUnit && (parcelInfo.massUnit = p.parcelId.massUnit);
      p.parcelId.isFragile && (parcelInfo.isFragile = p.parcelId.isFragile);
      p.parcelId.isInsured && (parcelInfo.isInsured = p.parcelId.isInsured);
      p.parcelId.isMultiple && (parcelInfo.isMultiple = p.parcelId.isMultiple);

      setParcel((prevParcel) => ({
        ...prevParcel,
        ...parcelInfo,
      }));
    }

    if (p.end) {
      const startDate = new Date();
      const endDate = new Date(p.end);

      const timeDifference = Math.abs(endDate - startDate);
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      let labelInfo = '';

      if (daysDifference <= 31) {
        const s = daysDifference <= 1 ? 'day' : 'days';
        labelInfo = `${daysDifference} ${s}`;
      } else if (daysDifference < 365) {
        const months = Math.floor(daysDifference / 30);
        return months === 1 ? `${months} month` : `${months} months`;
      } else {
        const years = Math.floor(daysDifference / 365);
        return years === 1 ? `${years} year` : `${years} years`;
      }

      setSelectedDate({
        label: labelInfo,
        value: endDate,
      });
    }

    if (p.media) setOldMedia(p.media.map((f) => baseURLImage + f));
  };

  const handleFetchData = useCallback(async () => {
    setLoadingData(true);

    try {
      const canEditResponse = await axios.get(`product/${productId}/can-edit`);

      if (!canEditResponse?.data?.canEdit) throw new Error('Forbidden');

      const [categoryResponse, countryResponse, productResponse] = await Promise.all([
        axios.get('category/list?status=accepted'),
        axios.get('country'),
        axios.get(`product/${productId}/for-edit`),
        performRefreshUser(dispatch),
      ]);

      setProductData(productResponse.data);

      setCategories(
        categoryResponse.data
          .filter((item) => item.parent != null)
          .sort((a, b) => a?.name?.en.localeCompare(b?.name?.en))
      );
      setCountries(countryResponse.data);

      setLoadingData(false);
    } catch (error) {
      showSnackbar(error);

      navigate(`/product/${productId}`);
    }
  }, [productId, dispatch, navigate]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  const handleProductData = async () => {
    const fieldIsRequiredMsg = `${t('Field is required')}`;
    const MAX_TITLE_LENGTH = 250;

    let hasError = false;
    let productInfo;

    let fieldsToCheck = [
      { value: title, setError: setTitleError },
      { value: description, setError: setDescriptionError },
      { value: sellerAddress.country, setError: setSellerAddressErrors, fieldName: 'country' },
      { value: sellerAddress.city, setError: setSellerAddressErrors, fieldName: 'city' },
      { value: sellerAddress.street, setError: setSellerAddressErrors, fieldName: 'street' },
      { value: sellerAddress.postalCode, setError: setSellerAddressErrors, fieldName: 'postalCode' },
      { value: sellerPhone, setError: setSellerPhoneError },
      { value: parcel.weight, setError: setParcelErrors, fieldName: 'weight' },
      { value: parcel.height, setError: setParcelErrors, fieldName: 'height' },
      { value: parcel.length, setError: setParcelErrors, fieldName: 'length' },
      { value: parcel.width, setError: setParcelErrors, fieldName: 'width' },
    ];

    if (isMarketplace)
      fieldsToCheck.push(
        { value: itemsAmount, setError: setItemsError },
        { value: itemPrice, setError: setItemPriceError }
      );
    else
      fieldsToCheck.push(
        { value: selectedDate?.value, setError: setSelectedDateError },
        ...(isRaffle
          ? [
              { value: amountOfTickets, setError: setAmountOfTicketsError },
              { value: price, setError: setPriceError },
            ]
          : [{ value: minimalBid, setError: setMinimalBidError }])
      );

    if (!isCheckboxChecked)
      fieldsToCheck.push({
        value: selectedCategory,
        setError: setCategoryError,
      });

    fieldsToCheck.forEach(({ value, setError, fieldName }) => {
      if (!value || (typeof value === 'string' && !value.trim())) {
        if (fieldName)
          setError((prevErrors) => ({
            ...prevErrors,
            [fieldName]: fieldIsRequiredMsg,
          }));
        else setError(fieldIsRequiredMsg);
        hasError = true;
      } else if (value === title && value.length > MAX_TITLE_LENGTH) {
        setError(`Title must be less than ${MAX_TITLE_LENGTH} characters`);
        hasError = true;
      } else if (value === description && value.length > MAX_DESC_LENGTH) {
        setError(`Description must be less than ${MAX_DESC_LENGTH} characters`);
        hasError = true;
      }
    });

    if (buyNow && Number(buyNow) <= Number(minimalBid)) {
      setBuyItNowError('Buy it now must be greater than Opening bid');
      hasError = true;
    }

    if (hasError) {
      selectedCategoryRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (isRaffle)
      productInfo = {
        kind: 'Raffle',
        amount: amountOfTickets,
        total_amount: amountOfTickets,
        salesTarget: salesTargetOfTickets,
        price,
      };
    else if (isMarketplace)
      productInfo = {
        kind: 'Marketplace',
        item_price: itemPrice,
        items_amount: itemsAmount,
      };
    else
      productInfo = {
        kind: 'Auction',
        buy_now: buyNow,
        minimum: minimalBid,
        step: step,
        reservePrice,
      };

    try {
      setLoadingButton(true);

      if (
        user.phone !== sellerPhone ||
        user.shipping_address?.country !== sellerAddress.country ||
        user.shipping_address?.city !== sellerAddress.city ||
        user.shipping_address?.state !== sellerAddress.state ||
        user.shipping_address?.street !== sellerAddress.street ||
        user.shipping_address?.postalCode !== sellerAddress.postalCode
      ) {
        const updateUserObj = {};

        if (user.phone !== sellerPhone) updateUserObj['phone'] = sellerPhone;
        else updateUserObj['shipping_address'] = trimObjectValues(sellerAddress);

        await axios.put('user/me', updateUserObj);
      }

      const projectData = {
        title: title.trim(),
        description: description.trim(),
        productInfo,
        category: isCheckboxChecked ? selectedCategoryCheckbox : selectedCategory._id,
        country: countries.find((el) => el.iso === sellerAddress.country)._id,
        currency: user.currency,
        end: selectedDate?.value,
        parcel: trimObjectValues(parcel),
        sellerAddress: trimObjectValues(sellerAddress),
        media: oldMedia.map((url) => url.replace(baseURLImage, '')),
      };

      await axios.put(`product/${productId}/edit`, projectData);

      if (selectedFiles.length > 0) {
        const formData = new FormData();

        selectedFiles.forEach((file) => formData.append(`files`, file));

        await axios.post(`product/${productId}/uploads`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      setLoadingButton(false);
      setOpenSuccessForm(true);
      showSnackbar(null, 'Success', 'success');
    } catch (error) {
      if (error.response?.data?.status === 205) {
        const respObj = JSON.parse(error.response?.data?.response);
        setSellerAddress(respObj.recommendedAddress);
        showSnackbar({ response: { data: { message: respObj.message } } });
      } else showSnackbar(error);
      (error.response?.data?.message?.includes('address') ? sellerAddressRef : titleRef).current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    setUserAbbrName(user?.abbr_name);
  }, [user, dispatch]);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Box className="mb-20 px-2 flex justify-center">
        {loadingData ? (
          <Box className="mt-6 flex flex-col justify-center items-center">
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <Box className="mt-6 flex justify-center flex-col">
              <Card
                className="p-4 min-[870px]:p-8 pb-8 mt-10"
                sx={{
                  boxShadow: '0px 4px 4px 0px #0000001A',
                  maxWidth: 865,
                  borderRadius: '10px',
                }}
              >
                {!isQuantityEditOnly && (
                  <>
                    <Typography ref={titleRef} fontSize={18}>
                      {t('Title')}
                    </Typography>
                    <CustomInputs
                      placeholder={t('E.g. Air Jordan 1 High x Travis Scott')}
                      variant="outlined"
                      type="text"
                      value={title}
                      required
                      sx={{ mt: 1 }}
                      style={{
                        width: isMdScreen ? '92vw' : '86vw',
                        maxWidth: 788,
                      }}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setTitleError('');
                      }}
                      helperText={titleError ? titleError : ' '}
                      error={!!titleError}
                    />
                    <Box>
                      <Typography fontSize={18}>{t('Description')}</Typography>
                      <CustomInputs
                        placeholder={t('Type short description')}
                        variant="outlined"
                        type="text"
                        value={description}
                        required
                        multiline
                        rows={4}
                        sx={{ mt: 1, height: 150, width: '100%' }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        InputProps={{
                          style: {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                          },
                          maxLength: MAX_DESC_LENGTH,
                        }}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setDescriptionError('');
                        }}
                        helperText={
                          descriptionError ? descriptionError : `${description.length}/${MAX_DESC_LENGTH} characters`
                        }
                        error={!!descriptionError}
                      />
                    </Box>
                    <Box ref={selectedCategoryRef}>
                      <Typography fontSize={18}>{t('Choose a category')}</Typography>
                      <Box>
                        <CustomAutocomplete
                          value={isCheckboxChecked ? null : selectedCategory}
                          onChange={(event, newValue) => {
                            setSelectedCategory(newValue);
                            setCategoryError('');
                          }}
                          options={categories}
                          getOptionLabel={(category) => category?.name?.en}
                          sx={{ mt: 1, width: '100%' }}
                          style={{
                            width: isMdScreen ? '92vw' : '86vw',
                            maxWidth: 788,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Choose category')}
                              InputProps={{
                                ...params.InputProps,
                              }}
                              helperText={categoryError ? categoryError : ' '}
                              error={!!categoryError}
                            />
                          )}
                          disabled={isCheckboxChecked}
                        />
                        <Box>
                          {newCategory ? (
                            <Box className="flex">
                              <FormControlLabel
                                key={newCategory._id}
                                label={newCategory.name.en}
                                control={
                                  <Checkbox
                                    value={newCategory._id}
                                    checked={isCheckboxChecked}
                                    onChange={(e) => {
                                      setIsCheckboxChecked(e.target.checked);
                                      setSelectedCategoryCheckbox(e.target.value);
                                      setCategoryError('');
                                    }}
                                  />
                                }
                              />
                              <IconButton onClick={handleDeleteCategory} sx={{ width: 48, height: 48 }}>
                                <img
                                  src={isDarkMode ? dumpIconDM : dumpIcon}
                                  alt="Dump Icon"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              className="flex justify-start"
                              onClick={() => setCreateCategoryDialogOpen(true)}
                            >
                              {t('My custom category')}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Box className="mt-8">
                  {!isRaffle && !isMarketplace && (
                    <>
                      <Typography component="div" fontSize={18} className="flex">
                        {t('Opening bid')}
                        <TooltipButton
                          marginBottom={4}
                          title={t(
                            'Your starting or opening bid is the suggested minimum bid for an auction item. Please note that 5% platform fee will be charged from your earnings'
                          )}
                        />
                      </Typography>
                      <InputWithCurrency
                        placeholder="12,00"
                        type="number"
                        value={minimalBid}
                        sx={{ width: '100%', mt: 1 }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        handleChange={setMinimalBid}
                        error={minimalBidError}
                        setError={setMinimalBidError}
                        currencyAbrName={userAbbrName}
                      />
                      <Typography component="div" fontSize={18} className="flex">
                        {t('Minimum raise')}
                        <TooltipButton
                          marginBottom={4}
                          title={t(
                            'The minimum raise is the minimum amount by which a new bidder must bid over current leading bid to become the new leader'
                          )}
                        />
                      </Typography>
                      <CustomInputs
                        placeholder="10"
                        variant="outlined"
                        type="number"
                        value={step}
                        onWheel={(e) => e.target.blur()}
                        sx={{ width: '100%', mt: 1 }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        onChange={(e) => {
                          setStep(e.target.value);
                          setStepError('');
                        }}
                        helperText={stepError ? stepError : ' '}
                        error={!!stepError}
                      />
                      <Typography component="div" fontSize={18} className="flex">
                        {t('Reserve price')}
                        <TooltipButton
                          marginBottom={4}
                          title={t(
                            'A reserve price is a minimum price that a seller would be willing to accept from a buyer'
                          )}
                        />
                      </Typography>
                      <CustomInputs
                        placeholder="10"
                        variant="outlined"
                        type="number"
                        value={reservePrice}
                        onWheel={(e) => e.target.blur()}
                        sx={{ width: '100%', mt: 1 }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        onChange={(e) => setReservePrice(e.target.value)}
                        helperText={' '}
                      />
                      <Typography fontSize={18}>{t('Buy it now for')}</Typography>
                      <InputWithCurrency
                        placeholder="123.456,00"
                        type="number"
                        value={buyNow}
                        sx={{ width: '100%', mt: 1 }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        handleChange={setBuyNow}
                        error={buyItNowError}
                        setError={setBuyItNowError}
                        isDisabledSelectedCurrency={true}
                        currencyAbrName={userAbbrName}
                      />
                    </>
                  )}
                  {isRaffle && (
                    <>
                      <Typography component="div" fontSize={18} className="flex">
                        {t('Price per ticket')}{' '}
                        <TooltipButton
                          marginBottom={4}
                          title={t(
                            'Amount of money (please note that 5% platform fee will be charged from it) user need to spend to get 1 ticket (place) in your raffle.'
                          )}
                        />
                      </Typography>
                      <InputWithCurrency
                        placeholder="123.456,00"
                        type="number"
                        value={price}
                        sx={{ width: '100%' }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        handleChange={handlePriceChange}
                        error={priceError}
                        setError={setPriceError}
                        currencyAbrName={userAbbrName}
                      />

                      <Typography component="div" fontSize={18} className="flex">
                        {t('Enter amount of Tickets available')}{' '}
                        <TooltipButton
                          marginBottom={4}
                          title={t('Define the amount of tickets (places) that will be participating in raffle play.')}
                        />
                      </Typography>
                      <CustomInputs
                        placeholder="123456"
                        variant="outlined"
                        type="number"
                        value={amountOfTickets}
                        onWheel={(e) => e.target.blur()}
                        sx={{ width: '100%' }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        onChange={(e) => {
                          handleAmountOfTicketsChange(e.target.value);
                          setAmountOfTicketsError('');
                        }}
                        helperText={amountOfTicketsError ? amountOfTicketsError : ' '}
                        error={!!amountOfTicketsError}
                      />

                      <Typography component="div" fontSize={18} className="flex">
                        {t('Enter the sales target for tickets')}{' '}
                        <TooltipButton
                          marginBottom={4}
                          title={t(
                            'The target for ticket sales is the minimum amount of ticket sales you would like to reach.'
                          )}
                        />
                      </Typography>
                      <CustomInputs
                        placeholder="123456"
                        variant="outlined"
                        type="number"
                        value={salesTargetOfTickets}
                        onWheel={(e) => e.target.blur()}
                        sx={{ width: '100%' }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        onChange={(e) => setSalesTargetOfTickets(e.target.value)}
                      />

                      <Collapse in={estimatedEarnings !== 0 && estimatedEarnings !== null}>
                        <Typography
                          component="div"
                          fontSize={18}
                          fontWeight={300}
                          sx={{ mb: 2 }}
                          className="flex flex-col"
                        >
                          {t('Estimated earnings from this Raffle')}:{' '}
                          <Box component="span" style={{ fontWeight: 400 }} className="flex">
                            {userAbbrName} {estimatedEarnings}{' '}
                            <TooltipButton
                              marginBottom={4}
                              title="Amount of money (please note that 5% platform fee will be charged from it) you will receive after raffle played if all tickets (places) will be bought."
                            />
                          </Box>
                        </Typography>
                      </Collapse>
                    </>
                  )}
                  {isMarketplace && (
                    <>
                      {!isQuantityEditOnly && (
                        <>
                          <Typography component="div" fontSize={18} className="flex">
                            {t('Price per item')}{' '}
                            <TooltipButton
                              marginBottom={4}
                              title={t(
                                'Amount of money (please note that 5% platform fee will be charged from it) user need to spend to get 1 ticket (place) in your raffle.'
                              )}
                            />
                          </Typography>
                          <InputWithCurrency
                            placeholder="123.456,00"
                            type="number"
                            value={itemPrice}
                            sx={{ width: '100%' }}
                            style={{
                              width: isMdScreen ? '92vw' : '86vw',
                              maxWidth: 788,
                            }}
                            handleChange={setItemPrice}
                            error={itemPriceError}
                            setError={setItemPriceError}
                            currencyAbrName={userAbbrName}
                          />
                        </>
                      )}

                      <Typography component="div" fontSize={18} className="flex">
                        {t('Enter quantity of items available')}{' '}
                        <TooltipButton marginBottom={4} title={t('Define the quantity of items that can be sold.')} />
                      </Typography>
                      <CustomInputs
                        placeholder="123456"
                        variant="outlined"
                        type="number"
                        value={itemsAmount}
                        onWheel={(e) => e.target.blur()}
                        sx={{ width: '100%' }}
                        style={{
                          width: isMdScreen ? '92vw' : '86vw',
                          maxWidth: 788,
                        }}
                        onChange={(e) => {
                          setItemsAmount(e.target.value);
                          setItemsError('');
                        }}
                        helperText={itemsError ? itemsError : ' '}
                        error={!!itemsError}
                      />
                    </>
                  )}
                </Box>
                {!isQuantityEditOnly && (
                  <Box ref={sellerAddressRef}>
                    <Typography fontSize={18}>{t('Shipping from')}</Typography>
                    <AddressFields
                      values={sellerAddress}
                      onChange={handleSellerAddressChange}
                      errors={sellerAddressErrors}
                      countriesList={countries}
                    />
                    <CustomTelInputs
                      value={sellerPhone}
                      placeholder={t('Phone no. with country code')}
                      variant="outlined"
                      defaultCountry={sellerAddress?.country}
                      style={{ maxWidth: 788 }}
                      onChange={(phone) => {
                        setSellerPhone(phone);
                        setSellerPhoneError('');
                      }}
                      helperText={sellerPhoneError ? sellerPhoneError : ' '}
                      error={!!sellerPhoneError}
                    />
                    <Typography fontSize={18}>{t('Parcel')}</Typography>
                    <Box className="flex max-[435px]:flex-col max-[435px]:items-center w-full my-4">
                      {parcelOptions}
                    </Box>
                    <Box className="flex justify-between max-[435px]:flex-col max-[435px]:items-center">
                      <CustomInputs
                        placeholder={t(`Length, ${parcel.distanceUnit}`)}
                        variant="outlined"
                        value={parcel.length}
                        required
                        style={{ width: isSgMaxScreen ? '100%' : '32%' }}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleParcelChange('length', e.target.value)}
                        helperText={parcelErrors.length ? parcelErrors.length : ' '}
                        error={!!parcelErrors.length}
                      />
                      <CustomInputs
                        placeholder={t(`Width, ${parcel.distanceUnit}`)}
                        variant="outlined"
                        value={parcel.width}
                        required
                        style={{ width: isSgMaxScreen ? '100%' : '32%' }}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleParcelChange('width', e.target.value)}
                        helperText={parcelErrors.width ? parcelErrors.width : ' '}
                        error={!!parcelErrors.width}
                      />
                      <CustomInputs
                        placeholder={t(`Height, ${parcel.distanceUnit}`)}
                        variant="outlined"
                        value={parcel.height}
                        required
                        style={{ width: isSgMaxScreen ? '100%' : '32%' }}
                        type="number"
                        onChange={(e) => handleParcelChange('height', e.target.value)}
                        helperText={parcelErrors.height ? parcelErrors.height : ' '}
                        error={!!parcelErrors.height}
                      />
                    </Box>
                    <Box className="flex justify-between max-[435px]:flex-col max-[435px]:items-center max-[435px]:mb-8">
                      <CustomInputs
                        placeholder={t(`Weight, ${parcel.massUnit}`)}
                        variant="outlined"
                        value={parcel.weight}
                        style={{ width: isSgMaxScreen ? '100%' : isRaffle ? '33%' : '49%' }}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleParcelChange('weight', e.target.value)}
                        helperText={parcelErrors.weight ? parcelErrors.weight : ' '}
                        error={!!parcelErrors.weight}
                      />
                      <FormControlLabel
                        label="Fragile item"
                        className="justify-center"
                        style={{
                          width: isSgMaxScreen ? '100%' : isRaffle ? '33%' : '49%',
                          marginTop: isSgMaxScreen ? -10 : -24,
                        }}
                        control={
                          <Checkbox
                            checked={parcel.isFragile}
                            onChange={(e) => handleParcelChange('isFragile', e.target.checked)}
                          />
                        }
                      />
                      {isRaffle && (
                        <FormControlLabel
                          label="Insure the item"
                          className="justify-center"
                          style={{ width: isSgMaxScreen ? '100%' : '33%', marginTop: isSgMaxScreen ? -10 : -24 }}
                          control={
                            <Checkbox
                              checked={parcel.isInsured}
                              onChange={(e) => handleParcelChange('isInsured', e.target.checked)}
                            />
                          }
                        />
                      )}
                      {isMarketplace && (
                        <FormControlLabel
                          label="One box for multiple items"
                          className="justify-center"
                          style={{ width: isSgMaxScreen ? '100%' : '33%', marginTop: isSgMaxScreen ? -10 : -24 }}
                          control={
                            <Checkbox
                              checked={parcel.isMultiple}
                              onChange={(e) => handleParcelChange('isMultiple', e.target.checked)}
                            />
                          }
                        />
                      )}
                    </Box>
                  </Box>
                )}
                {!isMarketplace && (
                  <Box>
                    <Typography fontSize={18}>{t(`${isRaffle ? 'Raffle' : 'Auction'} ends in`)}</Typography>
                    <CustomAutocomplete
                      value={selectedDate}
                      onChange={(event, newValue) => {
                        setSelectedDate(newValue);
                        setSelectedDateError('');
                      }}
                      options={chooseDateAutocompleteOptions()}
                      getOptionLabel={(option) => option.label}
                      sx={{ mt: 1, width: '100%' }}
                      style={{
                        width: isMdScreen ? '92vw' : '86vw',
                        maxWidth: 788,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('Duration')}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          helperText={selectedDateError ? selectedDateError : ' '}
                          error={!!selectedDateError}
                        />
                      )}
                    />
                  </Box>
                )}
                {!isQuantityEditOnly && (
                  <>
                    <Box className="mt-4">
                      <Typography fontSize={18}>{t('Media')}</Typography>
                      <Card
                        className="flex justify-center items-center flex-col"
                        sx={{
                          mt: 1,
                          minHeight: 200,
                          padding: 3,
                          backgroundColor: isDarkMode ? 'transparent' : '#FAFAFA',
                          border: isDarkMode ? '1px solid #fff' : null,
                          borderRadius: '10px',
                          boxShadow: '0px 0px 4px 0px #0000001A',
                        }}
                      >
                        {[...oldMedia, ...selectedFiles].length > 0 && (
                          <Box>
                            <ul>
                              {[...oldMedia, ...selectedFiles].map((file, index) => (
                                <li
                                  key={index}
                                  className="mb-4 flex justify-center items-center"
                                  style={{ position: 'relative' }}
                                >
                                  {typeof file === 'string' ? (
                                    <img
                                      src={file}
                                      alt={file}
                                      style={{
                                        maxHeight: 300,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      style={{
                                        maxHeight: 300,
                                      }}
                                    />
                                  )}
                                  <IconButton
                                    onClick={() => handleRemoveFile(index)}
                                    style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      fontSize: '32px',
                                      color: isDarkMode ? 'white' : 'black',
                                    }}
                                  >
                                    <CancelRoundedIcon
                                      style={{ fontSize: '32px', color: isDarkMode ? '#fff' : '#b31b1b' }}
                                    />
                                  </IconButton>
                                </li>
                              ))}
                            </ul>
                          </Box>
                        )}
                        <Input
                          type="file"
                          inputProps={{ multiple: true }}
                          onChange={handleFileInputChange}
                          style={{ display: 'none' }}
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <CustomButton sx={{ width: isSgScreen ? 250 : 200 }} variant="contained" component="span">
                            <img src={isDarkMode ? fileIconDM : fileIcon} alt="Camera Icon" style={{ width: '22px' }} />
                            <Box
                              style={{ flex: 1, textAlign: 'center', color: isDarkMode ? 'white' : null }}
                              className="mt-2"
                            >
                              {t('Add file')}
                            </Box>
                          </CustomButton>
                        </label>
                      </Card>
                    </Box>
                    <Box className="mt-20 flex justify-center">
                      <CustomButton
                        variant="contained"
                        sx={{ px: 6 }}
                        onClick={handleProductData}
                        disabled={loadingButton}
                      >
                        <LoadingIndicator
                          isLoading={loadingButton}
                          value={<Box style={{ flex: 1, color: isDarkMode ? 'white' : null }}>{t('done')}</Box>}
                        />
                      </CustomButton>
                    </Box>
                  </>
                )}
              </Card>
            </Box>
          </>
        )}
      </Box>
      <FormTemplate
        open={createCategoryDialogOpen}
        onClose={() => setCreateCategoryDialogOpen(false)}
        title={'Add custom category'}
        dialogContent={
          <>
            <CustomInputs
              placeholder={t('Enter name  of custom category')}
              variant="outlined"
              type="text"
              required
              sx={{ mt: 1 }}
              onChange={(e) => setCreateCategoryValue(e.target.value)}
            />
          </>
        }
        dialogActions={
          <>
            <CustomButton variant="contained" onClick={handleCreateCategory}>
              <LoadingIndicator
                isLoading={loadingCreateCategoryBtn}
                style={{ color: isDarkMode ? 'white' : null }}
                value={t('add category')}
              />
            </CustomButton>
          </>
        }
      />
      <FormTemplate
        open={openSuccessForm}
        onClose={() => {
          setOpenSuccessForm(false);
          navigate(`/product/${productId}`);
        }}
        dialogTitle={
          <>
            <Typography className="text-center flex flex-row justify-center items-center" fontSize={32}>
              <img src={congratulationsIcon} alt="Congratulation Icon" style={{ width: 30, height: 30 }} />
              <Box component="span" className="px-4">
                {t('CONGRATS')}
              </Box>
              <img src={congratulationsIcon} alt="Congratulation Icon" style={{ width: 30, height: 30 }} />
            </Typography>
          </>
        }
        dialogContent={
          <>
            <Typography className="text-center" fontSize={24}>
              {`Your ${isRaffle ? t('raffle') : isMarketplace ? t('item') : t('auction')} ${
                newCategory ? `${t('and custom category were')}` : `${t('was')}`
              }
             ${t(
               'successfully submitted to moderation. Moderation can take up to 24 hours. You can keep an eye on your'
             )} ${isRaffle ? t('raffles') : isMarketplace ? t('marketplace items') : t('auctions')} ${t('and its statuses in your profile page.')}`}
            </Typography>
          </>
        }
        dialogActions={
          <>
            <CustomButton
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => navigate(`/profile/${isRaffle ? 1 : isMarketplace ? 2 : 0}`)}
            >
              {t('go to profile page')}
            </CustomButton>
          </>
        }
      />
      {showAddEmailForm && <AddEmailForm onOpen={true} onClose={() => setShowAddEmailForm(false)} />}
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default CreateAuctionAndRafflePageComponent;
