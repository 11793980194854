import React from 'react';
import congratulationsIcon from '../../assets/icons/congratulationsIcon.svg';
import translations from './local/en.json';

const ProductAuctionRaffleDialogTexts = ({ isPay, isConfirmEndRaffle, isConfirmEndAuction, isRaffle, t }) => {
  if (!isPay) {
    return (!isConfirmEndRaffle && isRaffle) || (!isConfirmEndAuction && !isRaffle) ? (
      isRaffle ? (
        t(translations.RAFFLE_END_MESSAGE)
      ) : (
        t(translations.AUCTION_END_MESSAGE)
      )
    ) : (
      <>
        <span className="flex flex-row justify-center">
          <img src={congratulationsIcon} alt="Congratulation Icon" style={{ width: 30, height: 30 }} />
          <span className="px-4">
            {t(isRaffle ? translations.CONGRATS_RAFFLE_MESSAGE : translations.CONGRATS_AUCTION_MESSAGE)}
          </span>
          <img src={congratulationsIcon} alt="Congratulation Icon" style={{ width: 30, height: 30 }} />
        </span>
      </>
    );
  }
  return null;
};

export default ProductAuctionRaffleDialogTexts;
