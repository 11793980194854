import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from '../../config/axiosConfig';

import { Typography, Grid } from '@mui/material';
import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import BannerComponent from '../../utils/ui/BannerComponent.jsx';
import CustomTabs from '../../utils/ui/TabsComponent.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import ProductItem from './ProductItemComponent.jsx';
import CategorySwiperSection from './CategorySwiperSection.jsx';
import { selectUser } from '../../store/features/userSlice';

function HomePageComponent() {
  const { t } = useTranslation();
  const { hash } = useParams();
  const location = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { isSdScreen, isSgScreen, isMdScreen, isSgMaxScreen } = useMediaQueries();
  const initialData = Array(10).fill(null);
  const [bannerData, setBannerData] = useState(initialData);
  const [bannerDelay, setBannerDelay] = useState(null);
  const [auctionItemsData, setAuctionItemsData] = useState(initialData);
  const [raffleItemsData, setRaffleItemsData] = useState(initialData);
  const [marketplaceItemsData, setMarketplaceItemsData] = useState(initialData);
  const [categoriesItemsData, setCategoriesItemsData] = useState(Array(2).fill(null));
  const [selectedTab, setSelectedTab] = useState(0);
  const [serchCategoryValue, setSerchCategoryValue] = useState(null);
  const [loadingAll, setLoadingAll] = useState(false);
  const [loadingProductsData, setLoadingProductsData] = useState(false);
  const [allFetched, setAllFetched] = useState(false);

  const [prevCurrency, setPrevCurrency] = useState('');
  const [prevIsRaffle, setPrevIsRaffle] = useState('');
  const [prevLocationKey, setPrevLocationKey] = useState('');
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const handleChangeTabs = (event, newValue) => setSelectedTab(newValue);

  const productType = {
    0: 'Raffle',
    1: 'Auction',
    2: 'Marketplace',
  };

  const fetchData = useCallback(async () => {
    setLoadingProductsData(true);
    const searchParams = new URLSearchParams(hash);
    const categoryValue = searchParams.get('category');
    setSerchCategoryValue(hash && categoryValue ? categoryValue : null);
    const fetchBannerData = async () => {
      const response = await axios.get('banner/list');
      const delay = await axios.get('banner/duration');
      setBannerDelay(delay?.data?.[0]?.value * 1000 ?? null);
      return response.data;
    };
    const fetchDataByType = async () => {
      const url = `product?type=${productType[selectedTab]}&status=accepted&sort=${productType[selectedTab] === 'Auction' ? 'MostBids' : `Most${productType[selectedTab] === 'Raffle' ? 'Ticket' : 'Item'}sSold`}&viewCurrency=${user.currency}${
        hash && categoryValue ? `&category=${categoryValue}` : ''
      }`;
      const response = await axios.get(url);
      return response.data.list;
    };

    const fetchCategoryData = async () => {
      const url = `category/list?status=accepted`;
      const response = await axios.get(url);

      if (hash && categoryValue)
        return response.data
          .filter((item) => item.parent === categoryValue)
          .sort((a, b) => a?.name?.en?.localeCompare(b?.name?.en));
      else
        return response.data
          .filter((item) => item.parent === null)
          .sort((a, b) => a?.name?.en?.localeCompare(b?.name?.en));
    };

    const fetchDataAndAuctionItems = async () => {
      const allData = await Promise.all([fetchBannerData(), fetchDataByType(), fetchCategoryData()]);
      return allData;
    };

    const fetchAllData = async () => {
      setLoadingAll(true);
      setAllFetched(true);

      try {
        const [bannerData, productsData, categoriesData] = await fetchDataAndAuctionItems();
        setBannerData(bannerData);
        if (productType[selectedTab] === 'Raffle') setRaffleItemsData(productsData);
        if (productType[selectedTab] === 'Auction') setAuctionItemsData(productsData);
        if (productType[selectedTab] === 'Marketplace') setMarketplaceItemsData(productsData);
        setCategoriesItemsData(categoriesData);
      } catch (error) {
        showSnackbarRef.current(error);
      } finally {
        setLoadingAll(false);
        setLoadingProductsData(false);
      }
    };

    const triggerFetchByType = async () => {
      setLoadingProductsData(true);

      try {
        const results = await fetchDataByType();
        if (productType[selectedTab] === 'Raffle') setRaffleItemsData(results);
        if (productType[selectedTab] === 'Auction') setAuctionItemsData(results);
        if (productType[selectedTab] === 'Marketplace') setMarketplaceItemsData(results);
      } catch (error) {
        showSnackbarRef.current(error);
      } finally {
        setLoadingProductsData(false);
      }
    };

    try {
      if (!allFetched || user.currency !== prevCurrency || location.key !== prevLocationKey) fetchAllData();
      else triggerFetchByType();
    } catch (error) {
      showSnackbarRef.current(error);
    }
  }, [selectedTab, allFetched, user.currency, prevCurrency, prevIsRaffle, hash, location.key, prevLocationKey]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPrevCurrency(user.currency);
    setPrevIsRaffle(productType[selectedTab] === 'Raffle');
    setPrevLocationKey(location.key);
  }, [user.currency, selectedTab, location.key]);

  return (
    <>
      <div className={`pt-${isSgMaxScreen ? 4 : 12} px-4 md:px-10 lg:px-12 xl:px-20`}>
        <BannerComponent data={bannerData} delay={bannerDelay} isLoading={loadingAll} />
        {bannerData.length === 0 && (
          <>
            <Typography align="center" mt={4}>
              {t('No banners')}
            </Typography>
          </>
        )}
      </div>
      <div className="px-4 pt-10 md:px-10 lg:px-12 xl:px-20" style={{ paddingTop: isSgMaxScreen ? 45 : null }}>
        <Typography sx={{ pt: loadingAll ? 1.4 : 0, fontSize: 18 }} variant="h6">
          {t('Top')}
        </Typography>
        <div className="flex justify-between items-center">
          <CustomTabs
            tabs={[{ label: 'Raffles' }, { label: 'Auctions' }, { label: 'Marketplace' }]}
            titleSize={isSgMaxScreen ? 16 : 20}
            selectedTab={selectedTab}
            handleChange={handleChangeTabs}
          />
          {isSgScreen && (
            <CustomButton
              variant="contained"
              onClick={() => navigate(`/search/*?search=&tab=${selectedTab}`)}
              sx={{ mx: 1 }}
              style={{
                width: isMdScreen ? '35%' : isSgScreen ? '45%' : isSdScreen ? '38%' : '50%',
                maxWidth: 250,
              }}
            >
              <Typography fontSize={isMdScreen && 18}>
                {t(`All ${selectedTab === 2 ? 'Item' : productType[selectedTab]}s`)}
              </Typography>
            </CustomButton>
          )}
        </div>
        <div className="mt-10">
          <Grid container spacing={2}>
            <div className={`w-full overflow-x-auto flex ${isSgMaxScreen ? 'px-2' : ''}`}>
              {[0, 1, 2].map(
                (tabIndex) =>
                  selectedTab === tabIndex && (
                    <React.Fragment key={tabIndex}>
                      <ProductItem
                        itemData={(productType[selectedTab] === 'Raffle'
                          ? raffleItemsData
                          : productType[selectedTab] === 'Auction'
                            ? auctionItemsData
                            : marketplaceItemsData
                        ).slice(0, 5)}
                        mode={productType[selectedTab]}
                        isLoading={loadingProductsData}
                      />
                      <ProductItem
                        itemData={(productType[selectedTab] === 'Raffle'
                          ? raffleItemsData
                          : productType[selectedTab] === 'Auction'
                            ? auctionItemsData
                            : marketplaceItemsData
                        ).slice(5)}
                        mode={productType[selectedTab]}
                        isLoading={loadingProductsData}
                        itemIndex={6}
                      />
                    </React.Fragment>
                  )
              )}
            </div>
          </Grid>
          {isSgMaxScreen && (
            <CustomButton
              variant="contained"
              onClick={() => navigate(`/search/*?search=&tab=${selectedTab}`)}
              style={{ width: '38%', maxWidth: 250, margin: '0 auto', marginTop: 24 }}
            >
              <Typography>{t(`All ${selectedTab === 2 ? 'Item' : productType[selectedTab]}s`)}</Typography>
            </CustomButton>
          )}
        </div>
      </div>
      {categoriesItemsData?.map((item, index) => (
        <CategorySwiperSection
          key={index}
          item={item}
          loadingAll={loadingAll}
          serchCategoryValue={serchCategoryValue}
          navigate={navigate}
          t={t}
          userCurrency={user.currency}
        />
      ))}
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
export default HomePageComponent;
