import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../config/axiosConfig';
import { useTranslation } from 'react-i18next';

import { DialogTitle, DialogContent, DialogActions, Typography, IconButton, InputAdornment } from '@mui/material';

import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import LoadingIndicator from '../../../utils/ui/LoadingIndicator.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import { baseURL } from '../../../utils/constants';
import EmailForm from '../EmailForm.jsx';
import SignUpAdditionalAutocompletions from './SingUpAdditionalAutocomplites.jsx';
import TermsPolicyDialog from './TermsPolicyDialog.jsx';
import { CustomButton, CustomInputs } from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';
import { useMediaQueries } from '../../../utils/ui/js/useMediaQueries';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import googleIcon from '../../../assets/icons/googleIcon.svg';
import facebookIcon from '../../../assets/icons/facebookIcon.svg';
import appleIcon from '../../../assets/icons/appleIcon.svg';
import emailIcon from '../../../assets/icons/emailIcon.svg';
import emailIconDM from '../../../assets/icons/emailIconDM.svg';

const SignUpForm = ({ onClose, goBack }) => {
  const { t } = useTranslation();
  const { isSgMaxScreen } = useMediaQueries();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    selectedCountry: '',
    selectedCurrency: '',
    emailExists: '',
  });
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);
  const [signUpWithEmail, setSignUpWithEmail] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isHandleSignUp, setIsHandleSignUp] = useState(false);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleClickOpenPolicyModal = (isPrivacyPolicy) => () => {
    setOpenPolicyModal(true);
    setIsPrivacyPolicy(isPrivacyPolicy);
  };

  const toggleGoBack = () => {
    signUpWithEmail ? setSignUpWithEmail(false) : goBack();
  };

  const handleSignUpWithEmail = async () => {
    const fieldsToCheck = ['firstName', 'lastName', 'email', 'password'];

    const newErrors = {};
    fieldsToCheck.forEach((field) => {
      const trimmedValue = formData[field].trim();
      if (!trimmedValue) {
        newErrors[field] = `${t(
          `${field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())} is required`
        )}`;
      }
    });
    if (!selectedCountry) {
      newErrors[selectedCountry] = `${t(`Country is required`)}`;
    }
    if (!selectedCurrency) {
      newErrors[selectedCurrency] = `${t(`Currency is required`)}`;
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length !== 0) {
      setIsHandleSignUp(true);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('auth/sign-up', {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        email: formData.email.trim(),
        password: formData.password.trim(),
        country: selectedCountry.trim(),
        currency: selectedCurrency.trim(),
        phone: '',
        role: 'user',
      });
      if (response?.status === 201) {
        setCheckEmail(true);
        showSnackbar(null, 'Success', 'success');
      }
    } catch (error) {
      console.error(error);
      setIsHandleSignUp(true);
      const emailErrorMessage = error.response?.data?.errors?.email?.message;
      const emailExistMessage = error.response?.data?.response;
      const passwordErrorMessage = error.response?.data?.errors?.password?.message;
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailErrorMessage !== undefined ? emailErrorMessage : emailExistMessage,
        password: passwordErrorMessage,
      }));
    }
    setLoading(false);
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      {!checkEmail ? (
        <>
          <DialogTitle>
            <div className="flex justify-between items-center">
              <IconButton onClick={toggleGoBack}>
                <ArrowBackIosRoundedIcon style={{ fontSize: '26px', color: isDarkMode ? 'white' : 'black' }} />
              </IconButton>
              {isSgMaxScreen && (
                <Typography component="div" variant="h5" align="center" mt={0.5}>
                  {!signUpWithEmail ? 'Sign Up' : 'Sign Up with Email'}
                </Typography>
              )}
              <IconButton onClick={onClose}>
                <ClearRoundedIcon style={{ fontSize: '32px', color: isDarkMode ? 'white' : 'black' }} />
              </IconButton>
            </div>
            {!isSgMaxScreen && (
              <Typography component="div" variant="h4" align="center" mt={2}>
                {!signUpWithEmail ? 'Sign Up' : 'Sign Up with Email'}
              </Typography>
            )}
            <Typography component="div" variant="h6" align="center" fontWeight={300} mt={isSgMaxScreen ? 0 : 2}>
              {t('By signing up you agree to our')}{' '}
              <button className="underline" style={{ fontWeight: 500 }} onClick={handleClickOpenPolicyModal(false)}>
                {t('Terms & Conditions')}
              </button>{' '}
              {t('and')}{' '}
              <button className="underline" style={{ fontWeight: 500 }} onClick={handleClickOpenPolicyModal(true)}>
                {t('Privacy Policy')}.
              </button>
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              mt: isSgMaxScreen ? 0 : 3,
              px: isSgMaxScreen ? 0 : 1,
              pb: isSgMaxScreen ? 0 : null,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!signUpWithEmail ? (
              <>
                <Link to={baseURL + 'auth/google'}>
                  <CustomButton variant="contained" fontSize={isSgMaxScreen ? '0.9rem' : null}>
                    <img src={googleIcon} alt="Google Icon" style={{ width: `3${isSgMaxScreen ? 0 : 2}px` }} />
                    <div style={{ flex: 1 }}>{t('Continue with Google')}</div>
                  </CustomButton>
                </Link>
                <CustomButton
                  variant="contained"
                  fontSize={isSgMaxScreen ? '0.9rem' : null}
                  sx={{ mt: isSgMaxScreen ? 2 : 4 }}
                >
                  <img src={facebookIcon} alt="Facebook Icon" style={{ width: `3${isSgMaxScreen ? 0 : 2}px` }} />
                  <div style={{ flex: 1 }}>{t('Continue with Facebook')}</div>
                </CustomButton>
                <Link to={baseURL + 'auth/apple'}>
                  <CustomButton
                    variant="contained"
                    fontSize={isSgMaxScreen ? '0.9rem' : null}
                    sx={{ mt: isSgMaxScreen ? 2 : 4 }}
                  >
                    <img src={appleIcon} alt="Apple Icon" style={{ width: `3${isSgMaxScreen ? 0 : 2}px` }} />
                    <div style={{ flex: 1 }}>{t('Continue with Apple')}</div>
                  </CustomButton>
                </Link>
                <Typography pt={isSgMaxScreen ? 1 : 4} variant="h6" align="center" fontWeight={300}>
                  {t('or')}
                </Typography>
              </>
            ) : (
              <>
                {Object.keys(formData).map((fieldName, index) => (
                  <CustomInputs
                    key={index}
                    label={t(fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()))}
                    variant="outlined"
                    required
                    sx={{ pt: index > 0 ? 0 : 0.1, mt: index > 0 ? 0 : 1 }}
                    type={
                      fieldName === 'password'
                        ? showPassword
                          ? 'text'
                          : 'password'
                        : fieldName === 'email'
                          ? 'email'
                          : 'text'
                    }
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    helperText={(isHandleSignUp && errors[fieldName]) || ' '}
                    error={isHandleSignUp && !!errors[fieldName]}
                    InputProps={{
                      ...(fieldName === 'password' && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }),
                    }}
                  />
                ))}
                <SignUpAdditionalAutocompletions
                  onSelect={(selectedCountry, selectedCurrency) => {
                    setSelectedCountry(selectedCountry);
                    setSelectedCurrency(selectedCurrency);
                  }}
                  isSelectedCurrencyError={errors[selectedCurrency]}
                  isSelectedCountryError={errors[selectedCountry]}
                  onSetError={(value) => {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      [value === 'selectedCountryError' ? selectedCountry : selectedCurrency]: ' ',
                    }));
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              mb: 6,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!signUpWithEmail ? (
              <>
                <CustomButton
                  variant="contained"
                  fontSize={isSgMaxScreen ? '1rem' : null}
                  sx={{ mt: isSgMaxScreen ? 0 : 2 }}
                  onClick={() => setSignUpWithEmail(true)}
                >
                  <img src={isDarkMode ? emailIconDM : emailIcon} alt="E-mail Icon" style={{ width: '32px' }} />
                  <div style={{ flex: 1 }}>{t('Sign up with E-mail')}</div>
                </CustomButton>
              </>
            ) : (
              <>
                <CustomButton variant="contained" sx={{ mt: 2 }} onClick={handleSignUpWithEmail} disabled={loading}>
                  <LoadingIndicator
                    isLoading={loading}
                    style={{ color: isDarkMode ? 'white' : null }}
                    value={
                      <>
                        <div style={{ flex: 1 }}>{t('Continue')}</div>
                      </>
                    }
                  />
                </CustomButton>
              </>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <EmailForm
            open={true}
            onClose={onClose}
            mainText={t(
              'Thank you for signing up! Please verify your email address through the verification link sent to your mail. Don’t forget to check the spam folder!'
            )}
          />
        </>
      )}
      <TermsPolicyDialog
        open={openPolicyModal}
        handleClosePolicyModal={() => setOpenPolicyModal(false)}
        isPrivacyPolicy={isPrivacyPolicy}
        agreeButton={true}
        t={t}
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default SignUpForm;
