import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './components/common/navbar/Navbar.jsx';
import Footer from './components/common/footer/Footer.jsx';
import AppRoutes from './utils/router/Routes.jsx';
import CookieSnackbar from './utils/ui/CookieSnackbarComponent.jsx';
import SupportChat from './components/common/supportChat/SupportChatComponents.jsx';
import { lightTheme, darkTheme } from './utils/ui/js/theme.js';

import './common/styles/main.css';

const useReactiveLocalStorage = (key) => {
  const [value, setValue] = useState(() => localStorage.getItem(key));

  useEffect(() => {
    // Function to update the state when localStorage changes
    const syncValue = () => setValue(localStorage.getItem(key));
    // Listen for changes from other tabs
    const handleStorageChange = (event) => {
      if (event.key === key) syncValue();
    };
    // Poll for changes in the same tab
    const intervalId = setInterval(syncValue, 100);
    // Add and clean up event listeners
    window.addEventListener('storage', handleStorageChange);
    return () => {
      clearInterval(intervalId); // Clean up polling
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return value;
};

function App() {
  const localStorageValue = useReactiveLocalStorage('theme');

  return (
    <>
      <ThemeProvider theme={localStorageValue === 'dark' ? darkTheme : lightTheme}>
        <CssBaseline />
        <div className="flex flex-col min-h-screen relative">
          <Navbar />
          <AppRoutes />
          <SupportChat />
          <Footer />
          <CookieSnackbar />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
