import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@mui/material';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useMediaQueries } from '../js/useMediaQueries';

const FormTemplate = ({
  open,
  onClose,
  title,
  subtitle,
  dialogTitle,
  dialogContent,
  dialogActions,
  redirect,
  isGoBack,
  dialogContentPadding,
  isSticky,
  scrollElRef,
}) => {
  const { isMdScreen } = useMediaQueries();
  const navigate = useNavigate();

  const handleCloseForm = () => {
    if (onClose) onClose();
    if (redirect) navigate('/');
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div ref={scrollElRef} style={{ minWidth: isMdScreen ? 570 : 250 }}>
          <DialogTitle
            sx={{ position: isSticky && 'sticky', top: isSticky && 0, backgroundColor: isSticky && '#fff', zIndex: 10 }}
          >
            <div className="flex" style={{ justifyContent: isGoBack ? 'space-between' : 'end' }}>
              {isGoBack && (
                <>
                  <IconButton onClick={isGoBack}>
                    <ArrowBackIosRoundedIcon style={{ fontSize: '26px', color: isDarkMode ? 'white' : 'black' }} />
                  </IconButton>
                </>
              )}
              <IconButton onClick={handleCloseForm}>
                <ClearRoundedIcon style={{ fontSize: '32px', color: isDarkMode ? 'white' : 'black' }} />
              </IconButton>
            </div>
            {dialogTitle}
            {title && (
              <Typography component="div" mt={2} align="center" variant="h5">
                {title}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              mb: !dialogActions && 6,
              padding: dialogContentPadding,
            }}
          >
            {dialogContent}
            {subtitle && (
              <>
                <Typography className="text-center" variant="h6" fontWeight={300}>
                  {subtitle}
                </Typography>
              </>
            )}
          </DialogContent>
          {dialogActions && (
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                mb: isSticky ? 0 : 6,
                position: isSticky && 'sticky',
                bottom: isSticky && 0,
                backgroundColor: isSticky && '#fff',
              }}
            >
              {dialogActions}
            </DialogActions>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default FormTemplate;
