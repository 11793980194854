import { useMediaQuery } from 'react-responsive';

export const useMediaQueries = () => {
  const isSddScreen = useMediaQuery({ query: '(min-width: 345px)' });
  const isSdScreen = useMediaQuery({ query: '(min-width: 375px)' });
  const isSdgScreen = useMediaQuery({ query: '(min-width: 400px)' });
  const isSgScreen = useMediaQuery({ query: '(min-width: 435px)' });
  const isSgMaxScreen = useMediaQuery({ query: '(max-width: 435px)' });
  const isMdScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isLgScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const isXlScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isXXlScreen = useMediaQuery({ query: '(min-width: 1440px)' });

  return {
    isSddScreen,
    isSdScreen,
    isSdgScreen,
    isSgScreen,
    isSgMaxScreen,
    isMdScreen,
    isLgScreen,
    isXlScreen,
    isXXlScreen,
  };
};
