import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import CustomTabs from '../../utils/ui/TabsComponent.jsx';
import useTabsData from './AboutPageText.js';
import aboutUsIcon from '../../assets/icons/aboutUsIcon.svg';
import aboutUsIconDM from '../../assets/icons/aboutUsIconDM.svg';
import aboutVideo from '../../assets/videos/outbiddaAboutFinaleWithSubs.mp4';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';

function AboutPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();
  const { isSgMaxScreen } = useMediaQueries();

  const handleChangeTabs = (event, newValue) => setSelectedTab(newValue);
  const tabsData = useTabsData(t);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <div className="flex justify-center px-4 py-8 min-[435px]:py-10 min-[435px]:px-6 sm:p-10 lg:p-20 xl:mx-20">
      <div className="max-w-7xl" style={{ width: '100%' }}>
        <div className="flex flex-row items-center">
          <img src={isDarkMode ? aboutUsIconDM : aboutUsIcon} alt="About Us Icon" style={{ width: '30px' }} />
          <Typography fontWeight={600} fontSize={20} ml={2}>
            {t('Building a community for dedicated Buyers and Sellers')}
          </Typography>
        </div>
        <div className="mt-6">
          <CustomTabs
            tabs={tabsData?.map((tab) => ({ label: tab.label }))}
            selectedTab={selectedTab}
            handleChange={handleChangeTabs}
            titleSize={isSgMaxScreen ? 14 : 20}
          />
        </div>
        <div className="mt-6">
          {tabsData[selectedTab].content.map((text, index) => (
            <Typography key={index} fontWeight={300} fontSize={isSgMaxScreen ? 14 : 16} mt={index > 0 ? 2 : 4}>
              {text}
            </Typography>
          ))}
        </div>
        <div className={`video-container mt-1${isSgMaxScreen ? 0 : 6}`}>
          <video width="100%" height="auto" controls style={{ borderRadius: 20 }}>
            <source src={aboutVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
