import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../config/axiosConfig';

import { IconButton, InputAdornment } from '@mui/material';

import { CustomButton, CustomInputs } from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';
import FormTemplate from './FormTemplate.jsx';
import LoadingIndicator from '../../../utils/ui/LoadingIndicator.jsx';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UserChangePassword = ({ onClose }) => {
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordEror, setNewPasswordEror] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isSuccessfullyPasswordChange, setisSuccessfullyPasswordChange] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const handleChangePassword = async () => {
    if (!currentPassword) {
      setCurrentPasswordError(`${t('Password is required')}`);
      return;
    } else if (!newPassword) {
      setNewPasswordEror(`${t('New Password is required')}`);
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError(`${t('Passwords do not match')}`);
      return;
    }
    setIsLoadingButton(true);
    axios
      .put('user/change-password', {
        newPassword: confirmNewPassword,
        oldPassword: currentPassword,
      })
      .then(() => {
        setisSuccessfullyPasswordChange(true);
        setIsLoadingButton(false);
      })
      .catch((error) => {
        console.error(error);
        setCurrentPasswordError(error?.response?.data?.message);
      });
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      {!isSuccessfullyPasswordChange ? (
        <FormTemplate
          open={true}
          onClose={onClose}
          title="Change Password"
          dialogContent={
            <>
              <CustomInputs
                value={currentPassword}
                placeholder={t('Enter Current password')}
                variant="outlined"
                type={showCurrentPassword ? 'text' : 'password'}
                sx={{ mt: 2 }}
                InputProps={{
                  style: {
                    borderRadius: '0.75rem',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        edge="end"
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                  setCurrentPasswordError('');
                }}
                helperText={currentPasswordError ? currentPasswordError : ' '}
                error={!!currentPasswordError}
              />
              <CustomInputs
                value={newPassword}
                placeholder={t('Enter new password')}
                variant="outlined"
                type={showNewPassword ? 'text' : 'password'}
                sx={{ mt: 2 }}
                InputProps={{
                  style: {
                    borderRadius: '0.75rem',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setNewPasswordEror('');
                }}
                helperText={newPasswordEror ? newPasswordEror : ' '}
                error={!!newPasswordEror}
              />
              <CustomInputs
                value={confirmNewPassword}
                placeholder={t('Repeat new password')}
                variant="outlined"
                type={showConfirmNewPassword ? 'text' : 'password'}
                sx={{ mt: 2 }}
                InputProps={{
                  style: {
                    borderRadius: '0.75rem',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                        edge="end"
                      >
                        {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                  setConfirmNewPasswordError('');
                }}
                helperText={confirmNewPasswordError ? confirmNewPasswordError : ' '}
                error={!!confirmNewPasswordError}
              />
            </>
          }
          dialogActions={
            <CustomButton
              variant="contained"
              onClick={handleChangePassword}
              disabled={(!currentPassword && !newPassword && !confirmNewPassword) || isLoadingButton}
            >
              <LoadingIndicator
                isLoading={isLoadingButton}
                style={{ color: isDarkMode ? 'white' : null }}
                value={<> {t('change password')}</>}
              />
            </CustomButton>
          }
        />
      ) : (
        <>
          <FormTemplate open={true} onClose={onClose} title="Password changed" />
        </>
      )}
    </>
  );
};

export default UserChangePassword;
