import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  CardContent,
  Rating,
  Backdrop,
  Divider,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';

import axios from '../../config/axiosConfig';
import { baseURLImage } from '../../utils/constants.js';

import locationIcon from '../../assets/icons/locationIcon.svg';
import locationIconDM from '../../assets/icons/locationIconDM.svg';
import verificationIcon from '../../assets/icons/verificationIcon.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '800px',
  bgcolor: 'white',
  padding: 3,
  borderRadius: 2,
  boxShadow: '0px 2px 6px 2px  rgba(0, 0, 0, 0.08)',
  border: 'none',
  outline: 'none',
};

const StyledModal = styled(Modal)({
  '&:focus-visible': { outline: 'none', border: 'none' },
  '&.MuiBox-root': { outline: 'none', border: 'none' },
});

const NewReviewModelComponent = ({
  open,
  authUser,
  handleClose,
  unreviewedProduct,
  filterUnreviewedProduct,
  addNewReview,
  showSnackbar,
}) => {
  const [text, setText] = useState('');
  const [star, setStar] = useState(0);
  const [currentUnreviewedProduct, setCurrentUnreviewedProduct] = useState(unreviewedProduct[0]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    if (!star) return;

    try {
      const response = await axios.post('review', {
        product: currentUnreviewedProduct._id,
        text,
        star,
      });

      addNewReview(response.data);
      filterUnreviewedProduct(currentUnreviewedProduct);
    } catch (error) {
      showSnackbar(error);
    }
    setText('');
    handleClose();
  };

  const BootstrapButton = styled(Button)({
    width: '114px',
    height: '37px',
    borderRadius: '5px',
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
    padding: 0,
    border: 'none',
    color: '#191919',
    '&:hover': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: '-1px -1px 5px 1px rgba(0, 0, 0, 0.25)',
    },
    '&:active': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: '-1px -1px 5px 1px rgba(0, 0, 0, 0.25)',
    },
    '&:focus': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: '-1px -1px 5px 1px rgba(0, 0, 0, 0.25)',
    },
  });

  const handleOpenUnreviewedProductList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUnreviewedProductList = () => {
    setAnchorEl(null);
  };

  const chooseNewUnreviewedProduct = (p) => {
    setAnchorEl(null);
    setCurrentUnreviewedProduct(p);
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ style: { backgroundColor: 'rgba(217, 217, 217, 0.3)', backdropFilter: 'blur(5px)' } }}
    >
      <Box sx={style}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar
              src={baseURLImage + authUser?.picture}
              alt="Buyer Avatar"
              sx={{ width: 70, height: 70, marginRight: 4 }}
            />
            <Box>
              <Typography variant="body1" fontSize="14" fontWeight="500">
                <Box
                  component="span"
                  sx={{
                    width: 'fit-content',
                    position: 'relative',
                    '&::after': {
                      content: "''",
                      display: 'inline-block',
                      width: '17px',
                      height: '17px',
                      backgroundImage: `${authUser?.verification === 'verified' || authUser.isGenerated ? `url(${verificationIcon})` : 'none'}`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      position: 'absolute',
                      top: '-9px',
                      right: '-19px',
                    },
                  }}
                >
                  {`${authUser?.firstName} ${authUser?.lastName}`}
                </Box>
              </Typography>
              <Typography component="div" variant="body2" fontSize="14" display="flex" alignItems="center">
                {authUser?.country?.name && (
                  <>
                    <img
                      src={isDarkMode ? locationIconDM : locationIcon}
                      alt="Location Icon"
                      style={{ maxHeight: '15px', marginTop: '-2px', marginRight: '3px' }}
                    />
                    {authUser.country.name}
                  </>
                )}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" width="100%" justifyContent="center" mt={2} mb={4}>
            <Rating
              name="hover-feedback"
              value={star}
              precision={1}
              sx={{ fontSize: '3rem' }}
              onChange={(event, newValue) => {
                setStar(newValue);
              }}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
          </Box>

          <Box display="flex" mb={3}>
            <TextField
              placeholder="share details of your own experience with this seller... (optional)"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': { borderColor: '#ddd' },
                  '&:focus-visible fieldset': { borderColor: '#ddd', outline: 'none' },
                  '&.Mui-focused fieldset': { borderColor: '#ddd' },
                },
              }}
              onChange={handleChange}
            />
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ marginRight: 2 }}>
                <Typography variant="caption" color="textSecondary">
                  Type
                </Typography>
                <Typography variant="body1" fontWeight="300">
                  {currentUnreviewedProduct.type}
                </Typography>
              </Box>

              <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />

              <Box display="flex" alignItems="center">
                <Avatar
                  src={baseURLImage + currentUnreviewedProduct.media[0]}
                  alt={currentUnreviewedProduct.title}
                  variant="rounded"
                  sx={{ width: 40, height: 40, marginRight: 2 }}
                />
                <Typography variant="body1" fontWeight="300">
                  {currentUnreviewedProduct.title.length > 20
                    ? currentUnreviewedProduct.title.slice(0, 20) + '...'
                    : currentUnreviewedProduct.title}
                </Typography>
              </Box>

              <IconButton onClick={handleOpenUnreviewedProductList}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseUnreviewedProductList}
                PaperProps={{
                  style: {
                    maxHeight: 200,
                    minWidth: '20ch',
                    maxWidth: 400,
                  },
                }}
              >
                {unreviewedProduct.map((p, i) => (
                  <MenuItem key={p._id + i} onClick={() => chooseNewUnreviewedProduct(p)}>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={baseURLImage + p.media[0]}
                        alt={p.title}
                        variant="square"
                        sx={{ width: 20, height: 20, marginRight: 1 }}
                      />
                      <Typography variant="body1" fontWeight="300">
                        {p.title.length > 25 ? p.title.slice(0, 25) + '...' : p.title}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box display="flex">
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={handleClose}
                sx={{
                  bgcolor: '#DEDEDE',
                  marginRight: 2,
                  '&:hover': {
                    bgcolor: '#DEDEDE',
                  },
                  '&:active': {
                    bgcolor: '#DEDEDE',
                  },
                  '&:focus': {
                    bgcolor: '#DEDEDE',
                  },
                }}
              >
                Cancel
              </BootstrapButton>
              <BootstrapButton
                variant="contained"
                disableRipple
                disabled={!star}
                onClick={handleSubmit}
                sx={{
                  bgcolor: '#fff',
                }}
              >
                Post
              </BootstrapButton>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </StyledModal>
  );
};

export default NewReviewModelComponent;
