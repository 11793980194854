import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/features/userSlice';
import { setPreviousUrl } from '../../store/features/urlSlice';

const GuardedRoute = ({ Element }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  if (!user.role) {
    if (window.location.pathname !== '/login') dispatch(setPreviousUrl(window.location.pathname));
    return <Navigate to="/login" />;
  }

  return <>{Element}</>;
};

export default GuardedRoute;
