import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { LINKS } from '../../utils/constants';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';

import contactUsIcon from '../../assets/icons/contactUsIcon.svg';
import emailIcon from '../../assets/icons/emailIcon.svg';
import locationIcon from '../../assets/icons/locationIcon.svg';
import contactUsIconDM from '../../assets/icons/contactUsIconDM.svg';
import emailIconDM from '../../assets/icons/emailIconDM.svg';
import locationIconDM from '../../assets/icons/locationIconDM.svg';

function ContactUsPage() {
  const { t } = useTranslation();
  const { isSgMaxScreen } = useMediaQueries();

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <div className="flex justify-center px-4 py-8 min-[435px]:py-10 min-[435px]:px-6 sm:p-10 lg:p-20 xl:mx-20">
        <div className="max-w-7xl">
          <div className="flex flex-row items-center">
            <img src={isDarkMode ? contactUsIconDM : contactUsIcon} alt="Contact Us Icon" style={{ width: '30px' }} />
            <Typography fontWeight={600} fontSize={20} ml={2}>
              {t('Contact Us')}
            </Typography>
          </div>
          <Typography fontWeight={300} fontSize={isSgMaxScreen ? 14 : 16} mt={4}>
            {t(
              "Hey there! Stumped by an auction, tangled in tech, or just in the mood to chat about our awesome platform? Whether it's customer queries, tech puzzles, a little dispute resolution, or just a general shout-out, our team is ready to jump in! Think of us as your go-to crew for all things Outbidda. Just shoot us an email, and we'll get back to you."
            )}
          </Typography>
          <div className="flex flex-row mt-6">
            <img src={isDarkMode ? emailIconDM : emailIcon} alt="Email Icon" style={{ width: '30px' }} />
            <Typography fontWeight={500} fontSize={isSgMaxScreen ? 14 : 16} ml={2} className="flex items-center">
              {t('E-mail')}:{' '}
              <a className="underline ml-2" href={`mailto:${LINKS.EMAIL}`}>
                {LINKS.EMAIL}
              </a>
            </Typography>
          </div>
          <div className="flex flex-row items-center mt-6">
            <img src={isDarkMode ? locationIconDM : locationIcon} alt="Location Icon" style={{ width: '30px' }} />
            <Typography fontSize={isSgMaxScreen ? 14 : 16} ml={2}>
              {t('Address')}
            </Typography>
          </div>
          <Typography fontSize={isSgMaxScreen ? 14 : 16} className="flex flex-col" mt={2}>
            <span>{t('Outbidda Ltd')}.</span>
            <span>{t('112c High Street, Hadleigh, Suffolk')}</span>
            <span>{t('IP7 5EL, UK')}</span>
          </Typography>
        </div>
      </div>
    </>
  );
}

export default ContactUsPage;
