import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import axios from '../../config/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';

import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';

import { CustomButton, CustomInputs } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ChangePassword = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { isMdScreen } = useMediaQueries();
  const navigate = useNavigate();
  const { hash } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showMainPassword, setShowMainPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();

  const handleForgotPassword = async () => {
    if (!password) {
      setPasswordError(`${t('Password is required')}`);
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError(`${t('Passwords do not match')}`);
      return;
    }

    setIsLoading(true);
    axios
      .post('auth/change-password', {
        code: hash,
        newPassword: password,
      })
      .then(function (response) {
        setIsLoading(false);
        showSnackbar(null, 'Success, password has been changed!', 'success');
        navigate('/');
      })
      .catch(function (error) {
        setIsLoading(false);
        setPasswordError(error?.response?.data?.errors?.password?.message);
        showSnackbar(error);
      });
  };

  const handleCloseForm = () => {
    if (onClose) {
      onClose();
    }
    navigate('/');
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div style={{ minWidth: isMdScreen ? 600 : 200 }}>
          <>
            <DialogTitle>
              <div className="flex justify-end">
                <IconButton onClick={handleCloseForm}>
                  <ClearRoundedIcon style={{ fontSize: '32px', color: isDarkMode ? 'white' : 'black' }} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography className="text-center" variant="h4">
                {t('Change Password')}
              </Typography>
              <CustomInputs
                placeholder={t('Enter a new password')}
                variant="outlined"
                type={showMainPassword ? 'text' : 'password'}
                required
                sx={{ mt: 6 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowMainPassword(!showMainPassword)}
                        edge="end"
                      >
                        {showMainPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
                helperText={passwordError ? passwordError : ' '}
                error={!!passwordError}
              />
              <CustomInputs
                placeholder={t('Confirm Password')}
                variant="outlined"
                type={showConfirmPassword ? 'text' : 'password'}
                required
                sx={{ mt: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError('');
                }}
                helperText={confirmPasswordError ? confirmPasswordError : ' '}
                error={!!confirmPasswordError}
              />
            </DialogContent>
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                mb: 6,
              }}
            >
              <CustomButton variant="contained" disabled={isLoading} onClick={handleForgotPassword}>
                <LoadingIndicator
                  isLoading={isLoading}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={t('Save New Password')}
                />
              </CustomButton>
            </DialogActions>
          </>
        </div>
      </Dialog>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default ChangePassword;
