import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosConfig';
import { Typography } from '@mui/material';
import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import { useTranslation } from 'react-i18next';

import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';

import emailIcon from '../../assets/icons/emailIcon.svg';
import emailIconDM from '../../assets/icons/emailIconDM.svg';

const EmailForm = ({ open, onClose, email, title, mainText, addionalText, isUnverifiedUser }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();

  const handleResendVerificationLink = async () => {
    try {
      if (!isButtonActive) return;

      setIsButtonActive(false);
      setIsLoading(true);
      await axios.post(`auth/send/verified-email/${email}`, { email });

      setIsLoading(false);
      setCountdown(60);
    } catch (error) {
      setIsLoading(false);
      showSnackbar(error);
    }
  };

  useEffect(() => {
    let timer;

    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);
    } else {
      setIsButtonActive(true);
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <FormTemplate
        open={open}
        onClose={onClose}
        dialogTitle={
          <>
            <div className="flex justify-center">
              <img src={isDarkMode ? emailIconDM : emailIcon} alt="E-mail Icon" style={{ width: '48px' }} />
            </div>
          </>
        }
        dialogContent={
          <>
            <Typography variant="h4" align="center">
              {title ? title : `${t('Check your mail')}`}
            </Typography>
            {mainText && (
              <Typography variant="h6" mt={2} align="center" fontWeight={300}>
                {mainText}
              </Typography>
            )}
            {addionalText && (
              <Typography variant="h6" mt={2} align="center" fontWeight={300}>
                {addionalText}
              </Typography>
            )}
          </>
        }
        dialogActions={
          <>
            {isUnverifiedUser && (
              <CustomButton
                sx={{ mt: 4 }}
                variant="contained"
                onClick={handleResendVerificationLink}
                disabled={!isButtonActive || isLoading}
              >
                <LoadingIndicator
                  isLoading={isLoading}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={
                    isButtonActive
                      ? `${t('Resend verification link')}`
                      : `${t('Resend verification link')} (${countdown}${t('s')})`
                  }
                />
              </CustomButton>
            )}
          </>
        }
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default EmailForm;
