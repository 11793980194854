import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { CustomAccordion } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';

import arrowForUpAndDownIcon from '../../assets/icons/arrowForUpAndDownIcon.svg';
import arrowForUpAndDownIconDM from '../../assets/icons/arrowForUpAndDownIconDM.svg';

function CustomAccordionComponent({ title, content }) {
  const { t } = useTranslation();
  const { isSgMaxScreen } = useMediaQueries();

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={
          <img
            src={isDarkMode ? arrowForUpAndDownIconDM : arrowForUpAndDownIcon}
            alt="Choose Icon"
            style={{ width: '16px', height: '16px' }}
          />
        }
      >
        <Typography variant="subtitle2" fontSize={isSgMaxScreen ? 14 : 16} fontWeight={400}>
          {t(`${title}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {typeof content === 'string' ? (
          <Typography variant="body2" fontSize={isSgMaxScreen ? 14 : 16} fontWeight={200}>
            {content}
          </Typography>
        ) : (
          content
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
}

export default CustomAccordionComponent;
