import React from 'react';
import { Typography, Card } from '@mui/material';
import { baseURLImage } from '../../utils/constants';
import CardComponent from './CardTicketComponent.jsx';

import congratulationsIcon from '../../assets/icons/congratulationsIcon.svg';
import accountIcon from '../../assets/icons/accountIcon.svg';
import accountIconDM from '../../assets/icons/accountIconDM.svg';

function WinnerObserverCardComponents({ customer, kind, acceptedOrderList, highestBid, winnerUserTicketNumber }) {
  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Card
        className="p-6 w-full"
        style={{
          borderRadius: '0.75rem',
          maxWidth: 640,
        }}
        sx={{ minHeight: 240 }}
      >
        <div className="flex flex-col w-full justify-between items-center">
          <Typography align="center" fontSize={20}>
            {`The ${kind === 'Marketplace' ? 'Buyer' : 'Winner'}`}
          </Typography>
          <div className="pt-4 flex justify-evenly w-full items-center">
            <img src={congratulationsIcon} alt="Congratulations Icon" style={{ width: 30, height: 30 }} />
            <img
              src={
                !customer?.user?.picture
                  ? isDarkMode
                    ? accountIconDM
                    : accountIcon
                  : baseURLImage + customer?.user?.picture
              }
              style={{
                width: '121px',
                height: '121px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              alt={`${kind === 'Marketplace' ? 'Buyer' : 'Winner'} Icon`}
            />
            <img src={congratulationsIcon} alt="Congratulations Icon" style={{ width: 30, height: 30 }} />
          </div>
          <Typography className="underline" align="center" fontSize={20} fontWeight={500} mt={2}>
            {`${customer?.user?.firstName} ${customer?.user?.lastName}`}
          </Typography>
          {kind !== 'Marketplace' && (
            <>
              <Typography align="center" fontSize={20} mt={4} fontWeight={300}>
                {kind === 'Raffle' ? 'Ticket' : 'Winning bid'}
              </Typography>
              <CardComponent
                value={
                  kind === 'Auction'
                    ? acceptedOrderList?.[0]?.converter?.currency?.abbr_name + ' ' + highestBid
                    : winnerUserTicketNumber
                }
              />
            </>
          )}
        </div>
      </Card>
    </>
  );
}

export default WinnerObserverCardComponents;
