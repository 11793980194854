import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Rating, Avatar } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import axios from '../../config/axiosConfig';
import CustomTabs from '../../utils/ui/TabsComponent.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils.js';
import { baseURLImage } from '../../utils/constants.js';
import verificationIcon from '../../assets/icons/verificationIcon.svg';

import { UserReviewComponent } from './UserReviewComponent.jsx';
import { AuctionCardsComponent, RaffleCardsComponent, MarketplaceCardsComponent } from './ProductCardsComponent.jsx';

function UserComponent() {
  const navigate = useNavigate();

  const { userId, tabName } = useParams();
  const tabsName = useMemo(() => ['auctions', 'raffles', 'marketplace', 'reviews'], []);

  useEffect(() => {
    if (tabName && !tabsName.includes(tabName)) navigate('/404');
  }, [tabName, tabsName, navigate]);

  const [user, setUser] = useState({});
  const [authUser, setAuthUser] = useState();
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabName ? tabName : tabsName[3]);
  const [viewCurrency, setViewCurrency] = useState();

  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);
  const ProductType = {
    Raffle: 'Raffle',
    Auction: 'Auction',
    Marketplace: 'Marketplace',
  };

  const handleChangeMainTab = (e, value) => {
    const currentPath = window.location.pathname;
    const arrayPath = currentPath.split('/');
    arrayPath[3] = tabsName[value];

    setSelectedTab(tabsName[value]);
    window.history.replaceState(null, null, arrayPath.join('/'));
  };

  const fetchReviewsData = useCallback(async () => {
    try {
      const response = await axios.get(`user/${userId}/public`);

      setUser(response.data);
      setViewCurrency(response.data?.currency?._id);
      setUserDataLoaded(true);
    } catch (error) {
      console.error(error);
      showSnackbarRef.current(error);
      navigate('/404');
    }
  }, [userId, navigate]);

  const fetchAuthUserData = useCallback(async () => {
    try {
      const response = await axios.get(`user/me`);

      setAuthUser(response.data);
      setViewCurrency(response.data?.currency?._id);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchReviewsData();
    fetchAuthUserData();
  }, [fetchReviewsData, fetchAuthUserData]);

  useEffect(() => {
    if (tabName) setSelectedTab(tabName);
  }, [tabName, setSelectedTab]);

  return (
    <>
      <Box component="div" className="mb-20 px-2 md:px-5">
        {userDataLoaded && (
          <Box component="div" className="flex flex-col items-center mt-4">
            <Avatar
              src={baseURLImage + user.picture}
              style={{ width: '218px', height: '218px', borderRadius: '50%', objectFit: 'cover', fontSize: '130px' }}
              alt={`${user.firstName} ${user.lastName}`}
            />
            <Typography mt={2} fontWeight={500} fontSize={24} className="flex">
              <Box
                component="span"
                sx={{
                  width: 'fit-content',
                  position: 'relative',
                  '&::after': {
                    content: "''",
                    display: 'inline-block',
                    width: '27px',
                    height: '27px',
                    backgroundImage: `${user.verification === 'verified' ? `url(${verificationIcon})` : 'none'}`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    top: '-10px',
                    right: '-30px',
                  },
                }}
              >
                {user.firstName} {user.lastName}
              </Box>
            </Typography>
            <Typography mt={1} mb={1} fontSize={20} className="flex">
              {!!user.country && `From ${user.country.name}`}
            </Typography>
            <Rating
              value={user.averageStars || 0}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              size="medium"
            />
          </Box>
        )}
        <Box component="div" className="mt-6 px-2">
          <Box component="div" className="md:px-4">
            <CustomTabs
              tabs={[{ label: 'Auctions' }, { label: 'Raffles' }, { label: 'Marketplace' }, { label: 'Reviews' }]}
              selectedTab={tabsName.indexOf(selectedTab)}
              handleChange={(e, value) => handleChangeMainTab(e, value)}
              isLastBetween={false}
              titleSize={18}
            />
          </Box>
          <Box component="div" className="mt-4">
            {userDataLoaded && ( // check viewCurrency
              <Box component="div" className="mt-4 md:px-4 md:flex md:flex-row md:flex-wrap">
                {selectedTab === tabsName[0] ? (
                  <AuctionCardsComponent
                    userId={user._id}
                    viewCurrency={viewCurrency}
                    type={ProductType.Auction}
                    showSnackbar={showSnackbar}
                  />
                ) : selectedTab === tabsName[1] ? (
                  <RaffleCardsComponent
                    userId={user._id}
                    viewCurrency={viewCurrency}
                    type={ProductType.Raffle}
                    showSnackbar={showSnackbar}
                  />
                ) : selectedTab === tabsName[2] ? (
                  <MarketplaceCardsComponent
                    userId={user._id}
                    viewCurrency={viewCurrency}
                    type={ProductType.Marketplace}
                    showSnackbar={showSnackbar}
                  />
                ) : selectedTab === tabsName[3] && userDataLoaded ? (
                  <UserReviewComponent
                    userId={user._id}
                    authUser={authUser}
                    showSnackbar={showSnackbarRef.current}
                    userAverageStars={user.averageStars}
                  />
                ) : (
                  ''
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default UserComponent;
