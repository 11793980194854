import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from '../../config/axiosConfig';
import { Typography, Drawer, List, Grid, IconButton } from '@mui/material';

import CustomTabs from '../../utils/ui/TabsComponent.jsx';
import CustomCard from '../../utils/ui/CardComponent.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import { CustomPopover } from '../common/navbar/StyledNavbarComponent.jsx';
import CategoryList from './FilterCategoryList.jsx';
import { selectUser } from '../../store/features/userSlice';

import sortIcon from '../../assets/icons/sortIcon.svg';
import sortIconDM from '../../assets/icons/sortIconDM.svg';
import filterIcon from '../../assets/icons/filterIcon.svg';
import filterIconDM from '../../assets/icons/filterIconDM.svg';
import arrowForUpAndDownIcon from '../../assets/icons/arrowForUpAndDownIcon.svg';
import arrowForUpAndDownIconDM from '../../assets/icons/arrowForUpAndDownIconDM.svg';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const ProductSearchComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { isMdScreen, isXlScreen, isSgMaxScreen } = useMediaQueries();
  const [productData, setProductData] = useState(Array(3).fill(null));
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [prevSelectedTab, setPrevSelectedTab] = useState(null);
  const [userPopover, setUserPopover] = useState(null);
  const [sortBy, setSortBy] = useState('MostViewed');
  const [openFilterSideBar, setOpenFilterSideBar] = useState(false);
  const [isShowMoreItems, setIsShowMoreItems] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [totalPaginationPages, setTotalPaginationPages] = useState(1);
  const [currentPagePagination, setCurrentPagePagination] = useState(1);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);
  const topRef = useRef(null);
  const isRaffle = selectedTab === 0;
  const isAuction = selectedTab === 1;
  const isLateslPaginationPage = currentPagePagination === totalPaginationPages;

  const handleChangeTabs = (event, newValue) => {
    if (selectedTab !== prevSelectedTab) setPrevSelectedTab(newValue);

    setCurrentPagePagination(1);

    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;

    if (currentSearch) {
      const searchParams = new URLSearchParams(currentSearch);

      searchParams.set('tab', newValue);

      const newUrl = `${currentPath}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    } else {
      const newUrl = `${currentPath}?tab=${newValue}`;
      navigate(newUrl, { replace: true });
    }
  };

  const handleOpenUserPopover = (event) => setUserPopover(event.currentTarget);
  const handleCloseUserPopover = () => setUserPopover(null);
  const handleOpenFilterSideBar = async () => {
    setOpenFilterSideBar(true);
    const response = await axios.get('category/list?status=accepted');
    setCategories(response.data);
  };

  const handleCloseFilterSideBar = () => setOpenFilterSideBar(false);

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);

    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;

    if (currentSearch) {
      const searchParams = new URLSearchParams(currentSearch);

      searchParams.set('category', categoryId);

      const newUrl = `${currentPath}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    } else {
      const newUrl = `${currentPath}?category=${categoryId}`;
      navigate(newUrl, { replace: true });
    }
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;

    if (currentSearch) {
      const searchParams = new URLSearchParams(currentSearch);

      searchParams.set('sort', value);

      const newUrl = `${currentPath}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    } else {
      const newUrl = `${currentPath}?sort=${value}`;
      navigate(newUrl, { replace: true });
    }
    handleCloseUserPopover();
  };

  const handleShowMoreItems = () => {
    if (currentPagePagination <= totalPaginationPages) {
      setIsShowMoreItems(true);
      setCurrentPagePagination(currentPagePagination + 1);
    }
  };

  const handleScrollToTop = () => {
    topRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const isOpenSortPopover = Boolean(userPopover);
  const userPopoverId = isOpenSortPopover ? 'popover-account' : undefined;

  const sortOptions = isRaffle
    ? [
        { label: 'Most tickets sold', value: 'MostTicketsSold' },
        { label: 'Least tickets sold', value: 'LeastTicketsSold' },
      ]
    : isAuction
      ? [
          { label: 'Highest last bid', value: 'HighestLastBid' },
          { label: 'Lowest last bid', value: 'LowestLastBid' },
          { label: 'Most bids', value: 'MostBids' },
          { label: 'Least bids', value: 'LeastBids' },
        ]
      : [
          { label: 'Most items sold', value: 'MostItemsSold' },
          { label: 'Least items sold', value: 'LeastItemsSold' },
        ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!isShowMoreItems) setProductData(Array(3).fill(null));

    let productType = 'Raffle';
    try {
      const currentSearch = window.location.search;
      let selectedCategory = null;
      let sortBy = 'MostViewed';
      let search = null;

      if (currentSearch) {
        const searchParams = new URLSearchParams(currentSearch);

        const categoryValue = searchParams.get('category');
        if (categoryValue) {
          selectedCategory = categoryValue;
          setSelectedCategory(categoryValue);
        }

        const sortValue = searchParams.get('sort');
        if (sortValue) sortBy = sortValue;

        const searchValue = searchParams.get('search');
        if (searchValue) search = searchValue;

        const tabValue = searchParams.get('tab');
        if (tabValue) {
          setSelectedTab(Number(tabValue));
          const productTypes = {
            0: 'Raffle',
            1: 'Auction',
            2: 'Marketplace',
          };
          productType = productTypes[Number(tabValue)];
        }
      }
      let apiUrl = `product?type=${productType}&status=accepted&viewCurrency=${user.currency}&page=${currentPagePagination}`;

      if (selectedCategory) apiUrl += `&category=${selectedCategory}`;
      if (sortBy) apiUrl += `&sort=${sortBy}`;
      if (search) apiUrl += `&search=${search}`;

      const response = await axios.get(apiUrl);
      if (currentPagePagination > 1 && isShowMoreItems)
        setProductData((prevProductData) => [...prevProductData, ...response.data.list]);
      else setProductData(response.data.list);

      setTotalPaginationPages(response.data?.page_total);
    } catch (error) {
      showSnackbarRef.current(error);
    } finally {
      setLoading(false);
    }
  }, [user.currency, currentPagePagination, isShowMoreItems]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedCategory, sortBy, location.search]);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <div className="mt-4 pb-10 px-2 md:px-4 lg:px-10" ref={topRef}>
        <div className="mt-4 flex justify-center items-center px-4">
          <CustomTabs
            tabs={[{ label: 'Raffle' }, { label: 'Auction' }, { label: 'Marketplace' }]}
            selectedTab={selectedTab}
            handleChange={handleChangeTabs}
            titleSize={isSgMaxScreen ? 18 : null}
          />
          <div className="ml-auto flex flex-row">
            <button className="flex flex-row justify-between items-center ml-3" onClick={handleOpenFilterSideBar}>
              <img src={isDarkMode ? filterIconDM : filterIcon} alt="Filter Icon" width={26} />
            </button>
            <button
              className="flex flex-row justify-between items-center ml-4"
              aria-describedby={userPopoverId}
              onClick={handleOpenUserPopover}
            >
              <img src={isDarkMode ? sortIconDM : sortIcon} alt="Sort Icon" width={26} />
            </button>
          </div>
        </div>
        <div className={`mt-4 flex flex-wrap ${isSgMaxScreen ? 'justify-around' : ''}`}>
          <>
            {productData?.map((item, index) => (
              <div
                key={index}
                className="mt-8 md:px-4"
                style={{
                  maxWidth: isXlScreen ? 340 : isSgMaxScreen ? 150 : 310,
                }}
              >
                <CustomCard
                  item={item}
                  index={index}
                  isLoading={loading}
                  width={isXlScreen ? 315 : isSgMaxScreen ? 140 : 290}
                  height={isXlScreen ? 315 : isSgMaxScreen ? 250 : 290}
                />
              </div>
            ))}
            {productData.length === 0 && !loading && (
              <Typography sx={{ pt: 4, pb: 53, margin: '0 auto' }} fontSize={24}>
                {t('No items')}
              </Typography>
            )}
          </>
        </div>
        {productData.length !== 0 && !loading && (
          <>
            <div className="flex justify-center mt-4">
              <button
                onClick={isLateslPaginationPage ? handleScrollToTop : handleShowMoreItems}
                className="flex flex-row text-xl font-medium items-center"
              >
                <span>{isLateslPaginationPage ? t('Back to top') : t('Show more')} </span>
                <img
                  src={isDarkMode ? arrowForUpAndDownIconDM : arrowForUpAndDownIcon}
                  alt="Load More Icon"
                  style={{
                    marginLeft: 10,
                    width: '16px',
                    height: '16px',
                    transform: isLateslPaginationPage ? '' : 'rotate(180deg)',
                  }}
                />
              </button>
            </div>
          </>
        )}
      </div>
      <CustomPopover
        id={userPopoverId}
        open={isOpenSortPopover}
        anchorEl={userPopover}
        onClose={handleCloseUserPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {sortOptions.map((option, index) => (
          <button
            className="flex flex-row items-center p-4 pt-2 pr-6"
            key={index}
            onClick={() => handleSortBy(option.value)}
          >
            <Typography fontWeight={500} sx={{ ml: 2 }}>
              {option.label}
            </Typography>
          </button>
        ))}
      </CustomPopover>
      <Drawer open={openFilterSideBar} anchor="right" onClose={handleCloseFilterSideBar}>
        <List style={{ minWidth: isMdScreen ? '20vw' : '95vw' }}>
          <Grid container alignItems="center" className="pl-8">
            <Grid item>
              <IconButton onClick={handleCloseFilterSideBar}>
                <ClearRoundedIcon style={{ fontSize: '32px', color: isDarkMode ? 'white' : 'black' }} />
              </IconButton>
            </Grid>
          </Grid>
          <div className="ml-8">
            <CategoryList
              categories={categories}
              isSelectedCategory={selectedCategory}
              onClose={handleCloseFilterSideBar}
              onCategorySelect={handleCategorySelect}
            />
          </div>
        </List>
      </Drawer>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default ProductSearchComponent;
