import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import { privacyPolicyText } from '../../../assets/data/privacyPolicy.js';
import { termsAndConditionsText } from '../../../assets/data/termsAndConditions.js';

const TermsPolicyDialog = ({ open, handleClosePolicyModal, isPrivacyPolicy, agreeButton, t }) => {
  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <Dialog
      open={open}
      onClose={handleClosePolicyModal}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="flex justify-between items-center">
          <Typography component="div" variant="h5">
            {!isPrivacyPolicy ? `${t('Terms & Conditions')}` : `${t('Privacy Policy')}`}
          </Typography>
          <IconButton onClick={handleClosePolicyModal}>
            <ClearRoundedIcon style={{ fontSize: '32px', color: isDarkMode ? 'white' : 'black' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText component="div" id="scroll-dialog-description" tabIndex={-1}>
          {(!isPrivacyPolicy ? termsAndConditionsText : privacyPolicyText)?.map((section, index) => (
            <div key={index} className="pb-6">
              <Typography component="div" fontSize={22} fontWeight={500}>
                {t(section?.title)}
              </Typography>
              {section?.content?.map((paragraph, paragraphIndex) => (
                <React.Fragment key={paragraphIndex}>
                  {Array.isArray(paragraph) ? (
                    <ul className="list-disc">
                      {paragraph?.map((listItem, listIndex) => (
                        <li key={listIndex} style={{ listStyleType: 'disclosure-closed' }} className="mt-2 text-xl">
                          {t(listItem)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Typography component="div" key={paragraphIndex} mt={2} fontSize={20}>
                      {t(paragraph)}
                    </Typography>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {agreeButton && (
          <>
            <Button onClick={handleClosePolicyModal}>{t('Agree')}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TermsPolicyDialog;
