import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Typography, Button, FormGroup, FormControlLabel } from '@mui/material';
import { setCookie, selectCookie } from '../../store/features/cookieSlice';
import FormTemplate from './forms/FormTemplate.jsx';
import { useMediaQueries } from './js/useMediaQueries.js';

import {
  CustomButton,
  CustomIosAllSwitch,
  CustomIosSwitch,
} from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

import { styled } from '@mui/material/styles';

const StyledSnackbar = styled(Snackbar)({
  '& .MuiSnackbarContent-action': {
    width: '100%',
    paddingLeft: 0,
  },
});
const CookieSnackbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookie = useSelector(selectCookie);
  const { isMdScreen } = useMediaQueries();
  const [showCookieSnackbar, setShowCookieSnackbar] = useState(true);
  const [isOpenPreferencesForm, setIsOpenPreferencesForm] = useState(false);

  const [switchSelectAll, setSwitchSelectAll] = useState(true);
  const [switchWebPerfomance, setSwitchWebPerfomance] = useState(true);
  const [switchMarketing, setSwitchMarketing] = useState(true);
  const [selectAllSwitchStatus, setSelectAllSwitchStatus] = useState('on');

  const handleSwitchChange = (value, setFunction, otherSwitchValue) => {
    const newValue = !value;
    setFunction(newValue);
    if (otherSwitchValue && newValue) {
      setSwitchSelectAll(true);
      setSelectAllSwitchStatus('on');
    } else if (otherSwitchValue || newValue) {
      setSwitchSelectAll(true);
      setSelectAllSwitchStatus('middle');
    } else {
      setSwitchSelectAll(false);
      setSelectAllSwitchStatus('off');
    }
  };

  const handleSelectAllChange = () => {
    const newValue = !switchSelectAll;
    if (selectAllSwitchStatus === 'middle') {
      setSwitchSelectAll(true);
      setSelectAllSwitchStatus('on');
      setSwitchWebPerfomance(true);
      setSwitchMarketing(true);
    } else {
      setSwitchSelectAll(newValue);
      setSwitchWebPerfomance(newValue);
      setSwitchMarketing(newValue);
      setSelectAllSwitchStatus(newValue ? 'on' : 'off');
    }
  };

  useEffect(() => {
    setShowCookieSnackbar(cookie?.isCookieConsent);
  }, [cookie]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowCookieSnackbar(false);
    dispatch(setCookie({ isCookieConsent: true }));
    setTimeout(() => {
      window.executeIndexHTMLCode();
    }, 10);
  };

  const handleManageCookie = () => {
    setIsOpenPreferencesForm(true);
  };

  const handleAgreeCookie = () => {
    dispatch(setCookie({ isCookieConsent: true, isWebPerfomanceAnalyticsConsent: true, isMarketingConsent: true }));
    setTimeout(() => {
      window.executeIndexHTMLCode();
    }, 10);
  };

  const handleAgreeCookiePreferences = () => {
    dispatch(
      setCookie({
        isCookieConsent: true,
        isWebPerfomanceAnalyticsConsent: switchWebPerfomance,
        isMarketingConsent: switchMarketing,
      })
    );

    setIsOpenPreferencesForm(false);
    setTimeout(() => {
      window.executeIndexHTMLCode();
    }, 10);
  };

  return (
    <>
      <StyledSnackbar
        open={!showCookieSnackbar}
        onClose={handleSnackbarClose}
        message={
          <div className="px-[4%] lg:px-[3%] xl:px-[15%]" style={{ width: '100vw' }}>
            <Typography fontWeight={700} fontSize={30}>
              {t('We’ll tailor your experience')}
            </Typography>
            <Typography fontWeight={700} fontSize={14} mt={2} style={{ overflowY: 'auto', maxHeight: '20vh' }}>
              {t(
                `Outbidda uses cookies and other technologies to keep our sites reliable and secure, to measure their performance, to deliver a personalized shopping experience and personalized advertising. To do this, we collect information about users, their behavior, and their devices. If you select “That’s OK”, you accept this and agree that we may share this information with third parties, such as our marketing partners. This may mean that your data is processed worldwide. If you deny we won't use cookies and, unfortunately, you will not receive any personalized content. Select “Set preferences” for further details and to manage your options.`
              )}
            </Typography>
          </div>
        }
        action={
          <div
            className="px-[2%] lg:px-[3%] xl:px-[15%] py-4 flex items-center flex-col md:flex-row md:justify-evenly"
            style={{ width: '100%', paddingLeft: 0 }}
          >
            <Button variant="text" onClick={handleManageCookie} sx={{ textTransform: 'none' }}>
              <Typography sx={{ textDecoration: 'underline' }} color={'white'} fontWeight={700}>
                {t('Set preferences')}
              </Typography>
            </Button>
            <CustomButton
              variant="contained"
              onClick={handleAgreeCookie}
              sx={{ mt: isMdScreen ? 0 : 2, background: '#FFFFFF', '&:hover': { background: '#FFFFFF' } }}
            >
              {t(`That's OK`)}
            </CustomButton>
          </div>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ zIndex: 1300, width: '100%', bottom: -2 }}
      />
      <FormTemplate
        open={isOpenPreferencesForm}
        onClose={() => {
          setIsOpenPreferencesForm(false);
        }}
        isSticky={true}
        dialogTitle={
          <div>
            <Typography fontSize={26} fontWeight={700}>
              {t('Preferences')}
            </Typography>
            <Typography fontSize={14} mt={2}>
              {t(
                'This tool helps you to select and deactivate various tags / trackers / analytic tools used on this website.'
              )}
            </Typography>
          </div>
        }
        dialogContent={
          <>
            <FormGroup className="pl-2 min-[435px]:px-4">
              <FormControlLabel
                control={
                  <CustomIosAllSwitch
                    checked={switchSelectAll}
                    onChange={handleSelectAllChange}
                    status={selectAllSwitchStatus}
                  />
                }
                label={
                  <>
                    <div className="ml-6">
                      <Typography fontWeight={700} fontSize={14}>
                        {t('Select All')}
                      </Typography>
                    </div>
                  </>
                }
              />
              <FormControlLabel
                className="mt-6"
                style={{ display: 'flex', alignItems: 'flex-start' }}
                control={
                  <CustomIosSwitch
                    checked={switchWebPerfomance}
                    onChange={() => handleSwitchChange(switchWebPerfomance, setSwitchWebPerfomance, switchMarketing)}
                  />
                }
                label={
                  <>
                    <div className="ml-6">
                      <Typography fontWeight={700} fontSize={14}>
                        {t('Web Performance and Analytics')}
                      </Typography>
                      <Typography fontSize={12} pt={2}>
                        {t(
                          'We use this data to improve the performance of our websites and services, as well as to conduct user experience and business-relevant analysis of our stores and websites.'
                        )}
                      </Typography>
                    </div>
                  </>
                }
              />
              <FormControlLabel
                className="mt-6"
                style={{ display: 'flex', alignItems: 'flex-start' }}
                control={
                  <CustomIosSwitch
                    checked={switchMarketing}
                    onChange={() => handleSwitchChange(switchMarketing, setSwitchMarketing, switchWebPerfomance)}
                  />
                }
                label={
                  <>
                    <div className="ml-6">
                      <Typography fontWeight={700} fontSize={14}>
                        {t('Marketing')}
                      </Typography>
                      <Typography fontSize={12} pt={2}>
                        {t(
                          'Outbidda collects information about user journeys to deliver relevant sponsored content about our products (and products offered by our partners on the platform). To select content that fits your interests, we may for example use information about your purchases and interactions on the site. We may share this information and a customer identifier, like an encrypted e-mail address or device details, with third parties like Facebook and Google. This is to present you with relevant ads on third party websites and apps. We also may link this data across the different devices you use, as well as process data about the ads (for example, whether you clicked on an ad and then made a purchase). This is to measure ad performance and to enable ad billing. To provide you with a relevant marketing experience we also work with Google Analytics.'
                        )}
                      </Typography>
                    </div>
                  </>
                }
              />
            </FormGroup>
          </>
        }
        dialogActions={
          <>
            <CustomButton variant="contained" sx={{ my: 2 }} onClick={handleAgreeCookiePreferences}>
              {t('Save settings')}
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default CookieSnackbar;
