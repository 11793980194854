import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { padding: 20 },
  section: { marginBottom: 10 },
  title: { fontSize: 20, textAlign: 'center', marginBottom: 10 },
  text: { fontSize: 12 },
  footer: { fontSize: 10, textAlign: 'center', marginTop: 20, color: 'gray' },
});

const InvoicePDF = ({ transfer }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={styles.title}>Outbidda team</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.text}>Order: {transfer?._id}</Text>
        <Text style={styles.text}>
          Seller: {transfer?.user?.firstName} {transfer?.user?.lastName}
        </Text>
        <Text style={styles.text}>
          Buyer: {transfer?.customer?.firstName} {transfer?.customer?.lastName}
        </Text>
        <Text style={styles.text}>Status: {transfer?.status}</Text>
        <Text style={{ ...styles.text, marginBottom: 20, marginTop: 20 }}>Shipped from: {transfer?.country?.name}</Text>
        <Text style={styles.text}>
          Total sale price: {transfer?.converter?.price * transfer?.totalTicketsSold * (1 + transfer?.vatTax / 100)}{' '}
          {transfer?.converter?.currency?.abbr_name}
        </Text>
        <Text style={styles.text}>
          Platfrom fee (5%): {transfer?.converter?.price * transfer?.totalTicketsSold * 0.05}{' '}
          {transfer?.converter?.currency?.abbr_name}
        </Text>
        <Text style={styles.text}>
          VAT ({transfer?.vatTax}%):{' '}
          {transfer?.converter?.price * transfer?.totalTicketsSold * (transfer?.vatTax / 100)}{' '}
          {transfer?.converter?.currency?.abbr_name}
        </Text>
        <Text style={{ ...styles.text, marginBottom: 20, marginTop: 20 }}>
          Contents: {transfer?.title} / {transfer?.description}
        </Text>
      </View>

      <Text style={styles.footer}>Thank you for using our service!</Text>
    </Page>
  </Document>
);

export default InvoicePDF;
