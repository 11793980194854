import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../../config/axiosConfig';
import TextField from '@mui/material/TextField';
import {
  CustomAutocomplete,
  CustomButton,
} from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';

import { setUserCurrency, setUserCountry } from '../../../store/features/userSlice';
import { selectUserPrepopulateAddress } from '../../../store/features/userPrepopulateAddress';
import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import LoadingIndicator from '../../../utils/ui/LoadingIndicator.jsx';

const AutocompleteSelector = ({ value, onChange, options, getOptionLabel, label, helperText, error, disabled }) => {
  return (
    <CustomAutocomplete
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      sx={{ mt: 1 }}
      disabled={disabled}
      style={{ minWidth: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={label}
          label={label}
          required
          InputProps={{
            ...params.InputProps,
          }}
          helperText={helperText}
          error={error}
          disabled={disabled}
        />
      )}
    />
  );
};

const SingUpAdditionalAutocomplites = ({
  onSelect,
  additional,
  onClose,
  isSelectedCurrencyError,
  isSelectedCountryError,
  onSetError,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPrepopulateAddress = useSelector(selectUserPrepopulateAddress);

  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [country, setCountry] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [language, setLanguage] = useState([null]);
  const [selectedCurrencyError, setSelectedCurrencyError] = useState('');
  const [selectedCountryError, setSelectedCountryError] = useState('');
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const showSnackbarRef = useRef(showSnackbar);

  const handleError = () => {
    let hasError = false;
    if (selectedCountry === '' && !selectedCountry) {
      setSelectedCountryError(`${t('Country is required')}`);
      hasError = true;
    }
    if (selectedCurrency === '') {
      setSelectedCurrencyError(`${t('Currency is required')}`);
      hasError = true;
    }
    return hasError;
  };

  const handleSelectFields = () => {
    handleError();
    if (selectedCurrency && selectedCountry) {
      setIsLoading(true);
      axios
        .put('user/me', {
          country: selectedCountry,
          currency: selectedCurrency,
        })
        .then((response) => {
          dispatch(setUserCurrency(selectedCurrency));
          dispatch(setUserCountry(selectedCountry));
          setIsLoading(false);
          showSnackbar(null, 'Success', 'success');
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);
          showSnackbar(error);
        });
    } else {
      return;
    }
  };
  function findCountryIdByName(countries, countryName) {
    return countries.find((country) => country.name === countryName)?._id;
  }

  function findCurrencyIdByAbbr(currencies, abbrName) {
    return currencies.find((currency) => currency.abbr_name === abbrName)?._id;
  }
  const handleFetchData = useCallback(async () => {
    try {
      const [{ data: currencyResponse }, { data: countryResponse }] = await Promise.all([
        axios.get('currency'),
        axios.get('country'),
      ]);

      setCurrency(Array.isArray(currencyResponse) ? currencyResponse : []);
      setCountry(Array.isArray(countryResponse) ? countryResponse : []);
      setLanguage('');

      const prePopulateCountry = findCountryIdByName(countryResponse, userPrepopulateAddress?.country);
      const prePopulateCurrency = findCurrencyIdByAbbr(currencyResponse, userPrepopulateAddress?.currency);

      if (prePopulateCountry) setSelectedCountry(prePopulateCountry);
      if (prePopulateCurrency) setSelectedCurrency(prePopulateCurrency);
      else {
        const relevantCountriesWithCurrency = {
          Denmark: 'DKK',
          Norway: 'NOK',
          Sweden: 'SEK',
          Canada: 'CAD',
          'Great Britain': 'GBP',
          'United States': 'USD',
        };

        const countryName = userPrepopulateAddress?.country;

        const getPrePopulateCurrency =
          relevantCountriesWithCurrency[countryName] ||
          (userPrepopulateAddress?.continentCode === 'EU' ? 'EUR' : 'USD');

        const getPrePopulateCurrencyId = findCurrencyIdByAbbr(currencyResponse, getPrePopulateCurrency);

        setSelectedCurrency(getPrePopulateCurrencyId);
      }
    } catch (error) {
      showSnackbarRef.current(error);
    }
  }, [userPrepopulateAddress]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);
  useEffect(() => {
    onSelect && onSelect(selectedCountry, selectedCurrency);
  }, [onSelect, selectedCountry, selectedCurrency]);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <div className="flex flex-col items-center">
        <AutocompleteSelector
          value={selectedCountry ? country.find((country) => country._id === selectedCountry) : null}
          onChange={(event, newValue) => {
            setSelectedCountry(newValue ? newValue._id : '');
            setSelectedCountryError('');
            isSelectedCountryError && onSetError('selectedCountryError');
          }}
          options={country}
          getOptionLabel={(country) => country?.name}
          label={t('Choose country')}
          helperText={selectedCountryError || isSelectedCountryError || ' '}
          error={!!selectedCountryError || !!isSelectedCountryError}
        />
        <AutocompleteSelector
          value={selectedCurrency ? currency.find((currency) => currency._id === selectedCurrency) : null}
          onChange={(event, newValue) => {
            setSelectedCurrency(newValue ? newValue._id : '');
            setSelectedCurrencyError('');
            isSelectedCurrencyError && onSetError('selectedCurrencyError');
          }}
          options={currency}
          getOptionLabel={(currency) => currency?.abbr_name}
          label={t('Choose currency')}
          helperText={selectedCurrencyError || isSelectedCurrencyError || ' '}
          error={!!selectedCurrencyError || !!isSelectedCurrencyError}
        />
        <AutocompleteSelector
          value={selectedLanguage}
          onChange={(event, newValue) => {
            setSelectedLanguage(newValue ? newValue._id : '');
          }}
          disabled
          options={language}
          label={t('Choose language')}
        />
        {additional && (
          <>
            <CustomButton onClick={handleSelectFields} variant="contained" disabled={isLoading} sx={{ mt: 4 }}>
              <LoadingIndicator
                isLoading={isLoading}
                style={{ color: isDarkMode ? 'white' : null }}
                value={t('Save')}
              />
            </CustomButton>
          </>
        )}
      </div>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default SingUpAdditionalAutocomplites;
