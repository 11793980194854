import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    status: '',
    _id: '',
    picture: '',
    currency: process.env.REACT_APP_NODE_ENV === 'production' ? '6606ac954fbf9c7941c5e7fb' : '65158717bb30bd020a1ca060',
    country: '',
    abbr_name: '',
    shipping_address: {
      _id: '',
      country: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      postalCode: '',
    },
    billing_address: {
      _id: '',
      country: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      postalCode: '',
    },
    phone: '',
    verification: '',
  },
  reducers: {
    setUser: (state, action) => {
      if (action.payload) {
        const {
          email,
          firstName,
          lastName,
          role,
          status,
          _id,
          picture,
          currency,
          country,
          shipping_address,
          billing_address,
          phone,
          verification,
        } = action.payload;
        state.email = email;
        state.firstName = firstName;
        state.lastName = lastName;
        state.role = role;
        state.status = status;
        state._id = _id;
        state.picture = picture;
        state.currency = currency;
        state.country = country;
        state.shipping_address = shipping_address;
        state.billing_address = billing_address;
        state.phone = phone;
        state.verification = verification;
      } else {
        state.email = null;
        state.firstName = null;
        state.lastName = null;
        state.role = null;
        state.status = null;
        state._id = null;
        state.picture = null;
        state.currency = null;
        state.country = null;
        state.shipping_address = null;
        state.billing_address = null;
        state.phone = null;
      }
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
    },
    setUserCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setUserCountry: (state, action) => {
      state.country = action.payload;
    },
    setUserAbbrName: (state, action) => {
      state.abbr_name = action.payload;
    },
    setUserShippingAddress: (state, action) => {
      state.shipping_address = action.payload;
    },
    setUserBillingAddress: (state, action) => {
      state.billing_address = action.payload;
    },
  },
});

export const {
  setUser,
  setUserEmail,
  setUserCurrency,
  setUserCountry,
  setUserAbbrName,
  setUserShippingAddress,
  setUserBillingAddress,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
