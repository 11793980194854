import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { setPreviousUrl } from '../../store/features/urlSlice';
import { selectUser } from '../../store/features/userSlice';
import HomePage from '../../components/homePage/HomePageComponent.jsx';
import LoginForm from '../../components/forms/LoginForm.jsx';
import ForgotPassword from '../../components/forms/ForgotPassword.jsx';
import SimpleForm from '../../components/forms/singup/SignUpWithSocialAuthForm.jsx';
import FormTemplate from '../ui/forms/FormTemplate.jsx';
import ChangePassword from '../../components/forms/ChangePassword.jsx';
import CreateAuctionComponent from '../../components/createAuction/CreateAuctionComponent.jsx';
import EditProductPage from '../../components/editProductPage/EditProductPage.jsx';
import ProductComponent from '../../components/productPage/ProductComponent.jsx';
import ProductSearchComponent from '../../components/searchPage/ProductSearchComponent.jsx';
import ProfileComponent from '../../components/profilePage/ProfileComponent.jsx';
import NotFoundPage from '../../components/errors/NotFoundPage.jsx';
import AboutPage from '../../pages/aboutPage/AboutPage.jsx';
import ContactUsPage from '../../pages/contactUsPage/ContactUsPage.jsx';
import FaqPage from '../../pages/faqPage/FaqPage.jsx';
import ChatPage from '../../vue/vue-integrations/VueWrapper';
import BannedPage from '../../pages/BannedPage.jsx';
import GuardedRoute from './GuardedRoute.jsx';
import UserComponent from '../../components/userPage/UserComponent.jsx';
import faqData from '../../pages/faqPage/FaqData.js';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath !== '/login' && !currentPath.includes('/success-signin')) dispatch(setPreviousUrl(currentPath));
  }, [dispatch, navigate]);

  const faqRoutes = faqData.map((data) => (
    <Route
      path={data.route}
      key={data.route}
      element={<FaqPage pageData={data} nav={data.mainItem ? faqData.filter((d) => !d.mainItem) : []} />}
    />
  ));

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/:hash" element={<HomePage />} />
      <Route path="/login" element={<LoginForm open={true} setLoading={() => {}} isNeedToLoader={false} />} />
      <Route path="/forgot-password" element={<ForgotPassword open={true} />} />
      <Route path="/forgot-password/:hash" element={<ChangePassword open={true} />} />
      <Route
        path="/error-signin"
        element={<FormTemplate open={true} title="Error" subtitle="Error verified email" redirect={true} />}
      />
      <Route path="/success-signin/:hash" element={<SimpleForm />} />
      <Route
        path="/email-verification/:hash"
        element={<LoginForm open={true} setLoading={() => {}} isNeedToLoader={false} />}
      />
      <Route path="/create-product" element={<GuardedRoute Element={<CreateAuctionComponent />} />} />
      <Route path="/create-product/:hash" element={<GuardedRoute Element={<CreateAuctionComponent />} />} />
      <Route path="/product/:hash" element={<ProductComponent />} />
      <Route path="/product/:productId/edit" element={<GuardedRoute Element={<EditProductPage />} />} />
      <Route path="/search/:hash" element={<ProductSearchComponent />} />
      <Route path="/profile/:hash" element={<GuardedRoute Element={<ProfileComponent />} />} />
      <Route path="/user/:userId/:tabName?" element={<UserComponent />} />
      <Route path="/about-us" element={<AboutPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      {faqRoutes}
      <Route
        path="/chat"
        element={
          <GuardedRoute
            Element={
              <div key="chat-key">
                <ChatPage
                  componentProps={{
                    userId: user._id,
                    isDarkMode,
                  }}
                />
              </div>
            }
          />
        }
      />
      <Route path="/you-banned" element={<BannedPage />} />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
export default AppRoutes;
