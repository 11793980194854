import React from 'react';
const useTabsData = (t) => {
  const tabsData = [
    {
      label: t('About Us'),
      content: [
        t(
          'At Outbidda, we believe that the excitement of a good auction or raffle should be accessible to everyone, everywhere. Born from a passion for the dynamic and vibrant world of online bidding, our platform is a community-driven space where treasures are discovered, dreams are won, and the unexpected is just a click away.'
        ),
        t(
          "Founded by a team of enthusiasts who are as diverse as the items you'll find here, we've built a platform that combines the traditional spirit of auctions and raffles with the convenience and accessibility of the digital age. Our mission is to create a safe, engaging, and fair playground for both Sellers and Buyers."
        ),
      ],
    },
    {
      label: t('How it works'),
      content: [
        t(
          'Once your account is set up on Outbidda, a world of opportunities opens up for you. Feel free to dive into the excitement by placing a bid on an auction, buying a ticket for a raffle, or even creating your own auction or raffle.'
        ),
        <>
          <span style={{ fontWeight: 500 }}>{t('Bidding in Auctions: ')}</span>
          {t(
            'Outbidda auctions work just like any other online auction site: place your bid, submit it, and the highest bid at the auction’s end wins. Not a fan of bidding wars? No problem! Many items also offer a “Buy It Now” option, letting you skip the auction and purchase instantly.'
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Participating in Raffles: ')}</span>
          {t(
            "Each raffle ticket comes with a skill-based question linked to the theme of the item. Get it right, and you join the pool of potential winners; our system then randomly draws a winner from this group. But remember, an incorrect answer means you won't be in the running for the draw."
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Creating Your Auction or Raffle: ')}</span>
          {t(
            "Got something interesting to auction or an exciting raffle idea? All Outbidda account holders can create their own listings. Keep in mind, there's a brief wait time - up to 24 hours - as our dedicated moderators review your submission to ensure it meets our community standards."
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Payouts: ')}</span>
          {t(
            'For sellers who have successfully created an auction or raffle, we securely hold the sales earnings until the buyer or winner has received the parcel from the seller. This process ensures security and trust for both parties.'
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Verification badge: ')}</span>
          {t(
            "Stand out with our verification badge! Any user can apply for this badge to prove their legitimacy and build trust within the community. However, please note that not all applications will be approved, as the badge is granted only to users who meet our verification criteria. Apply now and show you're a trusted member!"
          )}
          <br />
          <br />
          {t(
            'To apply for the verification badge, please navigate over to your profile page and then click on settings. Scroll down to the bottom of the settings page and click on the “Apply today”.'
          )}
        </>,
      ],
    },
    {
      label: t('Why us?'),
      content: [
        <>
          <span style={{ fontWeight: 500 }}>{t('Accessibility for All: ')}</span>
          {t(
            "We believe that the thrill of auctions and raffles should be a universal experience. Whether you're a seasoned bidder or a newcomer to the scene, Outbidda is your playground."
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Diverse and Dynamic: ')}</span>
          {t(
            "Our platform reflects the diversity and dynamism of our community. Founded by enthusiasts who understand the pulse of online bidding, we offer an ever-evolving array of items, ensuring there's always something new and exciting to discover."
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Safe and Engaging Environment: ')}</span>
          {t(
            "Your trust is our top priority. We've designed Outbidda to be not just fun but also a secure and fair space. Whether you're buying, selling, or simply exploring, rest assured that you're in a safe and welcoming community."
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Empowering Creators: ')}</span>
          {t(
            "Have a unique item to auction or a creative raffle idea? Outbidda empowers you to be a creator. With the ability to host your auctions and raffles, you're not just a participant but a vital part of our vibrant marketplace."
          )}
        </>,
        <>
          <span style={{ fontWeight: 500 }}>{t('Commitment to Quality: ')}</span>
          {t(
            'Our dedicated moderators work around the clock to ensure that every listing meets our high community standards, maintaining an environment of quality and trust.'
          )}
        </>,
        t(
          "Outbidda isn't just a platform; it's a destination where dreams are won, treasures are uncovered, and the community thrives. Join us today and be part of a world where every click brings a new opportunity and every transaction builds a stronger community."
        ),
      ],
    },
  ];

  return tabsData;
};

export default useTabsData;
