import React from 'react';

import faqIcon from '../../assets/icons/faqIcon.svg';
import taxIcon from '../../assets/icons/taxIcon.svg';
import deliveryTruckIcon from '../../assets/icons/deliveryTruckIcon.svg';
import deliveryTimeIcon from '../../assets/icons/deliveryTimeIcon.svg';
import privacyPolicyIcon from '../../assets/icons/watchlistIcon.svg';
import faqIconDM from '../../assets/icons/faqIconDM.svg';
import taxIconDM from '../../assets/icons/taxIconDM.svg';
import deliveryTruckIconDM from '../../assets/icons/deliveryTruckIconDM.svg';
import deliveryTimeIconDM from '../../assets/icons/deliveryTimeIconDM.svg';
import privacyPolicyIconDM from '../../assets/icons/watchlistIconDM.svg';
import { privacyPolicyText } from '../../assets/data/privacyPolicy';

const faqData = [
  {
    title: 'FAQ',
    route: '/faq',
    alt: 'faq',
    src: faqIcon,
    srcDM: faqIconDM,
    mainItem: true,
    accordionsData: [
      {
        title: 'How do I create an auction or raffle?',
        content:
          'After signing up or logging in click on either the menu icon on the left or the profile icon on the right. In the menu you have the option to click on “Create raffle or auction” from there you will be redirected to the create an auction or raffle page, where you can list your item for auction or raffle.',
      },
      {
        title: 'What are the fees for creating an auction or raffle?',
        content:
          "Creating an auction or a raffle with us is absolutely free! But remember, nothing in life is truly free. So, when your auction item sells or those raffle tickets fly off the shelves, we'll take a modest 5%. For auctions, we take 5% of the winning bid, and for raffles, we get 5% of each ticket sold. It's our way of being part of the celebration, without actually throwing confetti!",
      },
      {
        title: 'Are Raffles in Outbidda a lottery?',
        content:
          "Nope, this isn't your run-of-the-mill lottery. It's a full-blown competition and falls within prize competitions or free draws. Here, the winners are decided by a skill-based game, not just luck. So, get your game face on!",
      },
      {
        title: 'What is a skill-based game?',
        content:
          "A game that focuses on skill is designed to evaluate a player's expertise or knowledge in a particular area. Participants who provide accurate responses in this skill-focused game will be eligible for the final selection process.",
      },
      {
        title: 'How is the winner drawn from a Raffle?',
        content:
          "Outbidda uses its own random draw generator. It's like a digital Fort Knox for raffles, ensuring every participant's experience is as safe and fair as a game of tic-tac-toe with your grandma. Scammers, beware!",
      },
      {
        title: 'Can I withdraw from a bid or raffle ticket purchase?',
        content: (
          <>
            <div style={{ fontWeight: 200 }}>
              Well, think of your bid or raffle ticket like a toothpaste squeezed out of the tube - there's no getting
              it back in!. We recommend all bidders and ticket buyers channel their inner chess grandmaster - ponder,
              strategize, and then make your move. Remember, in the game of bids and raffles, it's always wise to think
              twice before you roll the dice!
              <br />
              <i>
                * Note: For auctions only, if you loose an auction the amount will be refunded back to you. We only hold
                the money in your bank account until a higher bid then yours has been placed in the auction.
              </i>
            </div>
          </>
        ),
      },
      {
        title: 'Who can participate?',
        content:
          'For auctions, anybody who has created an account can participate, but raffles the entrants must be over the age of 18 years. ',
      },
      {
        title: 'How will I know if I won an auction or raffle?',
        content:
          'Winners are notified via email immediately after an auction ends or a raffle is drawn. You can also check the status of auctions and raffles you participated in your Outbidda profile.',
      },
      {
        title: 'How can I bid on an auction?',
        content:
          "To bid on an auction, first ensure you are logged in. Go to the auction page for the item you're interested in and enter your bid amount. If your bid is the highest when the auction ends, you will be declared the winner. ",
      },
      {
        title: 'Is it safe to make payments through your site?',
        content:
          "Yes, all payments are processed through Stripe, a renowned third-party payment provider known for its stringent security measures and compliance with payment processing regulations. By leveraging Stripe's secure platform, we ensure that your payment details are handled with the utmost care and security.",
      },
      {
        title: 'What happens if an item received is not as described?',
        content:
          'We have a dispute resolution process for such situations. Please report the issue to our support team, support@outbidda.com, within a specified timeframe, and our team will assist in resolving the issue.',
      },
      {
        title: 'How can I delete my data?',
        content:
          'You can leave an account deletion inquiry to our support team by leaving a message with "Delete my data" subject to support@outbidda.com email. You will receive success confirmation during the next 48 hours.',
      },
    ],
  },
  {
    title: 'Tax/VAT',
    route: '/faq-tax',
    alt: 'taxes',
    src: taxIcon,
    srcDM: taxIconDM,
    accordionsData: [
      {
        title: 'VAT Collection on Outbidda',
        content: (
          <>
            <div style={{ fontWeight: 200 }}>
              Outbidda ensures a seamless shopping experience by automatically calculating VAT (Value Added Tax) at
              checkout based on the buyer's local location. This guarantees compliance with tax regulations across
              regions.
              <br />
              <br />
              <b>How VAT Works by Region:</b>
              <ul style={{ listStyle: 'inside' }}>
                <li>
                  <b>EU Market:</b> VAT is applied to most goods and services sold within the European Union. The VAT
                  rate is based on the buyer's country of residence, ranging from 17% to 27% depending on the country.
                  Sellers may need to register for VAT in each country they sell to if they exceed specific thresholds.
                </li>
                <li>
                  <b>US Market:</b> While the U.S. does not have a federal VAT, sales tax is applied instead. Sales tax
                  rates vary by state and sometimes by locality. For online purchases, sales tax is collected based on
                  the buyer's state laws, particularly after the Wayfair decision requiring remote sellers to comply
                  with state tax laws.
                </li>
                <li>
                  <b>Canada Market:</b> VAT in Canada is collected as GST (Goods and Services Tax) or HST (Harmonized
                  Sales Tax), depending on the province. GST is federally set at 5%, while HST ranges up to 15% in
                  certain provinces. Some provinces also impose a provincial sales tax (PST).
                </li>
              </ul>
              <br />
              By automatically calculating and collecting VAT or sales taxes, Outbidda ensures compliance with local tax
              regulations, saving you time and effort. For more details, consult your local tax authority or a tax
              professional.
            </div>
          </>
        ),
      },
      {
        title: 'Private Seller Guidelines',
        content: (
          <>
            <div style={{ fontWeight: 200 }}>
              At Outbidda, private sellers are welcome to list and sell items, but restrictions apply if their sales
              activity resembles commercial trading. We review the following factors:
              <br />
              <br />
              <b>Frequency of Sales:</b> The number of transactions over a short period.
              <br />
              <b>Sales Volume:</b> The total revenue generated within a specific timeframe.
              <br />
              <br />
              If these metrics indicate commercial-level trading, the account may be suspended from selling until valid
              business registration is provided.
              <br />
              <br />
              <i>
                Important: Regular private sellers who trade at typical levels won’t be affected by this policy. These
                measures are in place to ensure compliance with tax regulations and to prevent unauthorized commercial
                activity.
              </i>
            </div>
          </>
        ),
      },
    ],
  },
  {
    title: 'Pre-Sell',
    route: '/faq-presell',
    alt: 'timer',
    src: deliveryTimeIcon,
    srcDM: deliveryTimeIconDM,
    accordionsData: [
      {
        title: 'Pre-Sale Eligibility and Guidelines',
        content: (
          <>
            <div style={{ fontWeight: 200 }}>
              Pre-sale eligibility is reserved for established professional sellers. To qualify, sellers must:
              <br />
              <br />
              <ul style={{ listStyle: 'inside' }}>
                <li>Have maintained a seller account for at least one year.</li>
                <li>Have earned a verification badge that proves the account’s legitimacy and trust</li>
                <li>Have completed at least 500 sales as a verified seller</li>
              </ul>
              <br />
              Pre-sale privileges are a responsibility, not a right. Any seller failing to fulfill pre-sale orders on
              time will lose this privilege. This ensures the integrity of the marketplace and builds trust with buyers.
            </div>
          </>
        ),
      },
      {
        title: 'What Products Can Be Pre-Sold on Outbidda?',
        content:
          'Verified sellers can pre-sell any product listed on Outbidda. Pre-orders require sellers to ship the product promptly on its release date. Sellers must ensure they can obtain the product before release to meet their obligations.',
      },
      {
        title: 'Responsibilities of Pre-Sale Sellers',
        content: (
          <>
            <div style={{ fontWeight: 200 }}>
              Professional sellers participating in pre-sales are required to:
              <br />
              <br />
              <ul style={{ listStyle: 'inside' }}>
                <li>Fulfill all pre-sale orders by the release date, as stated on the product page. </li>
                <li>
                  Maintain high standards of reliability, as failing to deliver on time will result in losing pre-sale
                  privileges.
                </li>
              </ul>
            </div>
          </>
        ),
      },
      {
        title: 'Recommendations for Professional Sellers',
        content: (
          <>
            <ul style={{ listStyle: 'inside', fontWeight: 200 }}>
              <li>
                <b>Sell What You Can Deliver:</b> Only pre-sell products you are confident you can ship on time. Avoid
                overcommitting, as it can harm your reputation and result in losing privileges.
              </li>
              <li>
                <b>Act Quickly if Issues Arise:</b> If you encounter difficulties, take all necessary steps—both time
                and cost-wise—to meet your commitments.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    title: 'Shipping',
    route: '/faq-shipping',
    alt: 'delivery',
    src: deliveryTruckIcon,
    srcDM: deliveryTruckIconDM,
    accordionsData: [
      {
        title: 'How Shipping Works on Outbidda',
        content:
          'At Outbidda, we aim to make shipping as seamless as possible for both buyers and sellers. To achieve this, we’ve implemented a streamlined shipping process, complete with shipping label creation and tracking updates. Please note that shipments through Outbidda are currently available in the EU, US, and Canada markets only.',
      },
      {
        title: 'Shipping Process for Raffles',
        content: (
          <>
            <ul style={{ listStyle: 'inside', fontWeight: 200 }}>
              <li>
                After a raffle concludes, the seller will receive an email prompting them to complete the shipping
                information and select a shipping carrier.
              </li>
              <li>
                Once the shipping information is provided, the buyer will receive an email with the tracking number to
                monitor their parcel’s progress.
              </li>
              <li>The cost of shipping for raffle items is covered by the seller.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Shipping Process for Auctions and Marketplace Sales',
        content: (
          <>
            <ul style={{ listStyle: 'inside', fontWeight: 200 }}>
              <li>
                When an auction ends, the buyer will receive an email to complete the shipping information and choose a
                shipping carrier.
              </li>
              <li>
                After the buyer submits the information, the seller will receive an email with the ready-to-print
                shipping label to attach to the parcel.
              </li>
              <li>In auctions and marketplace sales, the buyer pays for the shipping costs. </li>
            </ul>
          </>
        ),
      },
      {
        title: 'Tracking and Payment Security',
        content: (
          <>
            <div style={{ fontWeight: 200 }}>
              <ul style={{ listStyle: 'inside' }}>
                <li>
                  Outbidda provides buyers with a tracking link for every order, ensuring they can track their parcel’s
                  journey from shipment to delivery.
                </li>
                <li>
                  To protect both parties, Outbidda holds the funds in escrow until the parcel is successfully delivered
                  to the buyer. If any disputes arise, funds will be released only after the issue is resolved.
                </li>
              </ul>
              <br />
              By simplifying the shipping process and ensuring secure payments, Outbidda provides a reliable and
              trustworthy platform for all users.
            </div>
          </>
        ),
      },
    ],
  },
  {
    title: 'Privacy Policy',
    route: '/faq-privacy',
    alt: 'privacy-policy',
    src: privacyPolicyIcon,
    srcDM: privacyPolicyIconDM,
    accordionsData: privacyPolicyText.map((data) => {
      const content = (
        <>
          <div style={{ fontWeight: 200 }}>
            {data.content.map((el) => {
              if (typeof el === 'string')
                return (
                  <>
                    {el}
                    <br />
                    <br />
                  </>
                );
              if (typeof el === 'object')
                return (
                  <>
                    <ul style={{ listStyle: 'inside' }}>
                      {el.map((subEl) => (
                        <>
                          <li>{subEl}</li>{' '}
                        </>
                      ))}
                    </ul>
                  </>
                );
            })}
          </div>
        </>
      );

      return {
        title: data.title,
        content,
      };
    }),
  },
];

export default faqData;
