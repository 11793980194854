import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import axios from '../../config/axiosConfig';

import { CustomInputs, CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';

import EmailForm from './EmailForm.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';

import { setUserEmail } from '../../store/features/userSlice';

const AddEmailForm = ({ onOpen, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailErrorExists, setEmailErrorExists] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenedVerifyMessage, setIsOpenedVerifyMessage] = useState(false);

  const handleAddEmail = () => {
    if (email.trim()) {
      setIsLoading(true);
      axios
        .put('user/me', {
          email: email.trim(),
        })
        .then(() => {
          dispatch(setUserEmail(email));
          setIsLoading(false);
          if (onSuccess) {
            onSuccess(email);
          }
          setIsOpenedVerifyMessage(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setEmailErrorExists(error.response?.data?.response);
          setEmailError(error.response?.data?.errors?.email?.message);
        });
    } else {
      setEmailError('Email is requsired!');
      return;
    }
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <div>
      {!isOpenedVerifyMessage ? (
        <FormTemplate
          open={onOpen}
          onClose={onClose}
          title="Please add and verify your email in in order to proceed with platform functionality. We value privacy and security of our users and allow activities to verified accounts only."
          dialogContent={
            <>
              <CustomInputs
                placeholder={t('Email Address')}
                variant="outlined"
                type="email"
                required
                sx={{ mt: 1 }}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                helperText={emailError || emailErrorExists ? emailError || emailErrorExists : ' '}
                error={!!emailError || !!emailErrorExists}
              />
            </>
          }
          dialogActions={
            <>
              <CustomButton
                onClick={handleAddEmail}
                variant="contained"
                disabled={isLoading}
                sx={{ mt: 2 }}
                style={{ width: '100%', maxWidth: 304 }}
              >
                <LoadingIndicator
                  isLoading={isLoading}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={t('Save')}
                />
              </CustomButton>
            </>
          }
        />
      ) : (
        <EmailForm
          open={true}
          onClose={onClose}
          mainText={t(
            'Thank you for signing up! Please verify your email address through the verification link sent to your mail. Don’t forget to check the spam folder!'
          )}
        />
      )}
    </div>
  );
};

export default AddEmailForm;
