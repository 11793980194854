import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Skeleton, Chip, Box } from '@mui/material';

import { formatNumberWithTwoDecimals } from '../functions/formatNumberWithTwoDecimals';
import { baseURLImage } from '../constants';
import { useMediaQueries } from './js/useMediaQueries';

import ImagePreview from './ImagePreview.jsx';
import RaffleTimer from '../../components/productPage/RaffleTimer.jsx';

const CustomCard = ({
  item,
  index,
  isOnlyImages,
  noOpenImages,
  isLoading,
  profileFirstCardItem,
  profileSecondCardItem,
  chip,
  titleIcon,
  width,
  height,
  minWidth,
  maxWidth,
  margin,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState('');
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const location = useLocation();
  const { isSgMaxScreen } = useMediaQueries();
  const homeSliderView = location.pathname === '/' || location.pathname.includes('category');
  const recentlyViewedSliderMobile = location.pathname.includes('product') && isSgMaxScreen;
  const searchMobile = location.pathname.includes('search') && isSgMaxScreen;

  const handleMediaClick = (img) => {
    if (!noOpenImages) {
      setSelectedImages(baseURLImage + img);
      setIsImagePreviewOpen(true);
    }
  };

  const handleProductRedirect = (id) => navigate(`/product/${id}`);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Card
        sx={{ minWidth: minWidth, maxWidth: maxWidth }}
        style={{
          borderRadius: 10,
          width: width,
          height: height,
          boxShadow: '0 4px 16px rgb(0 0 0 / 8%)',
          margin: margin,
          background:
            homeSliderView && !isLoading && item?.media?.[0]
              ? `center / cover no-repeat url('${baseURLImage + item?.media?.[0]}')`
              : isDarkMode
                ? null
                : 'none',
          position: 'relative',
        }}
        onClick={() => (isOnlyImages ? handleMediaClick(item?.img) : handleProductRedirect(item?._id))}
      >
        {item?.productInfo?.kind === 'Raffle' &&
          item?.status === 'accepted' &&
          !!item?.end &&
          new Date() < new Date(item?.end) && (
            <Box component="div" sx={{ position: 'absolute', right: 20, top: 20 }}>
              <RaffleTimer targetDate={item?.end} spacingBetweenBlock={'1px'} boxSize="43px" />
            </Box>
          )}

        {isLoading ? (
          <Skeleton sx={{ height: height ? height : 300 }} animation="wave" variant="rectangular" />
        ) : (
          <>
            <CardMedia
              className="justify-center items-center"
              sx={{
                height: searchMobile ? 150 : isOnlyImages ? '100%' : '75%',
                cursor: 'pointer',
                display: 'flex',
                opacity: homeSliderView && item?.media?.[0] ? 0 : 100,
              }}
              image={isOnlyImages ? baseURLImage + item?.img : baseURLImage + item?.media?.[0]}
              title={`image${index + 1}`}
            >
              {isOnlyImages
                ? !item?.img
                : !item?.media?.[0] && (
                    <Typography
                      align="center"
                      fontSize={homeSliderView ? 20 : 16}
                      px={1}
                      mt={homeSliderView ? 'calc(50% - 60px)' : 0}
                    >
                      {t('No image')}
                    </Typography>
                  )}
            </CardMedia>
          </>
        )}
        {!isOnlyImages && (
          <CardContent
            className="flex flex-col justify-center"
            style={{
              boxShadow: homeSliderView ? 'none' : '0 3px 10px rgb(0 0 0 / 0.2)',
              height: searchMobile ? 100 : '25%',
              padding: '0 1rem',
              color: homeSliderView ? '#fff' : 'inherit',
              background: homeSliderView ? 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)' : 'none',
            }}
          >
            <div className="flex justify-between">
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  fontSize: 16,
                  fontWeight: 500,
                }}
                gutterBottom
                variant="h5"
                component="div"
              >
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    sx={{
                      fontSize: '50rem',
                    }}
                  />
                ) : (
                  <>
                    <div className="flex justify-between">
                      <span
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item?.title}
                      </span>
                      <span className="flex items-start">{titleIcon}</span>
                    </div>
                  </>
                )}
              </Typography>
              {chip && (
                <>
                  {isLoading ? (
                    <Skeleton animation="wave" height={20} width="20%" sx={{ mt: 1 }} />
                  ) : (
                    <Chip
                      label={chip.label}
                      sx={{ ml: 1 }}
                      style={{
                        color: chip.color !== '#FFFFFF' ? '#FFFFFF' : '#000000',
                        background: chip.color,
                      }}
                      variant={chip.color === '#FFFFFF' ? 'outlined' : undefined}
                    />
                  )}
                </>
              )}
            </div>
            <div className={`flex justify-between ${searchMobile ? 'flex-col' : ''}`}>
              {isLoading ? (
                <Skeleton animation="wave" height={10} width="30%" variant="text" />
              ) : (
                <>
                  <Typography variant="body2">
                    {profileFirstCardItem ? (
                      <>
                        {profileFirstCardItem?.title}:{' '}
                        <span className="font-bold">
                          {profileFirstCardItem?.value?.currency}{' '}
                          {profileFirstCardItem?.value?.price
                            ? formatNumberWithTwoDecimals(profileFirstCardItem?.value?.price)
                            : 'None'}
                        </span>
                      </>
                    ) : (
                      <>
                        {!recentlyViewedSliderMobile && !searchMobile && (
                          <span className="font-bold">{item?.productInfo?.kind}</span>
                        )}
                      </>
                    )}
                  </Typography>
                </>
              )}
              <Typography variant="body2" className={`${searchMobile ? 'flex flex-col' : ''}`}>
                {isLoading ? (
                  <Skeleton animation="wave" height={10} width="40%" variant="text" sx={{ mb: 3.7 }} />
                ) : (
                  <>
                    {profileSecondCardItem ? (
                      <>
                        {
                          <>
                            {profileSecondCardItem?.title}:{' '}
                            <span className="font-bold">
                              {profileSecondCardItem?.value?.currency && profileSecondCardItem?.value?.currency}{' '}
                              {profileSecondCardItem?.value?.price
                                ? formatNumberWithTwoDecimals(profileSecondCardItem?.value?.price)
                                : 'None'}
                            </span>
                          </>
                        }{' '}
                      </>
                    ) : (
                      <>
                        {item?.productInfo?.kind === 'Auction'
                          ? `${t('Last Bid')}`
                          : `${t(`${item?.productInfo?.kind === 'Raffle' ? 'Ticket' : ''} Price`)}`}
                        :{' '}
                        <span className="font-bold">
                          {item?.converter?.currency?.abbr_name}{' '}
                          {item?.productInfo?.kind === 'Auction' ? (
                            <>
                              {item?.orderList?.[0]?.converter?.price ? (
                                <>{formatNumberWithTwoDecimals(item?.orderList?.[0]?.converter?.price)}</>
                              ) : (
                                'None'
                              )}
                            </>
                          ) : (
                            formatNumberWithTwoDecimals(item?.converter?.price)
                          )}
                        </span>
                      </>
                    )}
                  </>
                )}
              </Typography>
            </div>
          </CardContent>
        )}
      </Card>
      <ImagePreview
        open={isImagePreviewOpen}
        onClose={() => {
          setIsImagePreviewOpen(false);
        }}
        imageUrl={selectedImages}
      />
    </>
  );
};

export default CustomCard;
