import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Card, Skeleton } from '@mui/material';

import axios from '../../config/axiosConfig.js';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries.js';
import CustomCard from '../../utils/ui/CardComponent.jsx';
import { getMyProductsChipColor } from '../profilePage/cardChipColors';

const loadingComponentCard = (width, height) => {
  return [null, null, null, null, null, null].map((_, i) => (
    <Card
      sx={{
        width,
        margin: '16px 16px 0 0',
        borderRadius: 10,
        boxShadow: '0 4px 16px rgb(0 0 0 / 8%)',
      }}
      key={`loadingProfileReviewComponent${i}`}
    >
      <Skeleton sx={{ height }} animation="wave" variant="rectangular" />
    </Card>
  ));
};

// type is enum Raffle, Auction or Marketplace
export function AuctionCardsComponent({ userId, type, showSnackbar, viewCurrency }) {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isXlScreen } = useMediaQueries();

  const fetchReviewsData = useCallback(async () => {
    try {
      const response = await axios.get(`product/owner/${userId}/public?kind=${type}&viewCurrency=${viewCurrency}`);

      setProduct(response.data);
    } catch (error) {
      console.error(error);
      showSnackbar(error);
    } finally {
      setLoading(false);
    }
  }, [userId, type, viewCurrency, showSnackbar]);

  useEffect(() => {
    fetchReviewsData();
  }, [fetchReviewsData]);

  if (loading) return loadingComponentCard(isXlScreen ? 315 : 290, isXlScreen ? 315 : 290);

  return (
    <>
      <Box
        component="div"
        className="mt-4 md:px-4 md:flex md:flex-row md:flex-wrap"
        style={{
          justifyContent: 'start',
        }}
      >
        {!loading && product.length === 0 && <Typography>None</Typography>}
        {!loading &&
          product.map((item, index) => (
            <div
              className="mt-4 md:pr-4"
              style={{
                maxWidth: isXlScreen ? 340 : 310,
              }}
              key={index}
            >
              <CustomCard
                item={item}
                index={index}
                isLoading={loading}
                width={isXlScreen ? 315 : 290}
                height={isXlScreen ? 315 : 290}
                profileFirstCardItem={{
                  title: 'Last highest bid',
                  value: {
                    currency: item?.orderList?.[0]?.converter?.currency?.abbr_name,
                    price: item?.orderList?.[0]?.converter?.price,
                  },
                }}
                profileSecondCardItem={{
                  title: 'Total bids',
                  value: {
                    currency: null,
                    price: item?.totalBids,
                  },
                }}
                chip={{
                  ...getMyProductsChipColor(item?.status),
                }}
              />
            </div>
          ))}
      </Box>
    </>
  );
}

export function RaffleCardsComponent({ userId, type, showSnackbar, viewCurrency }) {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isXlScreen } = useMediaQueries();

  const fetchReviewsData = useCallback(async () => {
    try {
      const response = await axios.get(`product/owner/${userId}/public?kind=${type}&viewCurrency=${viewCurrency}`);

      setProduct(response.data);
    } catch (error) {
      console.error(error);
      showSnackbar(error);
    } finally {
      setLoading(false);
    }
  }, [userId, type, viewCurrency, showSnackbar]);

  useEffect(() => {
    fetchReviewsData();
  }, [fetchReviewsData]);

  if (loading) return loadingComponentCard(isXlScreen ? 315 : 290, isXlScreen ? 315 : 290);

  return (
    <>
      <Box
        component="div"
        className="mt-4 md:px-4 md:flex md:flex-row md:flex-wrap"
        style={{
          justifyContent: 'start',
        }}
      >
        {!loading && product.length === 0 && <Typography>None</Typography>}
        {!loading &&
          product.map((item, index) => (
            <div
              className="mt-4 md:pr-4"
              style={{
                maxWidth: isXlScreen ? 340 : 310,
              }}
              key={index}
            >
              <CustomCard
                item={item}
                index={index}
                isLoading={loading}
                width={isXlScreen ? 315 : 290}
                height={isXlScreen ? 315 : 290}
                profileFirstCardItem={{
                  title: 'Ticket price',
                  value: {
                    currency: item?.converter?.currency?.abbr_name,
                    price: item?.converter?.price,
                  },
                }}
                profileSecondCardItem={{
                  title: 'Total tickets sold',
                  value: {
                    currency: null,
                    price: item?.totalTicketsSold,
                  },
                }}
                chip={{
                  ...getMyProductsChipColor(item?.status),
                }}
              />
            </div>
          ))}
      </Box>
    </>
  );
}

export function MarketplaceCardsComponent({ userId, type, showSnackbar, viewCurrency }) {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isXlScreen } = useMediaQueries();

  const fetchReviewsData = useCallback(async () => {
    try {
      const response = await axios.get(`product/owner/${userId}/public?kind=${type}&viewCurrency=${viewCurrency}`);

      setProduct(response.data);
    } catch (error) {
      console.error(error);
      showSnackbar(error);
    } finally {
      setLoading(false);
    }
  }, [userId, type, viewCurrency, showSnackbar]);

  useEffect(() => {
    fetchReviewsData();
  }, [fetchReviewsData]);

  if (loading) return loadingComponentCard(isXlScreen ? 315 : 290, isXlScreen ? 315 : 290);

  return (
    <>
      <Box
        component="div"
        className="mt-4 md:px-4 md:flex md:flex-row md:flex-wrap"
        style={{
          justifyContent: 'start',
        }}
      >
        {!loading && product.length === 0 && <Typography>None</Typography>}
        {!loading &&
          product.map((item, index) => (
            <div
              className="mt-4 md:pr-4"
              style={{
                maxWidth: isXlScreen ? 340 : 310,
              }}
              key={index}
            >
              <CustomCard
                item={item}
                index={index}
                isLoading={loading}
                width={isXlScreen ? 315 : 290}
                height={isXlScreen ? 315 : 290}
                profileFirstCardItem={{
                  title: 'Item price',
                  value: {
                    currency: item?.converter?.currency?.abbr_name,
                    price: item?.converter?.price,
                  },
                }}
                profileSecondCardItem={{
                  title: 'Items left',
                  value: {
                    currency: null,
                    price: item?.productInfo?.current_amount,
                  },
                }}
                chip={{
                  ...getMyProductsChipColor(item?.status),
                }}
              />
            </div>
          ))}
      </Box>
    </>
  );
}
