import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Divider } from '@mui/material';

import AccordionComponent from '../../utils/ui/AccordionComponent.jsx';

function FaqPage({ pageData, nav }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  const onHover = (event) => (event.target.style.borderColor = event.type === 'mouseover' ? '#000' : '#d6d6d6');
  const faqs = nav.map((navPage) => (
    <div
      className="flex flex-col items-center justify-center mx-2 my-2"
      style={{
        width: 200,
        height: 200,
        cursor: 'pointer',
        textAlign: 'center',
        border: '2px solid #d6d6d6',
        borderRadius: 8,
      }}
      key={navPage.route}
      onMouseOver={onHover}
      onMouseOut={onHover}
      onClick={() => navigate(navPage.route)}
    >
      <img
        style={{
          maxWidth: 140,
          width: isDarkMode ? null : 140,
          height: 60,
          pointerEvents: 'none',
        }}
        alt={navPage.alt}
        src={isDarkMode ? navPage.srcDM : navPage.src}
      />
      <p
        className="mt-2"
        style={{
          fontWeight: 600,
          fontSize: 24,
          pointerEvents: 'none',
        }}
      >
        {navPage.title}
      </p>
    </div>
  ));

  return (
    <>
      <div className="flex justify-center px-4 py-8 min-[435px]:py-10 min-[435px]:px-6 sm:p-10 lg:p-20 xl:mx-20">
        <div>
          <div className="flex flex-row items-center">
            <img src={isDarkMode ? pageData.srcDM : pageData.src} alt={pageData.alt} style={{ width: '30px' }} />
            <Typography fontWeight={600} fontSize={20} ml={2}>
              {t(pageData.title)}
            </Typography>
          </div>
          <div className="mt-6 max-w-7xl">
            <Divider />
            {!!nav.length && (
              <>
                {' '}
                <div className="flex justify-center max-[435px]:flex-col max-[435px]:items-center w-full my-6">
                  {faqs}
                </div>
                <Divider />
              </>
            )}
            <div className="my-6">
              {pageData.accordionsData.map((accordion, index) => (
                <div key={index}>
                  <div className="my-6">
                    <AccordionComponent title={accordion.title} content={accordion.content} />
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqPage;
