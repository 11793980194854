import { createSlice } from '@reduxjs/toolkit';

const cookieSlice = createSlice({
  name: 'cookie',
  initialState: {
    isCookieConsent: false,
    isWebPerfomanceAnalyticsConsent: false,
    isMarketingConsent: false,
  },
  reducers: {
    setCookie: (state, action) => {
      if (action.payload) {
        const { isCookieConsent, isWebPerfomanceAnalyticsConsent, isMarketingConsent } = action.payload;
        state.isCookieConsent = isCookieConsent;
        state.isWebPerfomanceAnalyticsConsent = isWebPerfomanceAnalyticsConsent;
        state.isMarketingConsent = isMarketingConsent;
      } else {
        state.isCookieConsent = null;
        state.isWebPerfomanceAnalyticsConsent = null;
        state.isMarketingConsent = null;
      }
    },
  },
});

export const { setCookie } = cookieSlice.actions;

export const selectCookie = (state) => state.cookie;

export default cookieSlice.reducer;
