import React, { useState } from 'react';
import { Avatar, Box, Card, CardContent, Typography, Divider, Rating, Button, Skeleton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { baseURLImage } from '../../utils/constants.js';
import TooltipButton from '../../utils/ui/TooltipComponent.jsx';

import locationIcon from '../../assets/icons/locationIcon.svg';
import locationIconDM from '../../assets/icons/locationIconDM.svg';
import arrowIcon from '../../assets/icons/arrowForUpAndDownIcon.svg';
import arrowIconDM from '../../assets/icons/arrowForUpAndDownIconDM.svg';
import verificationIcon from '../../assets/icons/verificationIcon.svg';

import ReplyModelComponent from './ReplyModelComponent.jsx';

export function ReviewListComponent({ userId, reviews, loading, showSnackbar }) {
  if (loading) {
    return [null, null, null].map((_, i) => (
      <Card
        sx={{
          width: '100%',
          margin: '20px 0',
          borderRadius: 2,
          boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.08)',
        }}
        key={`loadingProfileReviewComponent${i}`}
      >
        <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
      </Card>
    ));
  }

  return (
    <>
      {reviews.length !== 0 &&
        reviews.map((review, i) => (
          <ReviewCardComponent key={review._id} review={review} userId={userId} showSnackbar={showSnackbar} />
        ))}
    </>
  );
}

export function ReviewCardComponent({ review, userId, showSnackbar }) {
  const [collapseReplay, setCollapseReplay] = useState(true);
  const [modelOpen, setModelOpen] = useState(false);
  const [currentReviewReplies, setCurrentReviewReplies] = useState(review.replies);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleModelOpen = () => setModelOpen(true);
  const handleModelClose = () => setModelOpen(false);

  const userLink = review.isGenerated
    ? '/'
    : `/user/${review.reviewer?._id}/${review.product?.productInfo?.kind === 'Raffle' ? 'raffles' : 'auctions'}`;

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <Card
      sx={{
        width: '100%',
        margin: '20px 0',
        padding: 2,
        borderRadius: 2,
        boxShadow: '0px 2px 6px 2px  rgba(0, 0, 0, 0.08)',
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          {review.isGenerated ? (
            <Avatar
              sx={{
                height: 70,
                width: 70,
                bgcolor: 'white',
                boxShadow: '0px 1px 3px 3px rgba(0, 0, 0, 0.08)',
                color: 'black',
                fontSize: 50,
                fontWeight: 800,
                marginRight: 4,
                cursor: 'pointer',
              }}
              onClick={() => navigate(userLink)}
            >
              O
            </Avatar>
          ) : (
            <Avatar
              src={baseURLImage + review.reviewer?.picture}
              alt="Buyer Avatar"
              sx={{ width: 70, height: 70, marginRight: 4, cursor: 'pointer' }}
              onClick={() => navigate(userLink)}
            />
          )}

          <Box>
            <Typography variant="body1" fontSize="14" fontWeight="500" onClick={() => navigate(userLink)}>
              <Box
                component="span"
                sx={{
                  width: 'fit-content',
                  cursor: 'pointer',
                  position: 'relative',
                  '&::after': {
                    content: "''",
                    display: 'inline-block',
                    width: '17px',
                    height: '17px',
                    backgroundImage: `${review.reviewer?.verification === 'verified' || review.isGenerated ? `url(${verificationIcon})` : 'none'}`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    top: '-9px',
                    right: '-19px',
                  },
                }}
              >
                {review.isGenerated
                  ? 'Outbidda official'
                  : `${review.reviewer?.firstName} ${review.reviewer?.lastName}`}
              </Box>
            </Typography>
            <Typography component="div" variant="body2" fontSize="14" display="flex" alignItems="center">
              {review.isGenerated ? (
                <>
                  Automated review
                  <TooltipButton
                    marginTop={-12}
                    marginLeft={3}
                    marginBottom={0}
                    width={12}
                    title={t('Auto-feedback')}
                  />
                </>
              ) : (
                review.reviewer?.country?.name && (
                  <>
                    <img
                      src={isDarkMode ? locationIconDM : locationIcon}
                      alt="Location Icon"
                      style={{ maxHeight: '15px', marginTop: '-2px', marginRight: '3px' }}
                    />
                    {review.reviewer.country.name}
                  </>
                )
              )}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ marginY: 3 }} />

        <Box mb={2}>
          <Box display="flex" alignItems="center" mb={2} ml={1}>
            <Rating
              name="text-feedback"
              value={review.star}
              readOnly
              precision={1}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              sx={{ marginRight: 2 }}
              size="small"
            />
            <Typography variant="body2" color="textSecondary">
              {timeAgo(review.createdAt)}
            </Typography>
          </Box>
          <Typography variant="body1" gutterBottom>
            {review.text}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ marginRight: 2 }}>
            <Typography variant="caption" color="textSecondary">
              Type
            </Typography>
            <Typography variant="body1" fontWeight="500">
              {review.product.productInfo.kind}
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />

          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/product/${review.product._id}`)}
          >
            <Avatar
              src={baseURLImage + review.product.media[0]}
              alt={review.product.title}
              variant="rounded"
              sx={{ width: 40, height: 40, marginRight: 2 }}
            />
            <Typography variant="body1" fontWeight="500">
              {review.product.title}
            </Typography>
          </Box>
        </Box>

        {currentReviewReplies.length > 0 && (
          <>
            <Divider sx={{ marginY: 2 }} />

            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={baseURLImage + review.productOwner.picture}
                    alt={review.productOwner.firstName}
                    sx={{ width: 48, height: 48, marginRight: 2 }}
                  />
                  <Box>
                    <Typography variant="subtitle1" fontWeight="500">
                      <Box
                        component="span"
                        sx={{
                          width: 'fit-content',
                          position: 'relative',
                          '&::after': {
                            content: "''",
                            display: 'inline-block',
                            width: '20px',
                            height: '20px',
                            backgroundImage: `${review.productOwner.verification === 'verified' ? `url(${verificationIcon})` : 'none'}`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            position: 'absolute',
                            top: '-5px',
                            right: '-25px',
                          },
                        }}
                      >
                        {`${review.productOwner.firstName} ${review.productOwner.lastName}`}
                      </Box>
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      Seller
                    </Typography>
                  </Box>
                </Box>
                <Button variant="text">
                  {collapseReplay ? (
                    <img
                      src={isDarkMode ? arrowIconDM : arrowIcon}
                      alt="collapse"
                      onClick={() => setCollapseReplay(false)}
                      style={{ height: 30, transform: 'rotate(180deg)' }}
                    />
                  ) : (
                    <img src={arrowIcon} alt="unfold" onClick={() => setCollapseReplay(true)} style={{ height: 30 }} />
                  )}
                </Button>
              </Box>
              {!collapseReplay &&
                currentReviewReplies.map((el, i, arr) => {
                  return (
                    <Box key={`ProfileReviewCardComponentReplay${i}`}>
                      <Typography variant="body2" ml={7}>
                        {currentReviewReplies[i]}
                      </Typography>
                      {arr.length - 1 !== i && <Divider sx={{ marginY: 2, marginLeft: 7 }} />}
                    </Box>
                  );
                })}
            </Box>
          </>
        )}

        {userId && userId === review.productOwner._id && !review.isGenerated && (
          <>
            <Typography
              variant="body1"
              onClick={handleModelOpen}
              fontWeight="600"
              sx={{
                cursor: 'pointer',
                display: 'inline-block',
                borderBottom: '3px solid black',
                marginTop: 3,
              }}
            >
              Reply buyer
            </Typography>
            <ReplyModelComponent
              open={modelOpen}
              handleClose={handleModelClose}
              review={review}
              setCurrentReviewReplies={setCurrentReviewReplies}
              showSnackbar={showSnackbar}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

export function timeAgo(isoDate) {
  const date = new Date(isoDate);
  const now = new Date();
  const diffInMs = now - date;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) return 'Today';
  else if (diffInDays === 1) return '1 day';
  else if (diffInDays < 7) return `${diffInDays} days`;
  else if (diffInDays < 30) {
    const weeks = Math.floor(diffInDays / 7);
    return weeks === 1 ? '1 week' : `${weeks} weeks`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return months === 1 ? '1 month' : `${months} months`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return years === 1 ? '1 year' : `${years} years`;
  }
}
