import profileIcon from '../icons/profileIcon.svg';
import myListingsIcon from '../icons/myListingsIcon.svg';
import watchlistIcon from '../icons/watchlistIcon.svg';
import settingsIcon from '../icons/settingsIcon.svg';
import createAuctionIcon from '../icons/createAuctionIcon.svg';
import marketplaceIcon from '../icons/marketplaceIcon.svg';
import ticketIcon from '../icons/ticketIcon.svg';
import messagesIcon from '../icons/messagesIcon.svg';
import profileIconDM from '../icons/profileIconDM.svg';
import myListingsIconDM from '../icons/myListingsIconDM.svg';
import watchlistIconDM from '../icons/watchlistIconDM.svg';
import settingsIconDM from '../icons/settingsIconDM.svg';
import createAuctionIconDM from '../icons/createAuctionIconDM.svg';
import marketplaceIconDM from '../icons/marketplaceIconDM.svg';
import ticketIconDM from '../icons/ticketIconDM.svg';
import messagesIconDM from '../icons/messagesIconDM.svg';

export const menuItems = [
  { to: '/profile/0', label: 'Profile', icon: profileIcon, iconDM: profileIconDM },
  {
    to: '/create-product/0',
    label: 'Create Raffle',
    icon: ticketIcon,
    iconDM: ticketIconDM,
  },
  {
    to: '/create-product/1',
    label: 'Create Auction',
    icon: createAuctionIcon,
    iconDM: createAuctionIconDM,
  },
  {
    to: '/create-product/2',
    label: 'Create Marketplace',
    icon: marketplaceIcon,
    iconDM: marketplaceIconDM,
  },
  {
    to: '/profile/1',
    label: 'My Listings',
    icon: myListingsIcon,
    iconDM: myListingsIconDM,
  },
  { to: '/chat', label: 'Messages', icon: messagesIcon, iconDM: messagesIconDM, additionally: true },
  { to: '/profile/3', label: 'Watchlist', icon: watchlistIcon, iconDM: watchlistIconDM },
  { to: '/profile/7', label: 'Settings', icon: settingsIcon, iconDM: settingsIconDM },
];
