import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  Divider,
  Card,
  CardMedia,
  Skeleton,
  Box,
  CircularProgress,
  Avatar,
  Rating,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import axios from '../../config/axiosConfig';
import { baseURLImage } from '../../utils/constants.js';
import { CustomButton, CustomInputs } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import { CustomSkeleton } from './StyledSkeletonComponent.jsx';
import PaymentForm from '../forms/payment/PaymentForm.jsx';
import SwiperComponent from '../../utils/ui/SwiperComponent.jsx';
import InputWithCurrency from '../../utils/ui/InputWithCurrency.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import AddressFields from '../../utils/ui/AddressFieldsComponent.jsx';
import { isEqualObjectsExceptIds } from '../../utils/functions/isEqualObjects.js';
import { selectUserPrepopulateAddress } from '../../store/features/userPrepopulateAddress';
import { trimObjectValues } from '../../utils/functions/trimObjectValues.js';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import { formatDateTime } from '../../utils/functions/dateTimeFormatter';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import RenderPrice from './RenderProductPrice.jsx';
import BidsTable from './BidTableComponent.jsx';
import CardComponent from './CardTicketComponent.jsx';
import ProductAuctionRaffleDialogTexts from './ProductAuctionRaffleDialogTexts.js';
import WinnerUserCard from './WinnerObserverCardComponents.jsx';
import { setUser, selectUser } from '../../store/features/userSlice';
import { setPaymentsType, selectPaymentsType } from '../../store/features/toggleFormsSlice';
import UnfulfilledItemForm from './UnfulfilledItemForm.jsx';
import CustomsDeclarationForm from './CustomsDeclarationForm.jsx';
import RaffleTicketsProgressBar from './RaffleTicketsProgressBar';
import RaffleTimer from './RaffleTimer.jsx';

import locationIcon from '../../assets/icons/locationIcon.svg';
import watchTimeIcon from '../../assets/icons/watchTimeIcon.svg';
import watchlistIcon from '../../assets/icons/watchlistIcon.svg';
import ticketIcon from '../../assets/icons/ticketIcon.svg';
import congratulationsIcon from '../../assets/icons/congratulationsIcon.svg';
import messagesIcon from '../../assets/icons/messagesIcon.svg';
import reservePriceIcon from '../../assets/icons/myListingsIcon.svg';
import verificationIcon from '../../assets/icons/verificationIcon.svg';
import locationIconDM from '../../assets/icons/locationIconDM.svg';
import watchTimeIconDM from '../../assets/icons/watchTimeIconDM.svg';
import watchlistIconDM from '../../assets/icons/watchlistIconDM.svg';
import ticketIconDM from '../../assets/icons/ticketIconDM.svg';
import messagesIconDM from '../../assets/icons/messagesIconDM.svg';
import reservePriceIconDM from '../../assets/icons/myListingsIconDM.svg';

function ProductComponent() {
  const { hash } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isSgMaxScreen, isSdScreen, isSgScreen, isMdScreen, isLgScreen } = useMediaQueries();

  const user = useSelector(selectUser);
  const paymentsType = useSelector(selectPaymentsType);
  const [productData, setProductData] = useState({});
  const [userStar, setUserStar] = useState(0);
  const [openPaymentForm, setOpenPaymentForm] = useState(
    paymentsType.isPaymentOpened && user ? paymentsType.isPaymentOpened : false
  );
  const [paymentType, setPaymentType] = useState(paymentsType.paymentType ? paymentsType.paymentType : '');
  const [loadingData, setLoadingData] = useState(true);
  const [loadingComfirmEndAuctionOrRaffleBnt, setLoadingComfirmEndAuctionOrRaffleBnt] = useState(false);
  const [loadingCancelActionOrRaffleBtn, setLoadingCancelActionOrRaffleBtn] = useState(false);
  const [loadingOpenDisputeBtn, setLoadingOpenDisputeBtn] = useState(false);
  const [isRaffle, setIsRaffle] = useState(false);
  const [isAuction, setIsAuction] = useState(false);
  const [approxRate, setApproxRate] = useState({});
  const [loadingApproxRate, setLoadingApproxRate] = useState(false);
  const userPrepopulateAddress = useSelector(selectUserPrepopulateAddress);
  const userRef = useRef(user);
  const [isOpenAddressForm, setIsOpenAddressForm] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    country: user?.billing_address?.country,
    city: user?.billing_address?.city,
    street: user?.billing_address?.street,
    state: user?.billing_address?.state,
    postalCode: user?.billing_address?.postalCode,
  });
  const [shippingAddress, setShippingAddress] = useState({
    country: user?.shipping_address?.country,
    city: user?.shipping_address?.city,
    street: user?.shipping_address?.street,
    state: user?.shipping_address?.state,
    postalCode: user?.shipping_address?.postalCode,
  });
  const [billingErrors, setBillingErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });
  const [shippingErrors, setShippingErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });
  const [selectSameAddress, setSelectSameAddress] = useState(
    isEqualObjectsExceptIds(user?.shipping_address, user?.billing_address)
  );
  const selectSameAddressRef = useRef(selectSameAddress);
  const [countriesList, setCountriesList] = useState([null]);
  const [loadingAddressUpdBtn, setLoadingAddressUpdBtn] = useState(false);

  const [disputeMessage, setDisputeMessage] = useState('');
  const [disputeMessageError, setDisputeMessageError] = useState('');
  const [buyItemOrNowOrder, setBuyItemOrNowOrder] = useState('');
  const [winnerNumberValue, setWinnerNumberValue] = useState('');
  const [winnerUserNumber, setWinnerUserNumber] = useState('');

  const [openSuccessForm, setOpenSuccessForm] = useState(false);
  const [openEndRaffleForm, setOpenEndRaffleForm] = useState(false);
  const [openEndAuctionForm, setOpenEndAuctionForm] = useState(false);
  const [isConfirmEndRaffle, setIsConfirmEndRaffle] = useState(false);
  const [isConfirmEndAuction, setIsConfirmEndRAuction] = useState(false);
  const [isProductCanceled, setIsProductCanceled] = useState(false);
  const [isOpenDisputeForm, setIsOpenDisputeForm] = useState(false);
  const [recentlyViewedProductData, setRecentlyViewedProductData] = useState(Array(2).fill(null));
  const [isLoadingRecentlyViewedData, setIsLoadingRecentlyViewedData] = useState(true);
  const [productEditable, setProductEditable] = useState(false);

  const [isShowFulfilledItem, setIsShowFulfilledItem] = useState(false);
  const [isFulfilmentForm, setIsFulfilmentForm] = useState(false);

  const [isShowCustomsDeclaration, setIsShowCustomsDeclaration] = useState(false);

  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const handleClickBuy = (paymentType) => {
    dispatch(setPaymentsType({ isPaymentOpened: true, paymentType }));
    if (user.role) {
      setPaymentType(paymentType);
      setOpenPaymentForm(true);
    } else {
      dispatch(setPaymentsType({ isPaymentOpened: false, paymentType }));
      navigate('/login');
    }
  };

  const handleClosePaymentForm = () => {
    setOpenPaymentForm(false);
    dispatch(setPaymentsType({ isPaymentOpened: false, paymentType: null }));
  };

  const handleCancelAuctionOrRaffle = () => {
    setLoadingCancelActionOrRaffleBtn(true);
    axios
      .put('product/' + productData?._id, {
        status: 'canceled',
      })
      .then(() => {
        showSnackbar(null, 'Product canceled', 'success');
        fetchData();
        setIsProductCanceled(false);
        setLoadingCancelActionOrRaffleBtn(false);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleComfirmEndAuctionOrRaffle = () => {
    setLoadingComfirmEndAuctionOrRaffleBnt(true);
    axios
      .post(`product/${productData?._id}/finish`)
      .then((response) => {
        setWinnerNumberValue(isRaffle ? response?.data?.ticket : response?.data?.bid);
        setWinnerUserNumber(response?.data?.user);
        fetchData();
        isRaffle ? setIsConfirmEndRaffle(true) : setIsConfirmEndRAuction(true);
        setLoadingComfirmEndAuctionOrRaffleBnt(false);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleAddToWatchlist = () => {
    axios
      .post('watch', {
        product: productData?._id,
      })
      .then(() => {
        showSnackbar(null, 'Successfully added to Watchlist!', 'success');
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleOpenDispute = () => {
    if (!disputeMessage) {
      setDisputeMessageError('Field is required');
      return;
    }
    setLoadingOpenDisputeBtn(true);
    const disputeSide = productData?.user?._id === user?._id ? 'seller' : 'winner';
    axios
      .put(`order/${customer._id}/dispute`, {
        message: disputeMessage,
        side: disputeSide,
      })
      .then(() => {
        showSnackbar(null, 'Dispute opened!', 'success');
        fetchData();
        setIsOpenDisputeForm(false);
        setLoadingOpenDisputeBtn(false);
      })
      .catch((error) => {
        showSnackbar(error);
      });
  };

  const handleCreateChat = () => {
    axios
      .post(`chat`, {
        users: [user?._id, productData?.user?._id],
      })
      .then(() => {
        showSnackbar(null, 'Create chat', 'success');
      })
      .catch((error) => {
        showSnackbar(null, error, 'info');
      })
      .finally(() => {
        navigate('/chat');
      });
  };

  const fetchUserStarData = useCallback(async () => {
    if (productData?.user?._id) {
      const { data } = await axios.get(`review/owner/${productData?.user?._id}/average-star`);
      setUserStar(data.averageStar);
    }
  }, [productData?.user?._id]);

  const fetchProductEditableData = useCallback(async () => {
    const { data } = await axios.get(`product/${hash}/can-edit`);
    setProductEditable(data.canEdit);
  }, [hash]);

  const fetchRecentlyViewedData = useCallback(() => {
    setIsLoadingRecentlyViewedData(true);
    axios
      .get(`product/view/product?viewCurrency=${user.currency}`)
      .then((response) => {
        const recentlyViewedProductData = response.data
          .map((item) => item?.product)
          .filter((item) => item?.productInfo?.kind === 'Raffle');
        setRecentlyViewedProductData(recentlyViewedProductData);
        setIsLoadingRecentlyViewedData(false);
      })
      .catch((error) => {
        showSnackbarRef.current(error);
      });
  }, [user.currency]);

  const handleAddressUpdForm = async () => {
    setLoadingApproxRate(true);
    try {
      const countriesListResponse = await axios.get('country');
      setCountriesList(countriesListResponse.data);

      const isSameCountry = userPrepopulateAddress?.country === userRef.current?.country?.iso;
      const isUserCountry = userRef.current?.country;
      if (!userRef.current.billing_address) {
        setBillingAddress((prevBillingAddress) => ({
          ...prevBillingAddress,
          country: isUserCountry ? userRef.current.country.iso : userPrepopulateAddress?.country,
          city: !isUserCountry || isSameCountry ? userPrepopulateAddress?.city : '',
          postalCode: !isUserCountry || isSameCountry ? userPrepopulateAddress?.postalCode : '',
        }));
        if (!selectSameAddressRef.current) {
          setShippingAddress((prevShippingAddress) => ({
            ...prevShippingAddress,
            country: isUserCountry ? userRef.current.country.iso : userPrepopulateAddress?.country,
            city: !isUserCountry || isSameCountry ? userPrepopulateAddress?.city : '',
            postalCode: !isUserCountry || isSameCountry ? userPrepopulateAddress?.postalCode : '',
          }));
        }
      }
    } catch (error) {
      showSnackbarRef.current(error);
    } finally {
      setLoadingApproxRate(false);
    }
    setIsOpenAddressForm(true);
  };

  const fetchData = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);
    const order = searchParams.get('order');
    const orderType = searchParams.get('type');
    if (hash) {
      setLoadingData(true);
      try {
        const response = await axios.get(`product/${hash}?viewCurrency=${user.currency}`);
        setProductData(response.data);
        setIsRaffle(response?.data?.productInfo?.kind === 'Raffle');
        setIsAuction(response?.data?.productInfo?.kind === 'Auction');
        if (user?.role) {
          if (response?.data?.productInfo?.kind === 'Raffle') fetchRecentlyViewedData();
          await axios.post(`product/view/${response.data?._id}`);
        }
        if (order) {
          setOpenSuccessForm(true);
          setBuyItemOrNowOrder(order);
        }
        setPaymentType(orderType);
        setLoadingData(false);

        // fetch approximate shipping cost
        if (
          userRef?.current?.billing_address?.street &&
          response?.data?.productInfo?.kind !== 'Raffle' &&
          response?.data?.status === 'accepted' &&
          user?._id !== response?.data?.customer?.user?._id &&
          response?.data?.user?._id !== user?._id
        ) {
          setLoadingApproxRate(true);
          const approxRateData = await axios.get(`delivery/approx-ship-cost/${response.data?._id}`);
          setApproxRate(approxRateData.data);
          setLoadingApproxRate(false);
        }
      } catch (error) {
        showSnackbarRef.current(error);
      }
    }
  }, [hash, user.currency, user.role, fetchRecentlyViewedData, location.search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchUserStarData();
  }, [fetchUserStarData]);

  useEffect(() => {
    if (user?.role) fetchProductEditableData();
  }, [fetchProductEditableData, user, location.search]);

  useEffect(() => {
    userRef.current = user;
    setBillingAddress({
      country: user?.billing_address?.country,
      city: user?.billing_address?.city,
      street: user?.billing_address?.street,
      state: user?.billing_address?.state,
      postalCode: user?.billing_address?.postalCode,
    });
    setShippingAddress({
      country: user?.shipping_address?.country,
      city: user?.shipping_address?.city,
      street: user?.shipping_address?.street,
      state: user?.shipping_address?.state,
      postalCode: user?.shipping_address?.postalCode,
    });
  }, [user]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderType = searchParams.get('type');
    setPaymentType(paymentsType.paymentType ? paymentsType.paymentType : orderType);
  }, [paymentType, paymentsType.paymentType, location.search]);

  const {
    productInfo,
    title,
    description,
    currency,
    country,
    end,
    media,
    converter,
    tickets,
    totalBids,
    orderList,
    status,
    customer,
    delivery,
  } = productData;

  const isUnfulfilled = status === 'unfulfilled';
  const isFulfilled = status === 'fulfilled';
  const isPay = status === 'pay';
  const isPaid = status === 'paid';
  const isAccepted = status === 'accepted';
  const isPending = status === 'pending';
  const isDisputed = status === 'disputed';
  const isCanceled = status === 'canceled';
  const isWinner = isUnfulfilled || isFulfilled || isPay || isPaid || isDisputed;
  const isObserver = user?._id !== customer?.user?._id && productData?.user?._id !== user?._id;
  const isObserverAndWinner = isObserver && isWinner;
  const totalSold = productInfo?.total_amount - productInfo?.amount;
  const mediaArray = Array.isArray(media) ? media.map((img) => ({ img })) : [null];

  const acceptedOrders = orderList?.filter(
    (order) =>
      order?.status === 'accepted' ||
      order?.status === 'unfulfilled' ||
      order?.status === 'fulfilled' ||
      order?.status === 'disputed' ||
      order?.status === 'finished' ||
      (isCanceled && order?.status === 'rejected')
  );

  const userTickets = tickets?.find((ticket) => ticket?.user === user?._id);
  const isUserHasBid = !!acceptedOrders?.find((order) => order?.user?._id === user?._id);

  const highestBid = formatNumberWithTwoDecimals(acceptedOrders?.[0]?.converter?.price);

  const acceptedOrderList = orderList?.filter(
    (order, index) =>
      order?.status === 'accepted' ||
      order?.status === 'unfulfilled' ||
      order?.status === 'fulfilled' ||
      order?.status === 'disputed' ||
      order?.status === 'finished' ||
      (order?.status === 'rejected' &&
        order?.converter?.price <= highestBid &&
        orderList?.findIndex((o) => o?.converter?.price === order?.converter?.price) === index)
  );

  const winnerUserTicketNumber = productInfo?.winnerTicket;

  const [loadingConfirmReceivalBtn, setLoadingConfirmReceivalBtn] = useState(false);
  const [isConfirmReceivalForm, setIsConfirmReceivalForm] = useState(false);

  const handleConfirmReceival = () => {
    setLoadingConfirmReceivalBtn(true);
    axios
      .post(`order/${customer?._id}/finish`)
      .then(() => {
        showSnackbar(null, 'Receival confirmed!', 'success');
        setIsConfirmReceivalForm(false);
        fetchData();
        setLoadingConfirmReceivalBtn(false);
      })
      .catch((error) => {
        showSnackbar(error);
      });
  };

  const handleBillingAddressChange = (field, value) => {
    setBillingAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    setBillingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleShippingAddressChange = (field, value) => {
    setShippingAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    setShippingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleAddressUpdate = async () => {
    const fieldIsRequiredMsg = `${t('Field is required')}`;
    let hasError = false;

    const checkFields = (fields, setError) => {
      fields.forEach(({ value, fieldName }) => {
        if (!value || (typeof value === 'string' && !value.trim())) {
          setError((prevErrors) => ({
            ...prevErrors,
            [fieldName]: fieldIsRequiredMsg,
          }));
          hasError = true;
        }
      });
    };

    const billingFields = [
      { value: billingAddress.country, fieldName: 'country' },
      { value: billingAddress.city, fieldName: 'city' },
      { value: billingAddress.street, fieldName: 'street' },
      { value: billingAddress.postalCode, fieldName: 'postalCode' },
    ];
    checkFields(billingFields, setBillingErrors);

    if (!selectSameAddress) {
      const shippingFields = [
        { value: shippingAddress.country, fieldName: 'country' },
        { value: shippingAddress.city, fieldName: 'city' },
        { value: shippingAddress.street, fieldName: 'street' },
        { value: shippingAddress.postalCode, fieldName: 'postalCode' },
      ];
      checkFields(shippingFields, setShippingErrors);
    }
    if (hasError) {
      return;
    }

    setLoadingAddressUpdBtn(true);
    try {
      const newUserAddress = await axios.put('user/me', {
        shipping_address: selectSameAddress ? trimObjectValues(billingAddress) : trimObjectValues(shippingAddress),
        billing_address: trimObjectValues(billingAddress),
      });
      dispatch(setUser(newUserAddress?.data));
      showSnackbar(null, 'Address successfully updated!', 'success');
      setIsOpenAddressForm(false);
      fetchData();
    } catch (error) {
      showSnackbar(error);
    } finally {
      setLoadingAddressUpdBtn(false);
    }
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Box component="div" className="mb-20 px-2">
        <Grid container spacing={2} className="max-[435px]:pt-4  pb-16 max-[435px]:pl-0 min-[435px]:px-4">
          <Grid
            item
            xs={isMdScreen ? 6 : 12}
            style={{ justifyContent: 'center' }}
            className={`${isObserverAndWinner && 'opacity-30'}`}
          >
            <SwiperComponent
              data={mediaArray}
              isOnlyImages={true}
              isLoading={loadingData}
              isThumb={isMdScreen ? true : false}
              slidePerView={1}
            />
            {mediaArray.length === 0 && (
              <>
                <div className="flex justify-center">
                  <Card
                    className="flex items-center justify-center"
                    sx={{
                      borderRadius: '0.5rem',
                      maxHeight: 601,
                    }}
                    style={{
                      minWidth: '90%',
                      margin: '0 5%',
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        sx={{
                          height: isMdScreen ? '35vw' : isSdScreen ? '65vw' : '80vw',
                          width: '100%',
                        }}
                        animation="wave"
                        variant="rectangular"
                      />
                    ) : (
                      <>
                        <CardMedia
                          component="div"
                          sx={{
                            height: isMdScreen ? '35vw' : isSdScreen ? '65vw' : '80vw',
                            maxHeight: 601,
                          }}
                        />
                        {t('No image')}
                      </>
                    )}
                  </Card>
                </div>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={isMdScreen ? 6 : 12}
            sx={{ mt: isMdScreen ? 1 : 4, px: 2, margin: isMdScreen ? null : '0 5%' }}
          >
            {!loadingData && (
              <Box display="flex" alignItems="center" mb={2} ml={0.5}>
                <Avatar
                  src={baseURLImage + productData?.user?.picture}
                  alt="Buyer Avatar"
                  sx={{ width: 50, height: 50, marginRight: 1, cursor: 'pointer' }}
                  onClick={() => navigate(`/user/${productData?.user?._id}/reviews`)}
                />
                <Box>
                  <Typography variant="body1" fontSize="14" fontWeight="500">
                    <Box
                      component="span"
                      sx={{
                        width: 'fit-content',
                        position: 'relative',
                        '&::after': {
                          content: "''",
                          display: 'inline-block',
                          width: '17px',
                          height: '17px',
                          backgroundImage: `${productData?.user?.verification === 'verified' ? `url(${verificationIcon})` : 'none'}`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          position: 'absolute',
                          top: '-9px',
                          right: '-19px',
                        },
                      }}
                    >
                      <Box
                        component="span"
                        sx={{ borderBottom: `2px solid ${isDarkMode ? 'white' : 'black'}`, cursor: 'pointer' }}
                        onClick={() => navigate(`/user/${productData?.user?._id}/reviews`)}
                      >
                        {`${productData?.user?.firstName} ${productData?.user?.lastName}`}
                      </Box>
                    </Box>
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Box component="span" display="flex" alignItems="center" mr={1} mb="-5px" fontSize="14">
                      {+userStar.toFixed(1)}
                    </Box>
                    <Rating
                      value={userStar}
                      readOnly
                      precision={0.5}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      sx={{ marginRight: 2 }}
                      size="small"
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <div className={`${isObserverAndWinner && 'opacity-30'}`}>
              <Typography
                fontSize={24}
                fontWeight={500}
                sx={{
                  wordWrap: 'break-word',
                }}
                mt={1}
              >
                {loadingData ? <CustomSkeleton /> : <>{title}</>}
              </Typography>
              <div className="flex flex-row items-center mt-2">
                {!loadingData && (
                  <img src={isDarkMode ? locationIconDM : locationIcon} alt="Location Icon" style={{ width: '26px' }} />
                )}
                <Typography fontWeight={200} ml={2}>
                  {loadingData ? (
                    <CustomSkeleton
                      sx={{
                        fontSize: '20rem',
                      }}
                    />
                  ) : (
                    <>{country?.name}</>
                  )}
                </Typography>
              </div>
              <Typography
                fontSize={18}
                fontWeight={300}
                sx={{
                  wordWrap: 'break-word',
                }}
                my={3}
              >
                {loadingData ? <CustomSkeleton /> : <>{description}</>}
              </Typography>
            </div>
            {/* timer for auction*/}
            {isAuction && (
              <div className="flex flex-row items-center">
                {!loadingData && (
                  <img src={isDarkMode ? watchTimeIconDM : watchTimeIcon} alt="Timer Icon" style={{ width: '26px' }} />
                )}
                <Typography fontSize={18} fontWeight={200} sx={{ ml: 2 }}>
                  {loadingData ? (
                    <CustomSkeleton
                      width="100%"
                      sx={{
                        fontSize: '80rem',
                        mt: 2,
                      }}
                    />
                  ) : (
                    <>
                      {`${t('Auction')}`}{' '}
                      {isCanceled ? (
                        'cancelled!'
                      ) : isUnfulfilled || isFulfilled || isPay || !formatDateTime(end, true)?.timeToEnd ? (
                        'ended!'
                      ) : (
                        <>
                          {t('ends in ')} {formatDateTime(end, true)?.timeToEnd}
                        </>
                      )}
                    </>
                  )}
                </Typography>
              </div>
            )}

            {!!converter?.reservePrice && productData?.user?._id === user?._id && (
              <div className="flex flex-row items-center mt-2">
                {!loadingData && (
                  <img
                    src={isDarkMode ? reservePriceIconDM : reservePriceIcon}
                    alt="Reserve Price Icon"
                    style={{ width: '26px' }}
                  />
                )}
                <Typography fontSize={18} fontWeight={200} sx={{ ml: 2 }}>
                  {loadingData ? (
                    <CustomSkeleton
                      width="100%"
                      sx={{
                        fontSize: '80rem',
                        mt: 2,
                      }}
                    />
                  ) : (
                    <>{t(`Reserve price: ${converter?.currency?.abbr_name} ${converter?.reservePrice}`)}</>
                  )}
                </Typography>
              </div>
            )}
            {productData?.user?._id !== user?._id ? (
              <>
                <div className={`flex flex-col mt-2 ${isObserverAndWinner && 'opacity-30'}`}>
                  {loadingData ? (
                    <CustomSkeleton width="30%" />
                  ) : (
                    <>
                      <div className="flex items-center mb-2">
                        <img
                          src={isDarkMode ? watchlistIconDM : watchlistIcon}
                          alt="Eyes Icon"
                          style={{ width: '26px' }}
                        />
                        <button style={{ marginLeft: '16px' }} onClick={handleAddToWatchlist} disabled={!user?.role}>
                          <Typography fontSize={18} fontWeight={300} className="underline">
                            {t('Add to watchlist')}
                          </Typography>
                        </button>
                      </div>
                      {user?._id && (
                        <>
                          <div className="flex items-center">
                            <img
                              src={isDarkMode ? messagesIconDM : messagesIcon}
                              alt="Chat Icon"
                              style={{ width: '26px' }}
                            />
                            <button style={{ marginLeft: '16px' }} onClick={handleCreateChat}>
                              <Typography fontSize={18} fontWeight={300} className="underline" width={'100%'}>
                                {t('Contact seller')}
                              </Typography>
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* timer for raffle */}
                {isRaffle && (isAccepted || isPending) && !!end && new Date() < new Date(end) && (
                  <Box component="div" className={`flex ${isObserverAndWinner && 'opacity-30'}`} sx={{ marginTop: 4 }}>
                    <RaffleTimer targetDate={end} />
                  </Box>
                )}

                <div className={`mt-8 ${isObserverAndWinner && 'opacity-30'}`}>
                  {loadingData ? (
                    <CustomSkeleton />
                  ) : (
                    <>
                      <div className="flex">
                        <Typography fontSize={19} fontWeight={300}>
                          {isAuction ? (
                            <>
                              {t('Last highest bid')}:
                              <span className="font-medium ml-2">
                                {acceptedOrderList?.length > 0 ? (
                                  <>{`${converter?.currency?.abbr_name} ${highestBid}`}</>
                                ) : (
                                  'None'
                                )}
                              </span>
                            </>
                          ) : (
                            `Price per ${isRaffle ? 'Ticket' : 'Item'}${isWinner ? ' was' : ''}:`
                          )}
                        </Typography>
                        {!isAuction && (
                          <RenderPrice
                            price={productInfo?.price}
                            currency={currency}
                            defaultCurrency={user.currency}
                            converter={converter}
                            isRaffle={!isAuction}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {loadingData ? (
                    <CustomSkeleton />
                  ) : (
                    <>
                      <Typography component="div" fontSize={19} fontWeight={300} className="flex">
                        {isAuction
                          ? productInfo?.buy_now && `${t('Buy it now')}:`
                          : `${t(`${isRaffle ? 'Tickets Sold' : `Items ${isWinner ? (isObserver ? 'Sold' : 'Bought') : 'Available'}`}`)}:`}{' '}
                        <span className={isAuction ? '' : 'ml-2'}>
                          {isAuction ? (
                            <>
                              {productInfo?.buy_now && (
                                <RenderPrice
                                  price={productInfo?.buy_now}
                                  currency={currency}
                                  defaultCurrency={user.currency}
                                  converter={converter}
                                  isRaffle={isRaffle}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {isRaffle
                                ? `${productInfo?.total_amount - productInfo?.amount}/${productInfo?.total_amount}`
                                : productInfo?.items_amount}
                            </>
                          )}
                        </span>
                      </Typography>

                      {isRaffle && (
                        <RaffleTicketsProgressBar
                          amount={productInfo?.amount}
                          totalAmount={productInfo?.total_amount}
                          salesTargetOfTickets={productInfo?.salesTarget}
                        />
                      )}

                      {productInfo?.minimum && acceptedOrderList?.length === 0 && (
                        <>
                          <Typography component="div" fontSize={19} fontWeight={300} className="flex">
                            {t('Minimal bid')}:
                            <span style={{ fontWeight: 500 }}>
                              <RenderPrice
                                price={productInfo?.minimum}
                                currency={currency}
                                defaultCurrency={user.currency}
                                converter={converter}
                                minimal={true}
                              />
                            </span>
                          </Typography>
                        </>
                      )}
                    </>
                  )}

                  {/* approximate shipping */}
                  {!isRaffle &&
                    !isWinner &&
                    isObserver &&
                    (loadingData || loadingApproxRate ? (
                      <CustomSkeleton />
                    ) : !user?.billing_address?.street ? (
                      <span>
                        <Link className="underline" onClick={() => handleAddressUpdForm()}>
                          Click here
                        </Link>{' '}
                        to add your address and check the approximate shipping cost
                      </span>
                    ) : (
                      <>
                        <div className="flex">
                          <Typography fontSize={19} fontWeight={300}>
                            <>
                              {t('Approximate shipping price')}:{isSgMaxScreen && <br />}
                              <span
                                className={`font-medium ml-${isSgMaxScreen ? 0 : 2}`}
                              >{`${approxRate?.currency} ${approxRate?.amount}`}</span>
                            </>
                          </Typography>
                        </div>
                      </>
                    ))}
                </div>

                <div className="flex flex-col items-center md:items-start">
                  {!isWinner && (
                    <>
                      {loadingData ? (
                        <CustomSkeleton sx={{ height: 50, width: 300, mt: 4 }} animation="wave" variant="rounded" />
                      ) : (
                        <>
                          <CustomButton
                            onClick={() => handleClickBuy(isRaffle ? 'buyTicket' : isAuction ? 'bid' : 'buyItem')}
                            variant="contained"
                            sx={{ px: 6, mt: 4 }}
                            disabled={
                              !isAccepted || (isRaffle && productInfo?.amount <= 0) || productInfo?.current_amount <= 0
                            }
                          >
                            <div style={{ flex: 1, color: isDarkMode ? 'white' : null }}>
                              {isAuction ? `${t('bid')}` : `${t(`buy ${isRaffle ? 'ticket' : 'item'}`)}`}
                            </div>
                          </CustomButton>
                          {isAuction && productInfo?.buy_now && (
                            <CustomButton
                              onClick={() => handleClickBuy('buyNow')}
                              variant="contained"
                              sx={{ px: 6, mt: 4 }}
                              disabled={isUserHasBid || !isAccepted || productInfo?.overdue}
                            >
                              {t('buy it now')}
                            </CustomButton>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {isWinner && customer && (
                    <>
                      {user?._id === customer?.user?._id && (
                        <Card
                          className="mt-6 p-6 w-full"
                          style={{
                            borderRadius: '0.75rem',
                            maxWidth: 640,
                          }}
                          sx={{ minHeight: 240 }}
                        >
                          <div className="flex flex-col w-full justify-between items-center">
                            <>
                              <Typography className="flex flex-row" align="center" fontSize={24}>
                                <img
                                  src={congratulationsIcon}
                                  alt="Congratulations Icon"
                                  style={{ width: 30, height: 30 }}
                                />
                                <span className="px-4">
                                  {t(
                                    isRaffle || isAuction
                                      ? 'WINNER WINNER CHICKEN DINNER'
                                      : 'CONGRATS WITH YOUR PURCHASE'
                                  )}
                                </span>
                                <img
                                  src={congratulationsIcon}
                                  alt="Congratulations Icon"
                                  style={{ width: 30, height: 30 }}
                                />
                              </Typography>
                              {(isRaffle || isAuction) && (
                                <div className="mt-4">
                                  <Typography align="center" fontSize={20}>
                                    {isRaffle ? 'Ticket number' : 'Bid'}
                                  </Typography>
                                  <CardComponent
                                    widht={'30vw'}
                                    value={
                                      !isRaffle
                                        ? acceptedOrderList?.[0]?.converter?.currency?.abbr_name + ' ' + highestBid
                                        : winnerUserTicketNumber
                                    }
                                  />
                                </div>
                              )}
                              <div className="mt-4">
                                <Typography align="center" fontSize={20}>
                                  {isRaffle ? t('Ticket Owner') : isAuction ? t('from') : t('Happy buyer')}
                                </Typography>
                                <CardComponent
                                  widht={'30vw'}
                                  value={
                                    <>
                                      <div className="flex flex-row">
                                        <img
                                          src={congratulationsIcon}
                                          alt="Congratulations Icon"
                                          style={{ width: 30, height: 30 }}
                                        />
                                        <span className="px-4">{t('You')}</span>
                                        <img
                                          src={congratulationsIcon}
                                          alt="Congratulations Icon"
                                          style={{ width: 30, height: 30 }}
                                        />
                                      </div>
                                    </>
                                  }
                                />
                              </div>
                              {isFulfilled && (
                                <>
                                  <div className="mt-4">
                                    <Typography align="center" fontSize={20}>
                                      {t(` ${isRaffle ? 'Raffle' : 'Auction'} was shipped:`)}
                                    </Typography>
                                    <div className="mt-4">
                                      <CardComponent widht={'30vw'} value={delivery?.trackingProvider} />
                                    </div>
                                    <div className="mt-2">
                                      <CardComponent widht={'30vw'} value={delivery?.trackingNumber} />
                                    </div>
                                  </div>
                                </>
                              )}
                              {productData?.rates && !productData?.rates?.length && !isRaffle && (
                                <Typography sx={{ marginTop: 6 }} align="center" fontSize={20}>
                                  {t(
                                    'Looks like there are no available options for your shipment. Please contact support using button in the right bottom corner or submit a dispute to involve Support manager in manual delivery.'
                                  )}
                                </Typography>
                              )}
                              <div className={'mt-6 flex flex-row w-full justify-center'}>
                                {productData?.rates && !delivery?.trackingNumber && !isRaffle && (
                                  <CustomButton
                                    disabled={!productData?.rates?.length}
                                    style={{
                                      widht: '90%',
                                      maxWidth: '50%',
                                    }}
                                    onClick={() => {
                                      setIsFulfilmentForm(true);
                                      setIsShowFulfilledItem(true);
                                    }}
                                  >
                                    {t('confirm shipment')}
                                  </CustomButton>
                                )}
                                {isFulfilled && delivery?.trackingUrl && (
                                  <Link
                                    to={delivery?.trackingUrl}
                                    target="_blank"
                                    style={{
                                      widht: '50%',
                                      maxWidth: '50%',
                                    }}
                                  >
                                    <CustomButton
                                      style={{
                                        widht: '90%',
                                        maxWidth: '90%',
                                      }}
                                    >
                                      {t('track shipment')}
                                    </CustomButton>
                                  </Link>
                                )}
                                {isFulfilled && isRaffle && delivery?.customShipping && (
                                  <CustomButton
                                    style={{
                                      widht: '50%',
                                      maxWidth: '50%',
                                    }}
                                    onClick={() => setIsConfirmReceivalForm(true)}
                                  >
                                    {t('confirm receival')}
                                  </CustomButton>
                                )}
                                {(isFulfilled || isUnfulfilled || isPaid || isDisputed) && (
                                  <CustomButton
                                    sx={{ ml: isUnfulfilled || isFulfilled ? 2 : 0 }}
                                    onClick={() => setIsOpenDisputeForm(true)}
                                    disabled={isDisputed || isPaid}
                                    style={{
                                      widht: '50%',
                                      maxWidth: '50%',
                                    }}
                                  >
                                    {t(`${isDisputed ? 'opened' : 'open'} dispute`)}
                                  </CustomButton>
                                )}
                              </div>
                            </>
                          </div>
                        </Card>
                      )}
                    </>
                  )}
                  {isObserverAndWinner && customer && !isAuction && (
                    <div className="mt-12 w-full flex justify-center md:justify-start">
                      <WinnerUserCard
                        {...{
                          customer,
                          kind: productData.productInfo.kind,
                          acceptedOrderList,
                          highestBid,
                          winnerUserTicketNumber,
                        }}
                      />
                    </div>
                  )}
                </div>
                {userTickets && isWinner && !customer && (
                  <>
                    <Card
                      className="mt-4 py-2 px-6 flex flex-col justify-evenly items-center xl:px-10"
                      style={{
                        borderRadius: '0.75rem',
                      }}
                      sx={{ minHeight: 240 }}
                    >
                      <Typography fontSize={20}>{t('Raffle ended without a winner')}</Typography>
                    </Card>
                  </>
                )}
                {isRaffle && userTickets && (
                  <>
                    <Card
                      className="flex flex-col justify-center items-center w-full"
                      sx={{ mt: 6, py: 2 }}
                      style={{
                        borderRadius: '0.75rem',
                        maxWidth: 640,
                      }}
                    >
                      <Typography fontSize={20} className="flex flex-row items-center">
                        Your Ticket(s)
                        <img
                          src={isDarkMode ? ticketIconDM : ticketIcon}
                          alt="Ticket Icon"
                          style={{ width: 30, height: 30, marginLeft: '10px', marginBottom: '10px' }}
                        />
                      </Typography>
                      <Box
                        sx={{
                          maxHeight: 300,
                          overflowY: 'auto',
                          width: '98%',
                          px: isLgScreen ? '12%' : isMdScreen ? '5%' : isSgScreen ? '8%' : '2%',
                          pb: 2,
                          ...(isMdScreen && {
                            '&::-webkit-scrollbar': {
                              width: 5,
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#888',
                              borderRadius: 10,
                            },
                            '&::-webkit-scrollbar-track': {
                              backgroundColor: '#f2f2f2',
                            },
                          }),
                        }}
                      >
                        {userTickets?.tickets.map((ticket, index) => (
                          <Card
                            key={index}
                            className="flex justify-center items-center"
                            sx={{
                              mt: 3,
                              height: 60,
                              bgcolor: '#D9D9D91A',
                              width: '100%',
                            }}
                            style={{
                              borderRadius: '0.75rem',
                            }}
                          >
                            <Typography fontSize={isSgScreen ? 22 : 20}>{ticket}</Typography>
                          </Card>
                        ))}
                      </Box>
                    </Card>
                  </>
                )}
              </>
            ) : (
              <>
                {loadingData ? (
                  <CustomSkeleton
                    sx={{ height: !isRaffle ? 160 : 240, width: '100%', mt: 4 }}
                    animation="wave"
                    variant="rounded"
                    spaceBetween={70}
                  />
                ) : (
                  <>
                    {isAuction ? (
                      <>
                        <Card
                          className="mt-4 py-6 px-6 items-center"
                          style={{
                            borderRadius: '0.75rem',
                          }}
                          sx={{
                            minHeight: isAccepted && acceptedOrders?.length !== 0 ? 220 : 160,
                          }}
                        >
                          <div
                            className="pt-4"
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gap: '1rem',
                            }}
                          >
                            <Grid container spacing={2} justify="center">
                              <Grid item xs={12}>
                                <Typography fontWeight={300} fontSize={20}>
                                  {t('Highest bid')}
                                </Typography>
                                <Typography fontWeight={500} fontSize={24} mt={2}>
                                  {converter?.currency?.abbr_name} {acceptedOrderList?.length > 0 ? highestBid : 'None'}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} justify="center">
                              <Grid item xs={12}>
                                <Typography fontWeight={300} fontSize={20}>
                                  {t('Total bids')}
                                </Typography>
                                <Typography fontWeight={500} fontSize={24} mt={2}>
                                  {totalBids}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                          {isAccepted && acceptedOrders?.length !== 0 && (
                            <Typography align="center" mt={4}>
                              {t('Go to the chart below to accept bid')}
                            </Typography>
                          )}
                        </Card>
                      </>
                    ) : (
                      <>
                        {/* timer for raffle */}
                        {isRaffle && (isAccepted || isPending) && !!end && new Date() < new Date(end) && (
                          <Box
                            component="div"
                            className={`flex ${isObserverAndWinner && 'opacity-30'}`}
                            sx={{ marginTop: 4 }}
                          >
                            <RaffleTimer targetDate={end} />
                          </Box>
                        )}

                        {isRaffle && (
                          <RaffleTicketsProgressBar
                            amount={productInfo?.amount}
                            totalAmount={productInfo?.total_amount}
                            salesTargetOfTickets={productInfo?.salesTarget}
                          />
                        )}

                        <Card
                          className="mt-4 px-6 items-center"
                          style={{
                            borderRadius: '0.75rem',
                            display: 'grid',
                            gridTemplateColumns: !isRaffle ? 'none' : '1fr 1fr',
                            gap: '1rem',
                          }}
                          sx={{ minHeight: 240 }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} style={{ textAlign: isRaffle ? null : 'center' }}>
                              <Typography fontWeight={300} fontSize={20}>
                                {t(`Price per ${isRaffle ? 'ticket' : 'item'}`)}
                              </Typography>
                              <Typography fontWeight={500} fontSize={24}>
                                {converter?.currency?.abbr_name} {formatNumberWithTwoDecimals(converter?.price)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2} style={{ textAlign: isRaffle ? null : 'center' }}>
                              <Typography fontWeight={300} fontSize={20}>
                                {!isRaffle && !isAuction && isWinner
                                  ? t('Items sold')
                                  : t(`Available ${isRaffle ? 'ticket' : 'item'}s`)}
                              </Typography>
                              <Typography fontWeight={500} fontSize={24}>
                                {isRaffle && (
                                  <>
                                    {productInfo?.amount} <span className="font-light">{t('out of')}</span>{' '}
                                  </>
                                )}
                                {productInfo?.[`${isRaffle ? 'total' : 'items'}_amount`]}
                              </Typography>
                            </Grid>
                          </Grid>
                          {isRaffle && (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography fontWeight={300} fontSize={20}>
                                  {t('Total tickets sold')}
                                </Typography>
                                <Typography fontWeight={500} fontSize={24}>
                                  {totalSold}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} mt={2}>
                                <Typography fontWeight={300} fontSize={20}>
                                  {t('Your earnings')}
                                </Typography>
                                <Typography fontWeight={500} fontSize={24}>
                                  {converter?.currency?.abbr_name}{' '}
                                  {formatNumberWithTwoDecimals(converter?.price * totalSold)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Card>
                      </>
                    )}
                    {isWinner && (
                      <>
                        <Card
                          className="mt-4 py-2 px-6 flex flex-col justify-evenly items-center xl:px-10"
                          style={{
                            borderRadius: '0.75rem',
                          }}
                          sx={{ minHeight: 240 }}
                        >
                          {customer ? (
                            <>
                              <div
                                className={`flex w-full justify-${isRaffle || isAuction ? 'between' : 'center'} items-start`}
                              >
                                {(isAuction || isRaffle) && (
                                  <div className="flex flex-col justify-between  w-1/2">
                                    <Typography fontWeight={300} fontSize={20}>
                                      {isRaffle ? 'Winner Ticket' : 'Bid accepted'}
                                    </Typography>
                                    <Typography
                                      fontWeight={500}
                                      fontSize={24}
                                      style={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'break-spaces',
                                      }}
                                    >
                                      {isRaffle ? (
                                        <>{winnerUserTicketNumber}</>
                                      ) : (
                                        <>
                                          {converter?.currency?.abbr_name} {customer?.price}
                                        </>
                                      )}
                                    </Typography>
                                  </div>
                                )}
                                <div
                                  className={`flex flex-col justify-between w-1/2 ml-2 ${isRaffle || isAuction ? '' : 'text-center'}`}
                                >
                                  <Typography fontWeight={300} fontSize={20}>
                                    {isRaffle ? 'Ticket Owner' : isAuction ? 'From' : 'Buyer'}
                                  </Typography>
                                  <Typography
                                    fontWeight={500}
                                    fontSize={24}
                                    style={{
                                      wordWrap: 'break-word',
                                      whiteSpace: 'break-spaces',
                                    }}
                                  >
                                    {isRaffle ? (
                                      <>
                                        {customer?.user?.firstName} {customer?.user?.lastName}
                                      </>
                                    ) : (
                                      <>
                                        {customer?.user?.firstName} {customer?.user?.lastName}
                                      </>
                                    )}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex flex-row justify-center w-full">
                                {productData?.internationalShipment && !productData?.rates && isUnfulfilled && (
                                  <CustomButton
                                    style={{ widht: '90%', maxWidth: '50%' }}
                                    onClick={() => setIsShowCustomsDeclaration(true)}
                                  >
                                    {t('add customs declaration')}
                                  </CustomButton>
                                )}
                                {productData?.rates && !productData?.rates?.length && isUnfulfilled && isRaffle && (
                                  <Typography sx={{ marginTop: 6 }} align="center" fontSize={20}>
                                    {t(
                                      'Looks like there are no available options for your shipment. Please contact support using button in the right bottom corner or submit a dispute to involve Support manager in manual delivery.'
                                    )}
                                  </Typography>
                                )}
                                {productData?.rates && !delivery?.trackingNumber && isUnfulfilled && isRaffle && (
                                  <CustomButton
                                    disabled={!productData?.rates?.length}
                                    style={{
                                      widht: '90%',
                                      maxWidth: '50%',
                                    }}
                                    onClick={() => {
                                      setIsFulfilmentForm(true);
                                      setIsShowFulfilledItem(true);
                                    }}
                                  >
                                    {t('confirm shipment')}
                                  </CustomButton>
                                )}
                                {(isFulfilled || isUnfulfilled || isPaid || isDisputed) && (
                                  <CustomButton
                                    sx={{ ml: isUnfulfilled ? 2 : 0 }}
                                    style={{
                                      widht: isUnfulfilled ? '90%' : '100%',
                                      maxWidth: isUnfulfilled ? '50%' : '70%',
                                    }}
                                    onClick={() => setIsOpenDisputeForm(true)}
                                    disabled={isDisputed || isPaid}
                                  >
                                    {t(`${isDisputed ? 'opened' : 'open'} dispute`)}
                                  </CustomButton>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <Typography fontSize={20}>{t('Raffle ended without a winner')}</Typography>
                            </>
                          )}
                        </Card>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {productData?.user?._id === user?._id && (
              <>
                {!isUnfulfilled && !isFulfilled && !isDisputed && !isPay && isRaffle && (
                  <>
                    <div className="flex flex-col items-center">
                      <Typography className="text-center" sx={{ mt: 4 }} fontSize={20}>
                        {t('End the raffle and draw a winner by clicking the button below!')}
                      </Typography>
                      <CustomButton
                        onClick={() => setOpenEndRaffleForm(true)}
                        sx={{ mt: 4 }}
                        disabled={!isAccepted || tickets?.length === 0}
                      >
                        {t('end raffle')}
                      </CustomButton>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <div className="px-4 md:px-6 lg:px-10 xl:px-12">
          {loadingData ? (
            <>
              {isAuction && (
                <CustomSkeleton sx={{ height: 200, width: '100%', mt: 10, px: 2 }} animation="wave" variant="rounded" />
              )}
            </>
          ) : (
            <>
              {isAuction && (
                <div className="mt-4">
                  {isWinner && productData?.user?._id === user?._id && (
                    <Typography className="text-center" mt={4} fontSize={20}>
                      {t(
                        'The Auction has ended, but you can still check out all the bids from your auction in the chart below:'
                      )}
                    </Typography>
                  )}
                  <Grid container spacing={2} className="py-16 max-[435px]:pl-2 min-[435px]:px-4">
                    <Grid item xs={isMdScreen && isObserverAndWinner ? 6 : 12}>
                      <BidsTable productData={productData} user={user} acceptedOrderList={acceptedOrderList} />
                    </Grid>
                    <Grid item xs={isMdScreen && isObserverAndWinner ? 6 : 12}>
                      {isObserverAndWinner && (
                        <WinnerUserCard
                          {...{
                            customer,
                            kind: productData.productInfo.kind,
                            acceptedOrderList,
                            highestBid,
                            winnerUserTicketNumber,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
            </>
          )}
          {productData?.user?._id === user?._id && (
            <>
              {productEditable && isPending && (
                <Box className="flex flex-col items-center">
                  <CustomButton onClick={() => navigate(`/product/${productData?._id}/edit`)} sx={{ mt: 4 }}>
                    {t(`edit ${isRaffle ? 'raffle' : isAuction ? 'auction' : 'item'}`)}
                  </CustomButton>
                </Box>
              )}

              {isAccepted && !isUnfulfilled && (
                <>
                  <div className="flex flex-col items-center">
                    {isAuction && (
                      <>
                        {loadingData ? (
                          <CustomSkeleton sx={{ height: 50, width: 300, mt: 4 }} animation="wave" variant="rounded" />
                        ) : (
                          <>
                            <CustomButton
                              onClick={() => setOpenEndAuctionForm(true)}
                              variant="contained"
                              sx={{ width: 180, mt: 4 }}
                              disabled={!isAccepted || acceptedOrders?.length === 0}
                            >
                              <div style={{ flex: 1 }}>{t('accept the highest bid')}</div>
                            </CustomButton>
                          </>
                        )}
                      </>
                    )}

                    {loadingData ? (
                      <CustomSkeleton sx={{ height: 50, width: 300, mt: 4 }} animation="wave" variant="rounded" />
                    ) : (
                      <>
                        {productEditable && (
                          <CustomButton onClick={() => navigate(`/product/${productData?._id}/edit`)} sx={{ mt: 4 }}>
                            {t(`edit ${isRaffle ? 'raffle' : isAuction ? 'auction' : 'quantity'}`)}
                          </CustomButton>
                        )}

                        <Typography
                          className="text-center"
                          sx={{ mt: 4 }}
                          fontSize={20}
                          width={isMdScreen ? '70%' : '100%'}
                        >
                          {loadingData ? (
                            <CustomSkeleton style={{ width: '100%' }} />
                          ) : (
                            <>
                              {t(
                                `Are you not satisfied with the ${
                                  isAuction ? 'bids' : 'result'
                                } or do you no longer want to ${isRaffle || isAuction ? 'run' : 'sell'} this ${isRaffle ? 'raffle' : isAuction ? 'auction' : 'item'}? End your ${
                                  isRaffle ? 'raffle' : isAuction ? 'auction' : 'sale'
                                } by clicking the button below.`
                              )}
                            </>
                          )}
                        </Typography>
                        <CustomButton onClick={() => setIsProductCanceled(true)} sx={{ mt: 4 }}>
                          {t(`cancel ${isRaffle ? 'raffle' : isAuction ? 'auction' : 'item'}`)}
                        </CustomButton>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {isRaffle && user?.role && recentlyViewedProductData !== null && productData?.user?._id !== user?._id && (
          <>
            <div className="mt-8 px-4">
              <Divider />
              <Typography className="text-center" fontSize={24} marginTop={4} marginBottom={4}>
                {t('Recently viewed Raffles')}
              </Typography>
              <SwiperComponent
                data={recentlyViewedProductData}
                isLoading={isLoadingRecentlyViewedData}
                leftCenteredSlides={true}
                sliderWidth={isSgMaxScreen ? 250 : 300}
                sliderOffset={8}
              />
            </div>
          </>
        )}
      </Box>
      <div>
        <PaymentForm
          open={openPaymentForm}
          onClose={handleClosePaymentForm}
          productData={productData}
          paymentType={paymentType}
          order={buyItemOrNowOrder}
          isOpenSuccessForm={openSuccessForm}
          isCloseForm={() => setOpenSuccessForm(false)}
          highestBid={highestBid}
        />
      </div>
      {(openEndRaffleForm || openEndAuctionForm) && (
        <>
          <FormTemplate
            open={openEndRaffleForm || openEndAuctionForm}
            onClose={() => {
              if (isRaffle) {
                setOpenEndRaffleForm(false);
                setIsConfirmEndRaffle(false);
              } else {
                setOpenEndAuctionForm(false);
                setIsConfirmEndRAuction(false);
              }
            }}
            title={
              <ProductAuctionRaffleDialogTexts
                {...{
                  isRaffle,
                  isPay,
                  isConfirmEndRaffle,
                  isConfirmEndAuction,
                  t,
                }}
              />
            }
            dialogContent={
              isConfirmEndRaffle || isConfirmEndAuction ? (
                <>
                  {loadingComfirmEndAuctionOrRaffleBnt ? (
                    <>
                      <div className="my-20 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                      </div>
                    </>
                  ) : (
                    <>
                      {isPay && !customer ? (
                        <>
                          <Typography fontSize={20} className="text-center">
                            {t(
                              'There is no winner due to no one of participants have answered the skill question correctly.You still get the payment that will be available soon.'
                            )}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className="text-center" fontWeight={300} fontSize={22}>
                            {isRaffle ? 'The winner of your raffle is:' : 'You have accepted the bid on:'}
                          </Typography>
                          <div className="mt-4">
                            <Typography className="text-center" fontWeight={300} fontSize={20}>
                              {isRaffle ? 'Ticket number' : 'Bid'}
                            </Typography>
                            <CardComponent
                              value={
                                !isRaffle
                                  ? acceptedOrderList?.[0]?.converter?.currency?.abbr_name + ' ' + winnerNumberValue
                                  : winnerNumberValue
                              }
                            />
                          </div>
                          <div className="mt-4">
                            <Typography className="text-center" fontWeight={300} fontSize={20}>
                              {isRaffle ? 'Ticket Owner' : 'from'}
                            </Typography>
                            <CardComponent value={`${winnerUserNumber.firstName} ${winnerUserNumber.lastName}`} />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <Typography className="text-center" mt={4} fontWeight={300} fontSize={20}>
                    {t(
                      `You can go to your profile page to view the fulfilment and your next payout from this ${
                        isRaffle ? 'raffle' : 'auction'
                      }.`
                    )}
                  </Typography>
                </>
              ) : (
                !isRaffle &&
                !isConfirmEndAuction && (
                  <>
                    <InputWithCurrency
                      sx={{ pt: 0.1 }}
                      value={highestBid}
                      currencyAbrName={acceptedOrderList?.[0]?.converter?.currency?.abbr_name}
                      isReadOnly={true}
                    />
                    <Typography mt={2} className="text-center" fontSize={24}>
                      {t('From')}:
                    </Typography>
                    <CardComponent
                      value={`${acceptedOrderList?.[0]?.user?.firstName} ${acceptedOrderList?.[0]?.user?.lastName}`}
                    />
                  </>
                )
              )
            }
            dialogActions={
              <>
                {isConfirmEndRaffle || isConfirmEndAuction ? (
                  <>
                    <CustomButton sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/profile/0')}>
                      {t('go to profile page')}
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton
                    onClick={handleComfirmEndAuctionOrRaffle}
                    variant="contained"
                    disabled={!isAccepted || loadingComfirmEndAuctionOrRaffleBnt}
                  >
                    <LoadingIndicator
                      isLoading={loadingComfirmEndAuctionOrRaffleBnt}
                      style={{ color: isDarkMode ? 'white' : null }}
                      value={
                        <>
                          <div style={{ flex: 1 }}>{isRaffle ? 'end raffle' : 'accept bid'}</div>
                        </>
                      }
                    />
                  </CustomButton>
                )}
              </>
            }
          />
        </>
      )}
      {isProductCanceled && (
        <FormTemplate
          open={true}
          onClose={() => setIsProductCanceled(false)}
          title={`You are about to cancel this ${
            isRaffle ? 'raffle' : isAuction ? 'auction' : 'item'
          }.${isRaffle || isAuction ? ' We will return money to all participants, click the button below to proceed.' : ''}`}
          dialogActions={
            <>
              <CustomButton
                onClick={handleCancelAuctionOrRaffle}
                variant="contained"
                disabled={loadingCancelActionOrRaffleBtn}
              >
                <LoadingIndicator
                  isLoading={loadingCancelActionOrRaffleBtn}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={t(`cancel ${isRaffle ? 'raffle' : isAuction ? 'auction' : 'item'}`)}
                />
              </CustomButton>
            </>
          }
        />
      )}
      {isConfirmReceivalForm && (
        <FormTemplate
          open={isConfirmReceivalForm}
          onClose={() => {
            setIsConfirmReceivalForm(false);
          }}
          title={t('Confirm the item receival')}
          subtitle={t('You are about to confirm that item was received and approve the payment for seller.')}
          dialogActions={
            <>
              <CustomButton
                style={{
                  widht: '90%',
                  maxWidth: '50%',
                }}
                disabled={loadingConfirmReceivalBtn}
                onClick={handleConfirmReceival}
              >
                <LoadingIndicator
                  isLoading={loadingConfirmReceivalBtn}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={t('confirm!')}
                />
              </CustomButton>
            </>
          }
        />
      )}
      {isOpenDisputeForm && (
        <FormTemplate
          open={isOpenDisputeForm}
          onClose={() => {
            setIsOpenDisputeForm(false);
            setDisputeMessageError('');
          }}
          title="Dispute message"
          dialogContent={
            <>
              <CustomInputs
                value={disputeMessage}
                placeholder={t('Dispute message')}
                name={t('Dispute message')}
                variant="outlined"
                required
                type="text"
                sx={{ mt: 1 }}
                onChange={(e) => {
                  setDisputeMessage(e.target.value);
                  setDisputeMessageError('');
                }}
                helperText={disputeMessageError ? disputeMessageError : ' '}
                error={!!disputeMessageError}
              />
            </>
          }
          dialogActions={
            <>
              <CustomButton onClick={handleOpenDispute} variant="contained" disabled={loadingOpenDisputeBtn}>
                <LoadingIndicator
                  isLoading={loadingOpenDisputeBtn}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={t('Send message')}
                />
              </CustomButton>
            </>
          }
        />
      )}
      {isShowFulfilledItem && (
        <UnfulfilledItemForm
          onClose={() => {
            setIsShowFulfilledItem(false);
            setIsFulfilmentForm(false);
          }}
          productItem={productData}
          orderItem={productData?.order}
          onSuccess={() => {
            showSnackbar(null, 'Email sent! Don’t forget to check the spam folder!', 'success');
            fetchData();
            setIsShowFulfilledItem(false);
            setIsFulfilmentForm(false);
          }}
          isFulfilment={isFulfilmentForm}
        />
      )}
      {isShowCustomsDeclaration && (
        <CustomsDeclarationForm
          onClose={() => setIsShowCustomsDeclaration(false)}
          productItem={productData}
          onSuccess={() => {
            showSnackbar(null, 'Success', 'success');
            fetchData();
            setIsShowCustomsDeclaration(false);
          }}
        />
      )}
      {isOpenAddressForm && (
        <FormTemplate
          open={isOpenAddressForm}
          onClose={() => setIsOpenAddressForm(false)}
          title="Address update"
          dialogContent={
            <>
              <div className="mt-4">
                <Typography fontSize={18}>{t('Billing Address')}</Typography>
                <AddressFields
                  values={billingAddress}
                  onChange={handleBillingAddressChange}
                  errors={billingErrors}
                  countriesList={countriesList}
                />
                <FormControlLabel
                  label="Shipping address same as Billing address"
                  control={
                    <Checkbox checked={selectSameAddress} onChange={(e) => setSelectSameAddress(e.target.checked)} />
                  }
                />
                {!selectSameAddress && (
                  <>
                    <Typography fontSize={18}>{t('Shipping Address')}</Typography>
                    <AddressFields
                      values={shippingAddress}
                      onChange={handleShippingAddressChange}
                      errors={shippingErrors}
                      countriesList={countriesList}
                    />
                  </>
                )}
              </div>
            </>
          }
          dialogActions={
            <>
              <CustomButton onClick={handleAddressUpdate} variant="contained" disabled={loadingAddressUpdBtn}>
                <LoadingIndicator
                  isLoading={loadingAddressUpdBtn}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={t('Save')}
                />
              </CustomButton>
            </>
          }
        />
      )}
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ProductComponent;
