import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  CardContent,
  Rating,
  Backdrop,
  Divider,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/system';

import axios from '../../config/axiosConfig';
import { baseURLImage } from '../../utils/constants.js';

import locationIcon from '../../assets/icons/locationIcon.svg';
import locationIconDM from '../../assets/icons/locationIconDM.svg';
import verificationIcon from '../../assets/icons/verificationIcon.svg';
import { timeAgo } from './ReviewComponent.jsx';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '800px',
  bgcolor: 'white',
  padding: 3,
  borderRadius: 2,
  boxShadow: '0px 2px 6px 2px  rgba(0, 0, 0, 0.08)',
  border: 'none',
  outline: 'none',
};

const StyledModal = styled(Modal)({
  '&:focus-visible': { outline: 'none', border: 'none' },
  '&.MuiBox-root': { outline: 'none', border: 'none' },
});

const ReplyModelComponent = ({ open, handleClose, review, showSnackbar, setCurrentReviewReplies }) => {
  const [text, setText] = useState('');

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    if (!text) return;

    try {
      await axios.put(`review/${review._id}`, { replays: [text] });
      setCurrentReviewReplies([...review.replies, text]);
    } catch (error) {
      showSnackbar(error);
    }
    setText('');
    handleClose();
  };

  const BootstrapButton = styled(Button)({
    width: '114px',
    height: '37px',
    borderRadius: '5px',
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
    padding: 0,
    border: 'none',
    color: '#191919',
    '&:hover': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: '-1px -1px 5px 1px rgba(0, 0, 0, 0.25)',
    },
    '&:active': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: '-1px -1px 5px 1px rgba(0, 0, 0, 0.25)',
    },
    '&:focus': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: '-1px -1px 5px 1px rgba(0, 0, 0, 0.25)',
    },
  });

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ style: { backgroundColor: 'rgba(217, 217, 217, 0.3)', backdropFilter: 'blur(5px)' } }}
    >
      <Box sx={style}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar
              src={baseURLImage + review.reviewer?.picture}
              alt="Buyer Avatar"
              sx={{ width: 70, height: 70, marginRight: 4 }}
            />
            <Box>
              <Typography variant="body1" fontSize="14" fontWeight="500">
                <Box
                  component="span"
                  sx={{
                    width: 'fit-content',
                    position: 'relative',
                    '&::after': {
                      content: "''",
                      display: 'inline-block',
                      width: '17px',
                      height: '17px',
                      backgroundImage: `${review.reviewer?.verification === 'verified' || review.isGenerated ? `url(${verificationIcon})` : 'none'}`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      position: 'absolute',
                      top: '-9px',
                      right: '-19px',
                    },
                  }}
                >
                  {`${review.reviewer?.firstName} ${review.reviewer?.lastName}`}
                </Box>
              </Typography>
              <Typography component="div" variant="body2" fontSize="14" display="flex" alignItems="center">
                {review.reviewer?.country?.name && (
                  <>
                    <img
                      src={isDarkMode ? locationIconDM : locationIcon}
                      alt="Location Icon"
                      style={{ maxHeight: '15px', marginTop: '-2px', marginRight: '3px' }}
                    />
                    {review.reviewer.country.name}
                  </>
                )}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ marginY: 3 }} />

          <Box mb={3}>
            <Box display="flex" alignItems="center" mb={3} ml={1}>
              <Rating
                name="text-feedback"
                value={review.star}
                readOnly
                precision={1}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                sx={{ marginRight: 2 }}
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                {timeAgo(review.createdAt)}
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              {review.text}
            </Typography>
          </Box>

          <Box display="flex" mb={3} alignItems="center">
            <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ marginRight: 2 }}>
              <Typography variant="caption" color="textSecondary">
                Type
              </Typography>
              <Typography variant="body1" fontWeight="500">
                {review.product.productInfo.kind}
              </Typography>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />

            <Box display="flex" alignItems="center">
              <Avatar
                src={baseURLImage + review.product.media[0]}
                alt={review.product.title}
                variant="rounded"
                sx={{ width: 40, height: 40, marginRight: 2 }}
              />
              <Typography variant="body1" fontWeight="500">
                {review.product.title}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" mb={3}>
            <TextField
              // label="Reply to buyer"
              placeholder="Reply buyer’s review..."
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': { borderColor: '#ddd' },
                  '&:focus-visible fieldset': { borderColor: '#ddd', outline: 'none' },
                  '&.Mui-focused fieldset': { borderColor: '#ddd' },
                },
              }}
              onChange={handleChange}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleClose}
              sx={{
                bgcolor: '#DEDEDE',
                marginRight: 2,
                '&:hover': {
                  bgcolor: '#DEDEDE',
                },
                '&:active': {
                  bgcolor: '#DEDEDE',
                },
                '&:focus': {
                  bgcolor: '#DEDEDE',
                },
              }}
            >
              Cancel
            </BootstrapButton>
            <BootstrapButton
              variant="contained"
              disableRipple
              disabled={!text}
              onClick={handleSubmit}
              sx={{
                bgcolor: '#fff',
              }}
            >
              Reply Buyer
            </BootstrapButton>
          </Box>
        </CardContent>
      </Box>
    </StyledModal>
  );
};

export default ReplyModelComponent;
