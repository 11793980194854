import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import { formatDateTime } from '../../utils/functions/dateTimeFormatter';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals';

const BidTableComponent = ({ productData, user, acceptedOrderList }) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: '10px',
        boxShadow: '0px 0px 4px 0px #0000001A',
      }}
    >
      <Table style={{ borderRadius: '10px' }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={productData?.user?._id === user?._id ? 5 : 3} sx={{ fontWeight: 500, fontSize: 24 }}>
              {t('Bids')}
            </TableCell>
          </TableRow>
          <TableRow style={{ height: '50px' }}>
            <TableCell sx={{ fontWeight: 300, fontSize: 18 }}>{t('Highest bids')}</TableCell>
            <TableCell sx={{ fontWeight: 300, fontSize: 18 }} colSpan={productData?.user?._id === user?._id ? 1 : 2}>
              {t('From')}
            </TableCell>
            {productData?.user?._id === user?._id && (
              <>
                <TableCell sx={{ fontWeight: 300, fontSize: 18 }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 300, fontSize: 18 }} colSpan={2}>
                  Time
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {acceptedOrderList?.map((order, index) => (
            <TableRow
              key={order?._id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
                bgcolor: order?.user?._id === user?._id || index === 0 ? null : '#E2E2E259',
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: 18,
                }}
              >
                {order?.converter?.currency?.abbr_name} {formatNumberWithTwoDecimals(order?.converter?.price)}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: 18,
                }}
              >
                {order?.user?._id === user?._id ? (
                  <>YOUR BID</>
                ) : (
                  <>
                    {order?.user?.firstName} {order?.user?.lastName}
                  </>
                )}
              </TableCell>
              {productData?.user?._id === user?._id && (
                <>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    {formatDateTime(order?.updatedAt)?.date}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    {formatDateTime(order?.updatedAt)?.time}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BidTableComponent;
