import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, Collapse, Typography } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined';
import { CustomTextTypography } from './StyledNavbarComponent.jsx';
import { CustomIosSwitch } from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';

const SidebarItem = ({
  label,
  icon,
  isOpen,
  onClick,
  to = null,
  subItems = null,
  onCloseSideBar,
  labelAdditionally = null,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');

  const handleClick = () => {
    if (onCloseSideBar) onCloseSideBar();
    if (onClick) onClick();
  };

  const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <>
      <ListItemButton sx={{ px: 4, ml: 4, py: 2 }} onClick={handleClick} component={to ? Link : 'div'} to={to}>
        <ListItemIcon>
          {label === 'Night Mode' ? (
            <ModeNightOutlinedIcon
              style={{ width: '26px', height: '26px', color: isDarkMode ? '#fff' : '#000000DE' }}
            ></ModeNightOutlinedIcon>
          ) : (
            icon && <img src={icon} alt={`${label} icon`} style={{ width: '26px', height: '26px' }} />
          )}
        </ListItemIcon>
        <CustomTextTypography fontSize={18} className="flex justify-between w-full">
          {label}
          {labelAdditionally && (
            <>
              <span className=" w-[20px] h-[20px] flex items-center justify-center rounded-full bg-[#AA0000]">
                <Typography fontSize={10} className="text-xs text-[#FFFFFF]" noWrap>
                  {labelAdditionally}
                </Typography>
              </span>
            </>
          )}
        </CustomTextTypography>
        {label === 'Night Mode' && <CustomIosSwitch checked={isDarkMode} onChange={toggleTheme} />}

        {subItems && isOpen && <ExpandLess />}
      </ListItemButton>
      {subItems && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((subItem, index) => (
              <ListItemButton key={index} sx={{ pl: 14, py: 2 }}>
                <ListItemIcon>
                  <img src={subItem.icon} alt={`${subItem.label} Icon`} />
                </ListItemIcon>
                <CustomTextTypography>{subItem.label}</CustomTextTypography>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SidebarItem;
