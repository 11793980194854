import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import axios from '../../../config/axiosConfig';
import { selectUser } from '../../../store/features/userSlice';
import './supportChatStyle.css';

const canUseDOM = () => {
  return typeof window !== 'undefined' && window.document && window.document.createElement;
};

export const ZendeskAPI = (...args) => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn('Zendesk is not initialized yet');
  }
};

const Zendesk = ({ zendeskKey, defer = false, userName, userEmail, zendeskJWT, zESettings }) => {
  useEffect(() => {
    if (canUseDOM() && !window.zE) {
      window.zESettings = zESettings;

      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
      script.defer = defer;
      script.async = !defer;

      script.addEventListener('load', () => {
        if (window.zE) {
          window.zE('messenger', 'close');
          // window.zE('messenger', 'setLocale', 'en-US');

          window.zE('messenger', 'loginUser', (cb) => cb(zendeskJWT));

          // window.zE('messenger', 'identify', {
          //   name: 'Test name',
          //   email: 'test_email@gmail.com',
          //   external_id: 'some_user_id_from_bd_{24}',
          // });

          // window.zE('webWidget', 'identify', {
          //   name: 'Test name',
          //   email: 'test_email@gmail.com',
          //   external_id: 'some_user_id_from_bd_{24}',
          // });

          // window.zE('webWidget', 'setAttributes', {
          //   name: 'Test name',
          //   email: 'test_email@gmail.com',
          //   external_id: 'some_user_id_from_bd_{24}',
          // });

          //  window.zE('messenger', 'prefill', {
          //   name: {
          //     value: 'Test name',
          //     readOnly: true // optional
          //   },
          //   email: {
          //     value: 'test_email@gmail.com',
          //     readOnly: true // optional
          //   }
          // })
        }
      });

      document.body.appendChild(script);
    }

    return () => {
      if (canUseDOM() && window.zE) {
        delete window.zE;
        delete window.zESettings;
        const script = document.getElementById('ze-snippet');
        if (script) {
          script.remove();
        }
      }
    };
  }, [zendeskKey, defer, zendeskJWT, zESettings]);

  return null;
};

Zendesk.propTypes = {
  zendeskKey: PropTypes.string.isRequired,
  defer: PropTypes.bool,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  zendeskJWT: PropTypes.string,
};

function SupportChat() {
  const user = useSelector(selectUser);
  const location = useLocation();

  const [zendeskJWT, setZendeskJWT] = useState();

  const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

  const fetchZendeskJWT = useCallback(async () => {
    try {
      const { data } = await axios.get('/user/zendesk-jwt');
      setZendeskJWT(data.token);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== '/chat' && user?._id) {
      fetchZendeskJWT();
    }
  }, [fetchZendeskJWT, location.pathname, user?._id]);

  if (location.pathname !== '/chat' && user?._id && zendeskJWT) {
    const zESettings = {
      webWidget: {
        // authenticate: {
        //   jwtFn: (callback) => callback(zendeskJWT),
        // },
        offset: {
          horizontal: '20px',
          vertical: '20px',
        },
        chat: {
          supersized: true,
          suppress: false,
          profileCard: {
            avatar: false,
          },
        },
      },
    };

    return <Zendesk defer zendeskKey={ZENDESK_KEY} zESettings={zESettings} zendeskJWT={zendeskJWT} />;
  }

  return <></>;
}

export default SupportChat;

/*

testco2539 > to username

chat dashboard: https://testco2539.zendesk.com/agent/dashboard
setting chat: https://testco2539.zendesk.com/admin/channels/messaging_and_social/channels_list
get token: https://testco2539.zendesk.com/admin/account/security/end_users#messaging

*/
