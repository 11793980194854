import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from '../../config/axiosConfig';
import {
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
  Input,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Link,
  Rating,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { setUser, selectUser } from '../../store/features/userSlice';
import { selectUserPrepopulateAddress } from '../../store/features/userPrepopulateAddress';

import {
  CustomButton,
  CustomInputs,
  CustomTelInputs,
} from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import TooltipButton from '../../utils/ui/TooltipComponent.jsx';
import CustomTabs from '../../utils/ui/TabsComponent.jsx';
import CustomCard from '../../utils/ui/CardComponent.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils.js';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries.js';
import AddressFields from '../../utils/ui/AddressFieldsComponent.jsx';
import UserChangePassword from '../../utils/ui/forms/UserChangePassword.jsx';
import VerificationForm from '../../utils/ui/forms/VerificationForm.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import { trimObjectValues } from '../../utils/functions/trimObjectValues.js';
import { isEqualObjectsExceptIds } from '../../utils/functions/isEqualObjects.js';
import { handleChangeFileInput } from '../../utils/functions/handleChangeFileInput.js';
import performRefreshUser from '../../utils/requests/refreshUser.js';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import { baseURLImage } from '../../utils/constants.js';
import UnfulfilledItemForm from '../productPage/UnfulfilledItemForm.jsx';

import CustomTable from './ItemsTableComponent.jsx';
import { getMyProductsChipColor, getMyShoppingItemsChipColor } from './cardChipColors';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import fileIcon from '../../assets/icons/fileIcon.svg';
import accountIcon from '../../assets/icons/accountIcon.svg';
import dumpIcon from '../../assets/icons/dumpIcon.svg';
import fileIconDM from '../../assets/icons/fileIconDM.svg';
import accountIconDM from '../../assets/icons/accountIconDM.svg';
import dumpIconDM from '../../assets/icons/dumpIconDM.svg';
import verificationIcon from '../../assets/icons/verificationIcon.svg';
import { formatDateTime } from '../../utils/functions/dateTimeFormatter';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals';
import { ProfileReviewComponent } from './ProfileReviewComponent.jsx';

function ProfileComponent() {
  const { isLgScreen, isXlScreen } = useMediaQueries();

  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const userPrepopulateAddress = useSelector(selectUserPrepopulateAddress);
  const dispatch = useDispatch();
  const { hash } = useParams();
  const userRef = useRef(user);
  const navigate = useNavigate();

  const [userAuction, setUserAuction] = useState([null]);
  const [userBid, setUserBid] = useState([null]);
  const [userRaffle, setUserRaffle] = useState([null]);
  const [userTicket, setUserTicket] = useState([null]);
  const [userMarketplace, setUserMarketplace] = useState([null]);
  const [userItemsBought, setUserItemsBought] = useState([null]);
  const [userBillingOrder, setUserBillingOrder] = useState([null]);
  const [userPayouts, setUserPayouts] = useState([null]);
  const [userWatchlistAuction, setUserWatchlistAuction] = useState([null]);
  const [userWatchlistRaffle, setUserWatchlistRaffle] = useState([null]);
  const [userWatchlistMarketplace, setUserWatchlistMarketplace] = useState([null]);
  const [userUnfulfilledProduct, setUserUnfulfilledProduct] = useState([null]);
  const [userUnfulfilledOrder, setUserUnfulfilledOrder] = useState([null]);
  const [countriesList, setCountriesList] = useState([null]);
  const [userBillingOrderSelected, setUserBillingOrderSelected] = useState(null);
  const [userPayoutsOrderSelected, setUserPayoutsOrderSelected] = useState(null);

  const [dataByUserLoading, setDataByUserLoading] = useState(false);
  const [orderByUserLoading, setOrderByUserLoading] = useState(false);
  const [watchlistLoading, setWatchlistLoading] = useState(false);
  const [dataByUserPaymentLoading, setDataByUserPaymentLoading] = useState(false);
  const [userBillingOrderLoading, setUserBillingOrderLoading] = useState(false);
  const [userUnfulfilledProductLoading, setUserUnfulfilledProductLoading] = useState(false);
  const [userUnfulfilledOrderLoading, setUserUnfulfilledOrderLoading] = useState(false);
  const [loadingChangeUserButton, setLoadingChangeUserButton] = useState(false);
  const [isUserRefreshData, setIsUserRefreshData] = useState(false);
  const [isShowBillignItemForm, setIsShowBillignItemForm] = useState(false);
  const [isShowPayoutsItemForm, setIsShowPayoutsItemForm] = useState(false);

  const [selectedMainTab, setSelectedMainTab] = useState(0);
  const [selectedAuctionTab, setSelectedAuctionTab] = useState(0);
  const [selectedRaffleTab, setSelectedRaffleTab] = useState(0);
  const [selectedMarketplaceTab, setSelectedMarketplaceTab] = useState(0);
  const [selectedWatchlistTab, setSelectedWatchlistTab] = useState(0);
  const [selectedBillingPayoutsTab, setSelectedBillingPayoutsTab] = useState(0);
  const [selectedFulfilmentsTab, setSelectedFulfilmentsTab] = useState(0);

  const [userChangePictureFiles, setUserChangePictureFiles] = useState([]);
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);
  const [userStar, setUserStar] = useState(0);

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailErrorExists, setEmailErrorExists] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [selectSameAddress, setSelectSameAddress] = useState(
    isEqualObjectsExceptIds(user?.shipping_address, user?.billing_address)
  );
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const [isShowEmailMessage, setIsShowEmailMessage] = useState(false);
  const [verificationDialog, setVerificationDialog] = useState(false);

  const [billingAddress, setBillingAddress] = useState({
    country: user?.billing_address?.country,
    city: user?.billing_address?.city,
    street: user?.billing_address?.street,
    state: user?.billing_address?.state,
    postalCode: user?.billing_address?.postalCode,
  });
  const [shippingAddress, setShippingAddress] = useState({
    country: user?.shipping_address?.country,
    city: user?.shipping_address?.city,
    street: user?.shipping_address?.street,
    state: user?.shipping_address?.state,
    postalCode: user?.shipping_address?.postalCode,
  });
  const [billingErrors, setBillingErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });
  const [shippingErrors, setShippingErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);
  const selectSameAddressRef = useRef(selectSameAddress);

  const handleBillingAddressChange = (field, value) => {
    setBillingAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    setBillingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleShippingAddressChange = (field, value) => {
    setShippingAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    setShippingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };
  const handleChangeMainTab = (e, value) => {
    setSelectedMainTab(value);
    const currentPath = window.location.pathname;

    const newPath = currentPath.replace(/\d+$/, value);

    window.history.replaceState(null, null, newPath);
  };

  const handleFileInputChange = (event) => handleChangeFileInput(event, setUserChangePictureFiles, showSnackbar);

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = userChangePictureFiles.filter((_, index) => index !== indexToRemove);
    setUserChangePictureFiles(updatedFiles);
  };

  const handleDeleteFromWatchlist = (id) => {
    axios
      .delete(`watch/${id}`)
      .then(() => fetchDataWatchlist())
      .catch((error) => showSnackbarRef.current(error));
  };

  const fetchUserStarData = useCallback(async () => {
    const { data } = await axios.get(`review/owner/${user._id}/average-star`);
    setUserStar(data.averageStar);
  }, [user._id]);

  const handleChangeUserData = async () => {
    const fieldIsRequiredMsg = `${t('Field is required')}`;
    let hasError = false;

    const checkFields = (fields, setError) => {
      fields.forEach(({ value, fieldName }) => {
        if (!value || (typeof value === 'string' && !value.trim())) {
          setError((prevErrors) => ({
            ...prevErrors,
            [fieldName]: fieldIsRequiredMsg,
          }));
          hasError = true;
        }
      });
    };

    let billingFields = [
      { value: billingAddress.country, fieldName: 'country' },
      { value: billingAddress.city, fieldName: 'city' },
      { value: billingAddress.street, fieldName: 'street' },
      { value: billingAddress.postalCode, fieldName: 'postalCode' },
    ];
    if (billingAddress.country === 'CA') billingFields.push({ value: billingAddress.state, fieldName: 'state' });
    checkFields(billingFields, setBillingErrors);

    if (!selectSameAddress) {
      let shippingFields = [
        { value: shippingAddress.country, fieldName: 'country' },
        { value: shippingAddress.city, fieldName: 'city' },
        { value: shippingAddress.street, fieldName: 'street' },
        { value: shippingAddress.postalCode, fieldName: 'postalCode' },
      ];
      if (shippingAddress.country === 'CA') shippingFields.push({ value: shippingAddress.state, fieldName: 'state' });
      checkFields(shippingFields, setShippingErrors);
    }
    if (hasError) return;
    setLoadingChangeUserButton(true);

    const userPictureFormData = new FormData();
    userChangePictureFiles.forEach((file, index) => {
      userPictureFormData.append(`avatar`, file);
    });

    axios
      .put('user/me', {
        phone: phone,
        email: user?.email !== email ? email.trim() : undefined,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        shipping_address: selectSameAddress ? trimObjectValues(billingAddress) : trimObjectValues(shippingAddress),
        billing_address: trimObjectValues(billingAddress),
      })
      .then((response) => {
        if (user?.email !== email) setIsShowEmailMessage(true);
        dispatch(setUser(response?.data));
        setLoadingChangeUserButton(false);
        showSnackbar(null, 'Success', 'success');
      })
      .catch((error) => {
        if (error.response?.data?.status === 205) {
          const respObj = JSON.parse(error.response?.data?.response);
          selectSameAddress
            ? setBillingAddress(respObj.recommendedAddress)
            : setShippingAddress(respObj.recommendedAddress);
          showSnackbarRef.current({ response: { data: { message: respObj.message } } });
        } else {
          setEmailErrorExists(error.response?.data?.response);
          setEmailError(error.response?.data?.errors?.email?.message);
          showSnackbarRef.current(error);
        }
        setLoadingChangeUserButton(false);
      });

    if (userChangePictureFiles.length !== 0) {
      axios
        .put(`user/avatar`, userPictureFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          dispatch(setUser(response?.data));
          setUserChangePictureFiles([]);
          setLoadingChangeUserButton(false);
        });
    }
  };

  const fetchDataByUser = useCallback(async () => {
    setDataByUserLoading(true);

    const { data: responseDataByUser } = await axios.get(`product/by-user?viewCurrency=${user.currency}`);
    const { userAuction, userRaffle, userMarketplace } = responseDataByUser.reduce(
      (accumulator, product) => {
        if (product.productInfo.kind === 'Auction') accumulator.userAuction.push(product);
        else if (product.productInfo.kind === 'Raffle') accumulator.userRaffle.push(product);
        else accumulator.userMarketplace.push(product);

        return accumulator;
      },
      { userAuction: [], userRaffle: [], userMarketplace: [] }
    );

    setUserAuction(userAuction);
    setUserRaffle(userRaffle);
    setUserMarketplace(userMarketplace);

    setDataByUserLoading(false);
  }, [user.currency]);

  const fetchDataOrderByUser = useCallback(async () => {
    setOrderByUserLoading(true);

    const { data: orderByUser } = await axios.get(`order/by-user?viewCurrency=${user.currency}`);

    const userAuctionBid = orderByUser
      .filter((auction) => auction.productInfo.kind === 'Auction')
      .flatMap((auction) =>
        auction?.orderList.map((bid) => {
          if (bid?.user === user?._id) return { auction, bid };
          else return null;
        })
      )
      .filter((data) => data !== null);

    setUserBid(userAuctionBid);

    const userRaffleTicket = orderByUser
      .filter((raffle) => raffle.productInfo.kind === 'Raffle')
      .flatMap((raffle) => {
        const acceptedTickets = raffle?.orderList
          .map((ticket) => {
            if (ticket?.user === user?._id) return { status: ticket.status, amount: ticket.amount };
            else return null;
          })
          .filter((ticket) => ticket !== null);

        if (acceptedTickets?.length > 0) {
          const totalAmount = acceptedTickets.reduce((acc, ticket) => acc + ticket.amount, 0);
          return { raffle, totalAmount, tickets: acceptedTickets };
        } else return null;
      })
      .filter((data) => data !== null);

    setUserTicket(userRaffleTicket);

    const userShopping = orderByUser
      .filter((item) => item.productInfo.kind === 'Marketplace')
      .flatMap((item) => {
        const boughtItems = item?.orderList
          .map((ticket) => {
            if (ticket?.user === user?._id) return { status: ticket.status, amount: ticket.amount };
            else return null;
          })
          .filter((ticket) => ticket !== null);

        if (boughtItems?.length > 0) {
          const totalAmount = boughtItems.reduce((acc, ticket) => acc + ticket.amount, 0);
          return { item, totalAmount, tickets: boughtItems };
        } else return null;
      })
      .filter((data) => data !== null);

    setUserItemsBought(userShopping);

    const userBillingOrder = orderByUser
      .flatMap((order) =>
        order?.orderList
          ?.filter((order) => order?.status !== 'rejected')
          .map((customer) => {
            if (customer?.user === user?._id) return { order, customer };
            else return null;
          })
      )
      .filter((data) => data !== null);

    setUserBillingOrder(userBillingOrder);
    setOrderByUserLoading(false);
  }, [user._id, user.currency]);

  const fetchDataWatchlist = useCallback(async () => {
    setWatchlistLoading(true);

    const { data: watchlistResponse } = await axios.get(`watch?viewCurrency=${user.currency}`);
    const userAuctionWatchlist = watchlistResponse.filter(
      (auction) => auction?.product?.productInfo?.kind === 'Auction'
    );
    setUserWatchlistAuction(userAuctionWatchlist);
    const userRaffleWatchlist = watchlistResponse.filter((raffle) => raffle?.product?.productInfo?.kind === 'Raffle');
    setUserWatchlistRaffle(userRaffleWatchlist);
    const userMarketplaceWatchlist = watchlistResponse.filter(
      (item) => item?.product?.productInfo?.kind === 'Marketplace'
    );
    setUserWatchlistMarketplace(userMarketplaceWatchlist);

    setWatchlistLoading(false);
  }, [user.currency]);

  const fetchDataByUserUnfulfilledOrder = useCallback(async () => {
    setUserUnfulfilledOrderLoading(true);
    const { data: orderByUser } = await axios.get(`order/by-user?status=unfulfilled&viewCurrency=${user.currency}`);

    const filterOrderByUser = orderByUser
      .flatMap((order) =>
        order?.orderList
          ?.filter((order) => order?.status === 'unfulfilled' || order?.status === 'fulfilled')
          .map((customer) => {
            if (customer?.user === user?._id) return { order, customer };
            else return null;
          })
      )
      .filter((data) => data !== null);

    setUserUnfulfilledOrder(filterOrderByUser);
    setUserUnfulfilledOrderLoading(false);
  }, [user.currency, user?._id]);

  const fetchDataByUserUnfulfilledProduct = useCallback(async () => {
    setUserUnfulfilledProductLoading(true);
    const [{ data: responseUserUnfulfilledProduct }, { data: responseUserFulfilledProduct }] = await Promise.all([
      axios.get(`product/by-user?status=unfulfilled&viewCurrency=${user.currency}`),
      axios.get(`product/by-user?status=fulfilled&viewCurrency=${user.currency}`),
    ]);

    const userUnfulfilledProduct = [...responseUserUnfulfilledProduct, ...responseUserFulfilledProduct];

    userUnfulfilledProduct.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));

    setUserUnfulfilledProduct(userUnfulfilledProduct);
    setUserUnfulfilledProductLoading(false);
  }, [user.currency]);

  const fetchDataByUserPayoutProduct = useCallback(async () => {
    setDataByUserPaymentLoading(true);
    const [{ data: responseUserPayProduct }, { data: responseUserPaidProduct }] = await Promise.all([
      axios.get(`product/by-user?status=pay&viewCurrency=${user.currency}`),
      axios.get(`product/by-user?status=paid&viewCurrency=${user.currency}`),
    ]);

    const userPayoutsProduct = [...responseUserPayProduct, ...responseUserPaidProduct];

    setUserPayouts(userPayoutsProduct);
    setDataByUserPaymentLoading(false);
  }, [user.currency]);

  useEffect(() => {
    fetchUserStarData();
    if (
      (selectedAuctionTab === 0 && selectedMainTab === 0) ||
      (selectedRaffleTab === 0 && selectedMainTab === 1) ||
      (selectedMarketplaceTab === 0 && selectedMainTab === 2)
    )
      fetchDataByUser();
    else if (selectedAuctionTab === 1 || selectedRaffleTab === 1 || selectedMarketplaceTab === 1)
      fetchDataOrderByUser();
  }, [
    fetchUserStarData,
    fetchDataByUser,
    fetchDataOrderByUser,
    selectedAuctionTab,
    selectedRaffleTab,
    selectedMarketplaceTab,
    selectedMainTab,
  ]);

  useEffect(() => {
    if (selectedMainTab === 3) fetchDataWatchlist();
  }, [fetchDataWatchlist, selectedWatchlistTab, selectedMainTab]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBillingPayoutsTab === 0 && selectedMainTab === 4) {
        setUserBillingOrderLoading(true);
        await fetchDataOrderByUser();
        setUserBillingOrderLoading(false);
      } else if (selectedBillingPayoutsTab === 1 && selectedMainTab === 4) fetchDataByUserPayoutProduct();
    };
    fetchData();
  }, [fetchDataOrderByUser, fetchDataByUserPayoutProduct, selectedBillingPayoutsTab, selectedMainTab]);

  useEffect(() => {
    if (selectedFulfilmentsTab === 1 && selectedMainTab === 5) fetchDataByUserUnfulfilledProduct();
    else if (selectedFulfilmentsTab === 0 && selectedMainTab === 5) fetchDataByUserUnfulfilledOrder();
  }, [fetchDataByUserUnfulfilledProduct, fetchDataByUserUnfulfilledOrder, selectedFulfilmentsTab, selectedMainTab]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMainTab === 7) {
        setIsUserRefreshData(true);
        try {
          const [{ data: countriesListResponse }] = await Promise.all([
            axios.get('country'),
            performRefreshUser(dispatch),
          ]);
          setCountriesList(countriesListResponse);

          const isSameCountry = userPrepopulateAddress?.country === userRef.current?.country?.iso;
          const isUserCountry = userRef.current?.country;
          if (!userRef.current.billing_address) {
            setBillingAddress((prevBillingAddress) => ({
              ...prevBillingAddress,
              country: isUserCountry ? userRef.current.country.iso : userPrepopulateAddress?.country,
              city: !isUserCountry || isSameCountry ? userPrepopulateAddress?.city : '',
              postalCode: !isUserCountry || isSameCountry ? userPrepopulateAddress?.postalCode : '',
            }));
            if (!selectSameAddressRef.current) {
              setShippingAddress((prevShippingAddress) => ({
                ...prevShippingAddress,
                country: isUserCountry ? userRef.current.country.iso : userPrepopulateAddress?.country,
                city: !isUserCountry || isSameCountry ? userPrepopulateAddress?.city : '',
                postalCode: !isUserCountry || isSameCountry ? userPrepopulateAddress?.postalCode : '',
              }));
            }
          }
        } catch (error) {
          showSnackbarRef.current(error);
        } finally {
          setIsUserRefreshData(false);
        }
      }
    };

    fetchData();
  }, [
    selectedMainTab,
    dispatch,
    userPrepopulateAddress?.country,
    userPrepopulateAddress?.city,
    userPrepopulateAddress?.postalCode,
  ]);

  useEffect(() => {
    userRef.current = user;
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setPhone(user?.phone);
    setBillingAddress({
      country: user?.billing_address?.country,
      city: user?.billing_address?.city,
      street: user?.billing_address?.street,
      state: user?.billing_address?.state,
      postalCode: user?.billing_address?.postalCode,
    });
    setShippingAddress({
      country: user?.shipping_address?.country,
      city: user?.shipping_address?.city,
      street: user?.shipping_address?.street,
      state: user?.shipping_address?.state,
      postalCode: user?.shipping_address?.postalCode,
    });
  }, [user]);

  useEffect(() => {
    if (hash) setSelectedMainTab(Number(hash));
  }, [hash, setSelectedMainTab]);

  const PayoutsCalculateAndFormatAmount = (item) => {
    let amount = 0;
    if (item?.productInfo?.kind === 'Raffle') amount = item?.converter?.price * item?.totalTicketsSold;
    else {
      const finishedOrder = item?.orderList?.find((order) => order?.status === 'finished');
      if (finishedOrder) amount = finishedOrder?.converter?.price;
    }
    amount *= 0.95;
    return `${item?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(amount)}`;
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <Box component="div" className="mb-20 px-2 md:px-5">
        <Box component="div" className="flex flex-col items-center mt-4">
          <img
            src={!user.picture ? (isDarkMode ? accountIconDM : accountIcon) : baseURLImage + user.picture}
            style={{ width: '218px', height: '218px', borderRadius: '50%', objectFit: 'cover' }}
            alt="Account Icon"
          />
          <Typography sx={{ mt: 2 }} fontWeight={500} fontSize={24} className="flex">
            <Box
              component="span"
              sx={{
                width: 'fit-content',
                position: 'relative',
                '&::after': {
                  content: "''",
                  display: 'inline-block',
                  width: '27px',
                  height: '27px',
                  backgroundImage: `${user?.verification === 'verified' ? `url(${verificationIcon})` : 'none'}`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  position: 'absolute',
                  top: '-10px',
                  right: '-30px',
                },
              }}
            >
              {user?.firstName} {user?.lastName}
            </Box>
          </Typography>
          <Rating
            value={userStar}
            readOnly
            precision={0.5}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            sx={{ marginRight: 2 }}
            size="medium"
          />
        </Box>
        <div className="mt-6 px-2">
          <div className="md:px-4">
            <CustomTabs
              tabs={[
                { label: 'Auctions' },
                { label: 'Raffles' },
                { label: 'Marketplace' },
                { label: 'Watchlist' },
                { label: 'Billing & Payouts' },
                { label: 'Fulfillments' },
                { label: 'Reviews' },
                { label: 'Settings' },
              ]}
              selectedTab={selectedMainTab}
              handleChange={(e, value) => handleChangeMainTab(e, value)}
              isLastBetween={isLgScreen ? true : false}
              titleSize={18}
            />
          </div>
          <div className="mt-4">
            <div className="md:px-4">
              {selectedMainTab === 0 && (
                <>
                  <CustomTabs
                    tabs={[{ label: 'My Auctions' }, { label: 'My Bids' }]}
                    selectedTab={selectedAuctionTab}
                    handleChange={(e, value) => setSelectedAuctionTab(value)}
                    titleSize={16}
                  />
                </>
              )}
              {selectedMainTab === 1 && (
                <>
                  <CustomTabs
                    tabs={[{ label: 'My Raffles' }, { label: 'My Tickets' }]}
                    selectedTab={selectedRaffleTab}
                    handleChange={(e, value) => setSelectedRaffleTab(value)}
                    titleSize={16}
                  />
                </>
              )}
              {selectedMainTab === 2 && (
                <>
                  <CustomTabs
                    tabs={[{ label: 'My Items' }, { label: 'Items Bought' }]}
                    selectedTab={selectedMarketplaceTab}
                    handleChange={(e, value) => setSelectedMarketplaceTab(value)}
                    titleSize={16}
                  />
                </>
              )}
              {selectedMainTab === 3 && (
                <>
                  <CustomTabs
                    tabs={[{ label: 'Auctions' }, { label: 'Raffles' }, { label: 'Marketplace' }]}
                    selectedTab={selectedWatchlistTab}
                    handleChange={(e, value) => setSelectedWatchlistTab(value)}
                    titleSize={16}
                  />
                </>
              )}
              {selectedMainTab === 4 && (
                <>
                  <CustomTabs
                    tabs={[{ label: 'Billing' }, { label: 'Payout' }]}
                    selectedTab={selectedBillingPayoutsTab}
                    handleChange={(e, value) => setSelectedBillingPayoutsTab(value)}
                    titleSize={16}
                  />
                </>
              )}
              {selectedMainTab === 5 && (
                <>
                  <CustomTabs
                    tabs={[{ label: 'My Orders' }, { label: 'My Products' }]}
                    selectedTab={selectedFulfilmentsTab}
                    handleChange={(e, value) => setSelectedFulfilmentsTab(value)}
                    titleSize={16}
                  />
                </>
              )}
            </div>
            <div
              className="mt-4 md:px-4 md:flex md:flex-row md:flex-wrap"
              style={{
                justifyContent: selectedMainTab === 7 ? 'center' : 'start',
              }}
            >
              {selectedMainTab === 0 ? (
                selectedAuctionTab === 0 ? (
                  <>
                    {userAuction.length === 0 && <div>None</div>}
                    {userAuction.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item}
                          index={index}
                          isLoading={dataByUserLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileFirstCardItem={{
                            title: 'Last highest bid',
                            value: {
                              currency: item?.orderList?.[0]?.converter?.currency?.abbr_name,
                              price: item?.orderList?.[0]?.converter?.price,
                            },
                          }}
                          profileSecondCardItem={{
                            title: 'Total bids',
                            value: {
                              currency: null,
                              price: item?.totalBids,
                            },
                          }}
                          chip={{
                            ...getMyProductsChipColor(item?.status),
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {userBid.length === 0 && <div>None</div>}
                    {userBid.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item?.auction}
                          index={index}
                          isLoading={orderByUserLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileFirstCardItem={{
                            title: 'Last highest bid',
                            value: {
                              currency: item?.auction?.orderList?.[0]?.converter?.currency?.abbr_name,
                              price: item?.auction?.orderList?.[0]?.converter?.price,
                            },
                          }}
                          profileSecondCardItem={{
                            title: 'Your last bid',
                            value: {
                              currency: item?.bid?.converter?.currency?.abbr_name,
                              price: item?.bid?.converter?.price,
                            },
                          }}
                          chip={{
                            ...getMyShoppingItemsChipColor(item?.bid?.status),
                          }}
                        />
                      </div>
                    ))}
                  </>
                )
              ) : selectedMainTab === 1 ? (
                selectedRaffleTab === 0 ? (
                  <>
                    {userRaffle.length === 0 && <div>None</div>}
                    {userRaffle.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item}
                          index={index}
                          isLoading={dataByUserLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileFirstCardItem={{
                            title: 'Ticket price',
                            value: {
                              currency: item?.converter?.currency?.abbr_name,
                              price: item?.converter?.price,
                            },
                          }}
                          profileSecondCardItem={{
                            title: 'Total tickets sold',
                            value: {
                              currency: null,
                              price: item?.totalTicketsSold,
                            },
                          }}
                          chip={{
                            ...getMyProductsChipColor(item?.status),
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {userTicket.length === 0 && <div>None</div>}
                    {userTicket.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item?.raffle}
                          index={index}
                          isLoading={orderByUserLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileFirstCardItem={{
                            title: 'Ticket price',
                            value: {
                              currency: item?.raffle?.converter?.currency?.abbr_name,
                              price: item?.raffle?.converter?.price,
                            },
                          }}
                          profileSecondCardItem={{
                            title: 'Total tickets owned',
                            value: {
                              currency: null,
                              price: item?.totalAmount,
                            },
                          }}
                          chip={{
                            ...getMyShoppingItemsChipColor(item?.tickets?.[0]?.status),
                          }}
                        />
                      </div>
                    ))}
                  </>
                )
              ) : selectedMainTab === 2 ? (
                selectedMarketplaceTab === 0 ? (
                  <>
                    {userMarketplace.length === 0 && <div>None</div>}
                    {userMarketplace.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item}
                          index={index}
                          isLoading={dataByUserLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileFirstCardItem={{
                            title: 'Item price',
                            value: {
                              currency: item?.converter?.currency?.abbr_name,
                              price: item?.converter?.price,
                            },
                          }}
                          profileSecondCardItem={{
                            title: 'Items left',
                            value: {
                              currency: null,
                              price: item?.productInfo?.current_amount,
                            },
                          }}
                          chip={{
                            ...getMyProductsChipColor(item?.status),
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {userItemsBought.length === 0 && <div>None</div>}
                    {userItemsBought.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item?.item}
                          index={index}
                          isLoading={orderByUserLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileFirstCardItem={{
                            title: 'Item price',
                            value: {
                              currency: item?.item?.converter?.currency?.abbr_name,
                              price: item?.item?.converter?.price,
                            },
                          }}
                          profileSecondCardItem={{
                            title: 'Total items bought',
                            value: {
                              currency: null,
                              price: item?.totalAmount,
                            },
                          }}
                          chip={{
                            ...getMyShoppingItemsChipColor(item?.tickets?.[0]?.status),
                          }}
                        />
                      </div>
                    ))}
                  </>
                )
              ) : selectedMainTab === 3 ? (
                selectedWatchlistTab === 0 ? (
                  <>
                    {userWatchlistAuction.length === 0 && <div>None</div>}
                    {userWatchlistAuction.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item?.product}
                          index={index}
                          isLoading={watchlistLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          profileSecondCardItem={{
                            title: 'Last bid',
                            value: {
                              currency: item?.order?.converter?.currency?.abbr_name,
                              price: item?.order?.converter?.price,
                            },
                          }}
                          titleIcon={
                            <>
                              <IconButton
                                onClick={() => handleDeleteFromWatchlist(item?._id)}
                                sx={{
                                  width: 26,
                                  height: 26,
                                  padding: 0,
                                  ml: 2,
                                }}
                              >
                                <img
                                  src={isDarkMode ? dumpIconDM : dumpIcon}
                                  alt="Dump Icon"
                                  style={{
                                    width: 25,
                                    height: 25,
                                  }}
                                />
                              </IconButton>
                            </>
                          }
                        />
                      </div>
                    ))}
                  </>
                ) : selectedWatchlistTab === 1 ? (
                  <>
                    {userWatchlistRaffle.length === 0 && <div>None</div>}
                    {userWatchlistRaffle?.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item?.product}
                          index={index}
                          isLoading={watchlistLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          titleIcon={
                            <>
                              <IconButton
                                onClick={() => handleDeleteFromWatchlist(item?._id)}
                                sx={{
                                  width: 26,
                                  height: 26,
                                  padding: 0,
                                  ml: 2,
                                }}
                              >
                                <img
                                  src={isDarkMode ? dumpIconDM : dumpIcon}
                                  alt="Dump Icon"
                                  style={{
                                    width: 25,
                                    height: 25,
                                  }}
                                />
                              </IconButton>
                            </>
                          }
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {userWatchlistMarketplace.length === 0 && <div>None</div>}
                    {userWatchlistMarketplace?.map((item, index) => (
                      <div
                        className="mt-4 md:pr-4"
                        style={{
                          maxWidth: isXlScreen ? 340 : 310,
                        }}
                        key={index}
                      >
                        <CustomCard
                          item={item?.product}
                          index={index}
                          isLoading={watchlistLoading}
                          width={isXlScreen ? 315 : 290}
                          height={isXlScreen ? 315 : 290}
                          titleIcon={
                            <>
                              <IconButton
                                onClick={() => handleDeleteFromWatchlist(item?._id)}
                                sx={{
                                  width: 26,
                                  height: 26,
                                  padding: 0,
                                  ml: 2,
                                }}
                              >
                                <img
                                  src={isDarkMode ? dumpIconDM : dumpIcon}
                                  alt="Dump Icon"
                                  style={{
                                    width: 25,
                                    height: 25,
                                  }}
                                />
                              </IconButton>
                            </>
                          }
                        />
                      </div>
                    ))}
                  </>
                )
              ) : selectedMainTab === 4 ? (
                selectedBillingPayoutsTab === 0 ? (
                  <>
                    <CustomTable
                      data={userBillingOrder}
                      isLoading={userBillingOrderLoading}
                      isCursorPointer={true}
                      onClickHandler={(itemData) => {
                        setIsShowBillignItemForm(true);
                        setUserBillingOrderSelected(itemData);
                      }}
                      columnHeaders={['Amount', 'Status', 'Order No.:', 'Date']}
                      rowData={userBillingOrder?.map((item) => [
                        `${item?.customer?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                          item?.customer?.converter?.price
                        )}`,
                        item?.customer?.status === 'pending' ? 'Payment processing' : 'Paid',
                        `# ${item?.customer?._id}`,
                        formatDateTime(item?.order?.updatedAt)?.date,
                      ])}
                      statusColors={userBillingOrder?.map((item) =>
                        item?.customer?.status === 'pending' ? '#C67C7C' : '#7CC67E'
                      )}
                    />
                  </>
                ) : (
                  <>
                    <CustomTable
                      data={userPayouts}
                      onClickHandler={(itemData) => {
                        setIsShowPayoutsItemForm(true);
                        setUserPayoutsOrderSelected(itemData);
                      }}
                      isCursorPointer={true}
                      isLoading={dataByUserPaymentLoading}
                      columnHeaders={['Amount', 'Status', 'Date', 'Time']}
                      rowData={userPayouts?.map((item) => {
                        const status = item?.status === 'pay' ? 'Payout processing' : 'Paid';
                        const date = formatDateTime(item?.updatedAt)?.date;
                        const time = formatDateTime(item?.updatedAt)?.time;
                        return [
                          <span className="flex justify-center items-center">
                            {PayoutsCalculateAndFormatAmount(item)}
                            <span onClick={(e) => e.stopPropagation()}>
                              <TooltipButton
                                marginBottom={3}
                                title={t(
                                  'Clean amount you will be paid with 5% platform fee already charged automatically.'
                                )}
                              />
                            </span>
                          </span>,
                          status,
                          date,
                          time,
                        ];
                      })}
                      statusColors={userPayouts?.map((item) =>
                        item?.customer?.status === 'pay' ? '#C67C7C' : '#7CC67E'
                      )}
                    />
                  </>
                )
              ) : selectedMainTab === 5 ? (
                selectedFulfilmentsTab === 0 ? (
                  <>
                    <CustomTable
                      data={userUnfulfilledOrder}
                      onClickHandler={(itemData) => navigate(`/product/${itemData?._id}`)}
                      isLoading={userUnfulfilledOrderLoading}
                      columnHeaders={['Order No.:', 'Status', 'Amount', 'Customer', 'Date']}
                      rowData={userUnfulfilledOrder?.map((item) => [
                        `# ${item?.customer?._id}`,
                        item?.customer?.status,
                        item?.order?.productInfo?.kind === 'Raffle'
                          ? `${item?.customer?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                              item?.customer?.converter?.price * item?.order?.totalTicketsSold
                            )}`
                          : `${item?.customer?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                              item?.customer?.converter?.price
                            )}`,
                        `${item?.order?.customer?.firstName} ${item?.order?.customer?.lastName}`,
                        formatDateTime(item?.order?.end)?.date,
                      ])}
                      statusColors={userUnfulfilledOrder?.map((item) =>
                        item?.customer?.status === 'fulfilled' ? '#7CC67E' : '#C67C7C'
                      )}
                    />
                  </>
                ) : (
                  <CustomTable
                    data={userUnfulfilledProduct}
                    onClickHandler={(itemData) => navigate(`/product/${itemData?._id}`)}
                    isLoading={userUnfulfilledProductLoading}
                    isCursorPointer={true}
                    columnHeaders={['Order No.:', 'Status', 'Amount', 'Customer', 'Date']}
                    rowData={userUnfulfilledProduct?.map((item) => [
                      `# ${item?.customer?._id}`,
                      item?.status,
                      item?.productInfo?.kind === 'Raffle'
                        ? `${item?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                            item?.totalTicketsSold * item?.converter?.price
                          )}`
                        : `${item?.orderList?.[0]?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                            item?.orderList?.[0]?.converter?.price
                          )}`,
                      `${item?.customer?.firstName} ${item?.customer?.lastName}`,
                      formatDateTime(item?.end)?.date,
                    ])}
                    statusColors={userUnfulfilledProduct?.map((item) =>
                      item?.status === 'fulfilled' ? '#7CC67E' : '#C67C7C'
                    )}
                  />
                )
              ) : selectedMainTab === 6 ? (
                <ProfileReviewComponent userId={user._id} showSnackbar={showSnackbarRef.current} />
              ) : (
                <>
                  <div className="mt-6 md:mt-12 flex items-center flex-col">
                    {isUserRefreshData ? (
                      <div className="mt-6 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                      </div>
                    ) : (
                      <Card
                        className="min-[870px]:p-4"
                        sx={{
                          boxShadow: '0px 4px 4px 0px #0000001A',
                          maxWidth: 865,
                          borderRadius: '10px',
                        }}
                      >
                        <CardContent>
                          <Typography fontSize={18}>{t('Profile Picture')}</Typography>
                          <div className="mt-2">
                            <Card
                              className="flex justify-center items-center flex-col"
                              sx={{
                                mt: 2,
                                minHeight: 200,
                                padding: 3,
                                backgroundColor: isDarkMode ? 'transparent' : '#FAFAFA',
                                border: isDarkMode ? '1px solid #fff' : null,
                                borderRadius: '10px',
                                boxShadow: '0px 0px 4px 0px #0000001A',
                              }}
                            >
                              {userChangePictureFiles.length > 0 ? (
                                <>
                                  <Box>
                                    <ul>
                                      {userChangePictureFiles.map((file, index) => (
                                        <li
                                          key={index}
                                          className="mb-4 flex justify-center items-center"
                                          style={{ position: 'relative' }}
                                        >
                                          <img
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                            style={{
                                              maxHeight: 300,
                                            }}
                                          />
                                          <IconButton
                                            onClick={() => handleRemoveFile(index)}
                                            style={{
                                              position: 'absolute',
                                              top: '0',
                                              right: '0',
                                              fontSize: '32px',
                                              color: isDarkMode ? 'white' : 'black',
                                            }}
                                          >
                                            <CancelRoundedIcon
                                              style={{
                                                fontSize: '32px',
                                                color: isDarkMode ? '#fff' : '#b31b1b',
                                              }}
                                            />
                                          </IconButton>
                                        </li>
                                      ))}
                                    </ul>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      !user.picture
                                        ? isDarkMode
                                          ? accountIconDM
                                          : accountIcon
                                        : baseURLImage + user.picture
                                    }
                                    style={{
                                      width: '218px',
                                      height: '218px',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                    }}
                                    alt="Account Icon"
                                  />
                                </>
                              )}
                              <Input
                                type="file"
                                inputProps={{ multiple: true }}
                                onChange={handleFileInputChange}
                                style={{ display: 'none' }}
                                id="fileInput"
                              />
                              <label htmlFor="fileInput">
                                <CustomButton sx={{ width: 250, mt: 4 }} variant="contained" component="span">
                                  <img
                                    src={isDarkMode ? fileIconDM : fileIcon}
                                    alt="Camera Icon"
                                    style={{ width: '22px' }}
                                  />
                                  <div
                                    style={{ flex: 1, textAlign: 'center', color: isDarkMode ? 'white' : null }}
                                    className="mt-2"
                                  >
                                    {t('change picture')}
                                  </div>
                                </CustomButton>
                              </label>
                            </Card>
                          </div>
                          <div>
                            <div className="mt-4">
                              <Typography fontSize={18}>{t('User ID')}</Typography>
                              <div className="flex flex-row justify-between">
                                <CustomInputs
                                  placeholder={t('First Name')}
                                  value={firstName}
                                  variant="outlined"
                                  type="text"
                                  sx={{ mt: 2 }}
                                  style={{ width: '49%' }}
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setFirstNameError('');
                                  }}
                                  helperText={firstNameError ? firstNameError : ' '}
                                  error={!!firstNameError}
                                />
                                <CustomInputs
                                  placeholder={t('Last Name')}
                                  value={lastName}
                                  variant="outlined"
                                  type="text"
                                  sx={{ mt: 2 }}
                                  style={{ width: '49%' }}
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                    setLastNameError('');
                                  }}
                                  helperText={lastNameError ? lastNameError : ' '}
                                  error={!!lastNameError}
                                />
                              </div>
                            </div>
                            <Typography fontSize={18}>{t('Phone no.')}</Typography>
                            <CustomTelInputs
                              value={phone}
                              placeholder={t('Phone no. with country code')}
                              variant="outlined"
                              defaultCountry={billingAddress?.country}
                              sx={{ mt: 2 }}
                              style={{ maxWidth: 788 }}
                              onChange={(phone) => {
                                setPhone(phone);
                                setPhoneError('');
                              }}
                              helperText={phoneError ? phoneError : ' '}
                              error={!!phoneError}
                            />
                            <Typography fontSize={18}>{t('E-mail Address')}</Typography>
                            <CustomInputs
                              placeholder={t('E-mail Address')}
                              value={email}
                              variant="outlined"
                              type="email"
                              required
                              sx={{ mt: 2 }}
                              style={{
                                width: '100%',
                                maxWidth: 788,
                              }}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError('');
                              }}
                              helperText={emailError || emailErrorExists ? emailError || emailErrorExists : ' '}
                              error={!!emailError || !!emailErrorExists}
                            />
                            <div>
                              <CustomButton
                                onClick={() => setIsShowChangePassword(true)}
                                sx={{ width: 250 }}
                                variant="contained"
                              >
                                <Typography fontSize={20} noWrap>
                                  {t('change pasword')}
                                </Typography>
                              </CustomButton>
                            </div>
                            <div className="mt-4">
                              <Typography fontSize={18}>{t('Billing Address')}</Typography>
                              <AddressFields
                                values={billingAddress}
                                onChange={handleBillingAddressChange}
                                errors={billingErrors}
                                countriesList={countriesList}
                              />
                              <FormControlLabel
                                label="Shipping address same as Billing address"
                                control={
                                  <Checkbox
                                    checked={selectSameAddress}
                                    onChange={(e) => {
                                      setSelectSameAddress(e.target.checked);
                                    }}
                                  />
                                }
                              />
                              {!selectSameAddress && (
                                <>
                                  <Typography fontSize={18}>{t('Shipping Address')}</Typography>
                                  <AddressFields
                                    values={shippingAddress}
                                    onChange={handleShippingAddressChange}
                                    errors={shippingErrors}
                                    countriesList={countriesList}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="mt-4 flex justify-center">
                            <CustomButton
                              onClick={handleChangeUserData}
                              sx={{ width: 250, color: isDarkMode ? 'white' : null }}
                              variant="contained"
                              disabled={loadingChangeUserButton}
                            >
                              <LoadingIndicator isLoading={loadingChangeUserButton} value={<>{t('save changes')}</>} />
                            </CustomButton>
                          </div>
                          {user?.verification !== 'verified' && (
                            <div className="mb-8 mt-20 flex flex-col justify-center items-center">
                              <Typography fontSize={18}>
                                {t(
                                  user?.verification === 'unverified'
                                    ? 'Become a verified seller on Outbidda!'
                                    : 'Your verification request is being processed!'
                                )}
                              </Typography>
                              {user?.verification === 'unverified' && (
                                <Link component="button" underline="always" onClick={() => setVerificationDialog(true)}>
                                  Apply today
                                </Link>
                              )}
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {isShowChangePassword && (
          <UserChangePassword
            onClose={() => {
              setIsShowChangePassword(false);
            }}
          />
        )}
        {isShowEmailMessage && (
          <FormTemplate
            open={isShowEmailMessage}
            onClose={() => {
              setIsShowEmailMessage(false);
            }}
            title="Please verify your email address through the verification link sent to your mail."
          />
        )}
        {(isShowBillignItemForm || isShowPayoutsItemForm) && (
          <UnfulfilledItemForm
            onClose={() => {
              setIsShowBillignItemForm(false);
              setIsShowPayoutsItemForm(false);
            }}
            productItem={isShowBillignItemForm ? userBillingOrderSelected?.order : userPayoutsOrderSelected}
            orderItem={isShowBillignItemForm ? userBillingOrderSelected?.order : userPayoutsOrderSelected}
            customer={isShowBillignItemForm ? userBillingOrderSelected?.customer : userPayoutsOrderSelected}
            amountValue={
              isShowBillignItemForm
                ? `${userBillingOrderSelected?.customer?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                    userBillingOrderSelected?.customer?.converter?.price
                  )}`
                : PayoutsCalculateAndFormatAmount(userPayoutsOrderSelected)
            }
            statusValue={
              isShowBillignItemForm
                ? userBillingOrderSelected?.customer?.status === 'pending'
                  ? 'Payment processing'
                  : 'Paid'
                : null
            }
            isFulfilment={false}
          />
        )}
        {verificationDialog && <VerificationForm onClose={() => setVerificationDialog(false)} />}
      </Box>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ProfileComponent;
