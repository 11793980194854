import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';

import axios from '../../config/axiosConfig';

import { ReviewListComponent } from '../review/ReviewComponent';

export function ProfileReviewComponent({ userId, showSnackbar }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReviewsData = useCallback(async () => {
    try {
      const response = await axios.get(`review/${userId}`);

      setReviews(response?.data?.reviews);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchReviewsData();
  }, [fetchReviewsData]);

  return (
    <>
      {!loading && reviews.length === 0 ? (
        <div>None</div>
      ) : (
        <Box
          sx={{
            marginX: 'auto',
            width: '90%',
          }}
        >
          <ReviewListComponent reviews={reviews} userId={userId} loading={loading} showSnackbar={showSnackbar} />
        </Box>
      )}
    </>
  );
}
