import React, { useState } from 'react';
import { useMediaQueries } from './js/useMediaQueries';
import { ClickAwayListener, Tooltip } from '@mui/material';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

function TooltipButton({ title, ...customStyle }) {
  const { isLgScreen } = useMediaQueries();
  const [open, setOpen] = useState(false);

  return (
    <div>
      {!isLgScreen ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => {
              setOpen(false);
            }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<span style={{ whiteSpace: 'pre-line' }}>{title}</span>}
          >
            <HelpOutlineOutlinedIcon
              onClick={() => {
                setOpen(true);
              }}
              fontSize="medium"
              style={{
                marginBottom: customStyle.marginBottom || 20,
                marginLeft: 5,
                ...customStyle,
              }}
            />
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip
          title={<span style={{ whiteSpace: 'pre-line' }}>{title}</span>}
          arrow
          placement="top"
          onClick={() => {
            setOpen(true);
          }}
        >
          <HelpOutlineOutlinedIcon
            fontSize="medium"
            style={{
              marginBottom: customStyle.marginBottom || 20,
              marginLeft: 5,
              ...customStyle,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default TooltipButton;
