import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQueries } from './js/useMediaQueries';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import { baseURLImage } from '../constants';
import { Card, CardContent, Typography, Skeleton } from '@mui/material';
import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

function BannerComponent({ data, delay, isLoading }) {
  const { t } = useTranslation();
  const { isSgMaxScreen } = useMediaQueries();

  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = useCallback(() => {
    if (swiperRef.current) {
      setCurrentIndex(swiperRef.current.activeIndex);
    }
  }, []);

  const calculateSlidesPerView = () => {
    if (!isSgMaxScreen) return 1;
    else {
      const screenWidth = window.innerWidth;
      const offset = (screenWidth * data.length) / 100;
      const slidesPerView = (screenWidth - offset) / 339;
      return slidesPerView;
    }
  };

  const swiperParams = {
    slidesPerView: calculateSlidesPerView(),
    autoplay: {
      delay: delay || 5000,
      disableOnInteraction: true,
    },
    centeredSlides: true,
    spaceBetween: isSgMaxScreen ? 10 : 20,
    grabCursor: true,
    modules: [Autoplay, Navigation],
    slideToClickedSlide: false,
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        style={{ overflowY: 'visible', padding: 0, overflow: isHovered && !isSgMaxScreen ? 'visible' : 'hidden' }}
        {...swiperParams}
        // events
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={handleSlideChange}
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            className="justify-center pb-2 pt-1 h-fit"
            style={{
              display: 'flex',
              minWidth: isSgMaxScreen ? 'unset' : '100%',
              maxWidth: isSgMaxScreen ? 339 : 'unset',
              width: isSgMaxScreen ? 339 : 'unset',
              opacity: currentIndex === index || isSgMaxScreen ? 100 : 0,
              transition: '0.5s',
            }}
          >
            <Card
              sx={{ minWidth: '100%', maxWidth: '100%' }}
              style={{
                borderRadius: 20,
                width: isSgMaxScreen ? 339 : '100%',
                height: isSgMaxScreen ? 358 : 330,
                boxShadow: 'none',
                background: `center / cover no-repeat url('${baseURLImage + item?.photo}')`,
              }}
            >
              {isLoading ? (
                <Skeleton sx={{ height: isSgMaxScreen ? 358 : 330 }} animation="wave" variant="rectangular" />
              ) : (
                <CardContent
                  className={`flex justify-${(index + 1) % 2 === 0 ? 'end' : 'start'} items-center`}
                  style={{
                    boxShadow: 'none',
                    height: '100%',
                    padding: '0 5.5%',
                    paddingTop: isSgMaxScreen ? 70 : 0,
                    color: '#fff',
                    background: '#00000059',
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div
                    className={`flex flex-col justify-${isSgMaxScreen ? 'start' : 'between'} items-${isSgMaxScreen ? 'center' : (index + 1) % 2 === 0 ? 'end' : 'start'}`}
                    style={{
                      width: isSgMaxScreen ? '100%' : '27.5%',
                      height: isSgMaxScreen ? '100%' : 'unset',
                      position: 'relative',
                    }}
                  >
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        fontSize: isSgMaxScreen ? 14 : 16,
                        fontWeight: 400,
                        textAlign: isSgMaxScreen ? 'center' : 'left',
                      }}
                    >
                      {t(item?.caption?.en)}
                    </Typography>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        fontSize: isSgMaxScreen ? 24 : 30,
                        fontWeight: 600,
                        textAlign: isSgMaxScreen ? 'center' : 'left',
                        margin: isSgMaxScreen ? '8px 0' : 0,
                      }}
                    >
                      {t(item?.title?.en)}
                    </Typography>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: isSgMaxScreen ? 16 : 20,
                        fontWeight: 300,
                        textAlign: isSgMaxScreen ? 'center' : 'left',
                      }}
                    >
                      {t(item?.description?.en)}
                    </Typography>
                    <CustomButton
                      href={item?.link}
                      sx={{
                        boxShadow: '0',
                        border: '1px solid #fff',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        marginTop: isSgMaxScreen ? 0 : 3,
                      }}
                      style={{
                        width: isSgMaxScreen ? '45%' : 212,
                        height: isSgMaxScreen ? 37 : 45,
                        minWidth: 'unset',
                        borderRadius: 50,
                        position: isSgMaxScreen ? 'absolute' : 'unset',
                        bottom: isSgMaxScreen ? 130 : 'unset',
                      }}
                    >
                      <Typography fontSize={isSgMaxScreen ? 16 : 20} fontWeight={400} color={'#fff'}>
                        {t(item?.button?.en)}
                      </Typography>
                    </CustomButton>
                  </div>
                </CardContent>
              )}
            </Card>
          </SwiperSlide>
        ))}
        {/* Custom navigation buttons */}
        {!isSgMaxScreen && (
          <button
            ref={prevRef}
            style={{
              position: 'absolute',
              top: 'calc(50% - 16px)',
              left: '-16px',
              width: 32,
              height: 32,
              backgroundColor: '#fff',
              borderRadius: '50%',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              zIndex: 1,
              opacity: isHovered ? 100 : 0,
              transition: '0.2s',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              style={{
                border: 'solid black',
                borderWidth: '0 2px 2px 0',
                display: 'inline-block',
                padding: 3,
                transform: 'rotate(135deg)',
                WebkitTransform: 'rotate(135deg)',
                marginLeft: 2,
              }}
            ></div>
          </button>
        )}
        {!isSgMaxScreen && (
          <button
            ref={nextRef}
            style={{
              position: 'absolute',
              top: 'calc(50% - 16px)',
              right: '-16px',
              width: 32,
              height: 32,
              backgroundColor: '#fff',
              borderRadius: '50%',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              zIndex: 1,
              opacity: isHovered ? 100 : 0,
              transition: '0.2s',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              style={{
                border: 'solid black',
                borderWidth: '0 2px 2px 0',
                display: 'inline-block',
                padding: 3,
                transform: 'rotate(-45deg)',
                WebkitTransform: 'rotate(-45deg)',
                marginRight: 2,
              }}
            ></div>
          </button>
        )}
      </Swiper>
    </>
  );
}

export default BannerComponent;
