import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

function TabsComponent({ tabs, selectedTab, handleChange, isLastBetween, titleSize }) {
  const { t } = useTranslation();
  const lastIndex = tabs.length - 1;

  const calculateMarginLeft = (index, lastIndex, isLastBetween) => {
    if (isLastBetween) {
      if (index < lastIndex) return index > 0 ? titleSize / 8 || 3 : 0;
      else return 'auto';
    } else return index > 0 ? titleSize / 8 || 3 : 0;
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      TabIndicatorProps={{
        style: { backgroundColor: isDarkMode ? 'white' : 'black' },
      }}
      variant="scrollable"
      scrollButtons={false}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={t(tab.label)}
          style={{ color: isDarkMode ? 'white' : 'black' }}
          sx={{
            fontWeight: selectedTab === index ? 600 : 200,
            textTransform: 'capitalize',
            fontSize: titleSize || '24px',
            padding: 0,
            marginLeft: calculateMarginLeft(index, lastIndex, isLastBetween),
          }}
        />
      ))}
    </Tabs>
  );
}

export default TabsComponent;
