import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, TextField, Select, Autocomplete, Accordion, Switch } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

// breakpoints size in pixels
const StyledCustomButton = styled(Button)(({ theme, fontSize }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '74vw',
  textTransform: 'none',
  fontSize: fontSize || '20px',
  borderRadius: '0.75rem',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  fontWeight: 400,
  border: '2px solid #ffffff',
  backgroundColor: 'transparent',
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  [theme.breakpoints.up(375)]: {
    width: '74vw',
  },
  [theme.breakpoints.up(435)]: {
    width: '19rem',
  },
  [theme.breakpoints.up(768)]: {
    width: '22rem',
  },
}));

const StyledCustomInputs = styled(TextField)(({ theme }) => ({
  width: '65vw',
  borderRadius: '0.75rem',
  background: theme.palette.mode === 'dark' ? 'transparent' : '#FAFAFA',
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6c25be',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
  '& .MuiFormHelperText-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    background: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 5,
    margin: 0,
  },
  '& .MuiInputBase-root': {
    borderRadius: '0.75rem',
  },
  '& .MuiOutlinedInput-input': {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
  [theme.breakpoints.up(375)]: {
    width: '70vw',
  },
  [theme.breakpoints.up(435)]: {
    width: '19rem',
  },
  [theme.breakpoints.up(768)]: {
    width: '26.25rem',
  },
}));

const StyledCustomTelInputs = styled(MuiTelInput)(({ theme }) => ({
  width: '100%',
  borderRadius: '0.75rem',
  background: theme.palette.mode === 'dark' ? 'transparent' : '#FAFAFA',
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6c25be',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
  '& .MuiFormHelperText-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    background: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 5,
    margin: 0,
  },
  '& .MuiInputBase-root': {
    borderRadius: '0.75rem',
  },
  [theme.breakpoints.up(375)]: {
    width: '100%',
  },
  [theme.breakpoints.up(435)]: {
    width: '85vw',
  },
  [theme.breakpoints.up(768)]: {
    width: '92vw',
  },
}));

const StyledCustomSelect = styled(Select)(({ theme }) => ({
  width: 312,
  background: theme.palette.mode === 'dark' ? 'transparent' : '#FAFAFA',
  borderRadius: '0.75rem',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiSelect-select': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6c25be',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
  '& .MuiFormHelperText-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    background: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 5,
    margin: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B2BAC2',
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
  [theme.breakpoints.up('sm')]: {
    width: '26.25rem',
  },
}));

const StyledCustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '65vw',
  background: theme.palette.mode === 'dark' ? 'transparent' : '#FAFAFA',
  borderRadius: '0.75rem',
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6c25be',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
  '& .MuiFormHelperText-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    background: theme.palette.mode === 'dark' ? 'transparent' : 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 5,
    margin: 0,
    borderRadius: '0rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B2BAC2',
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
    {
      borderRadius: '1rem',
      paddingRight: 16,
    },
  [theme.breakpoints.up(375)]: {
    width: '70vw',
  },
  [theme.breakpoints.up(435)]: {
    width: '19rem',
  },
  [theme.breakpoints.up(768)]: {
    width: '26.25rem',
  },
}));

const StyledCustomAccordion = styled(Accordion)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'initial',
}));

const StyledCustomIosSwitch = styled(Switch)(({ theme }) => ({
  width: '42px',
  height: '26px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '22px',
    height: '22px',
  },
  '& .MuiSwitch-track': {
    borderRadius: '13px',
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StyledCustomIosAllSwitch = styled(Switch)(({ theme, status }) => ({
  width: '42px',
  height: '26px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: status === 'middle' ? 'translateX(8px)' : status === 'on' ? 'translateX(16px)' : 'translateX(0px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: status === 'on' ? '#2ECA45' : status === 'middle' ? '#FFA500' : '#E9E9EA',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '22px',
    height: '22px',
  },
  '& .MuiSwitch-track': {
    borderRadius: '13px',
    backgroundColor: status === 'on' ? '#2ECA45' : status === 'middle' ? '#FFA500' : '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CustomButton = ({ children, ...props }) => {
  return <StyledCustomButton {...props}>{children}</StyledCustomButton>;
};

const CustomInputs = (props) => {
  return <StyledCustomInputs {...props} />;
};
const CustomTelInputs = (props) => {
  return <StyledCustomTelInputs {...props} />;
};

const CustomSelect = (props) => {
  return <StyledCustomSelect {...props} />;
};

const CustomAutocomplete = (props) => {
  return <StyledCustomAutocomplete {...props} />;
};

const CustomAccordion = (props) => {
  return <StyledCustomAccordion {...props} />;
};

const CustomIosSwitch = (props) => {
  return <StyledCustomIosSwitch {...props} />;
};

const CustomIosAllSwitch = (props) => {
  return <StyledCustomIosAllSwitch {...props} />;
};

export {
  CustomButton,
  CustomInputs,
  CustomTelInputs,
  CustomSelect,
  CustomAutocomplete,
  CustomAccordion,
  CustomIosSwitch,
  CustomIosAllSwitch,
};
