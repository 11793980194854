import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, Skeleton } from '@mui/material';

const renderSkeletonCells = (columnHeaders) => {
  return Array.from({ length: columnHeaders?.length }, (_, index) => (
    <TableCell key={index} style={{ padding: 19.5 }}>
      <Skeleton variant="text" width="100%" height={20} animation="wave" />
    </TableCell>
  ));
};

const ItemsTableComponent = ({
  data,
  onClickHandler,
  isLoading,
  isCursorPointer,
  columnHeaders,
  rowData,
  statusColors,
}) => {
  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <TableContainer className="mt-4 md:px-4">
        <Table>
          <TableHead>
            <TableRow
              style={{
                borderBottom: `2px solid ${isDarkMode ? 'rgba(81, 81, 81, 1)' : '#FFFEFE'}`,
              }}
            >
              {columnHeaders?.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: 300,
                    fontSize: 20,
                    textAlign: 'center',
                  }}
                >
                  {isLoading ? (
                    <Skeleton sx={{ mb: 0.5 }} height={20} animation="wave" variant="text" />
                  ) : (
                    <>{header}</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 && !isLoading && (
              <TableRow>
                <TableCell colSpan={columnHeaders?.length}>
                  <Typography component="div" className="text-center" fontSize={20} fontWeight={600}>
                    None
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {data?.map((item, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: isDarkMode ? 'transparent' : '#fff',
                  borderRadius: '20px',
                  borderBottom: `20px solid ${isDarkMode ? 'transparent' : '#FFFEFE'}`,
                  boxShadow: '0px 0px 4px 0px #0000001A',
                  cursor: isCursorPointer && 'pointer',
                }}
                disabled={isLoading}
                onClick={() => {
                  !isLoading && onClickHandler(item);
                }}
              >
                {isLoading
                  ? renderSkeletonCells(columnHeaders)
                  : rowData?.[index]?.map((cellData, cellIndex) => (
                      <TableCell key={cellIndex} sx={{ fontSize: 18, textAlign: 'center' }}>
                        <Typography
                          component="div"
                          fontSize={18}
                          noWrap
                          style={{
                            color: cellIndex === columnHeaders.indexOf('Status') ? statusColors?.[index] : null,
                          }}
                        >
                          {cellData}
                        </Typography>
                      </TableCell>
                    ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ItemsTableComponent;
