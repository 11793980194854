import React from 'react';
import { Modal, Zoom, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

function ImagePreview({ open, onClose, imageUrl }) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition className="flex justify-center items-center">
      <Zoom in={open} style={{ outline: 'none' }}>
        <div
          style={{
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'relative',
            maxWidth: '90%',
          }}
          className="m-auto"
        >
          <img
            src={imageUrl}
            alt="Images"
            style={{
              borderRadius: '10px',
              boxShadow: '0px 4px 4px 0px #00000040',
            }}
          />
          <IconButton
            onClick={onClose}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              fontSize: '32px',
              color: 'black',
            }}
          >
            <CancelRoundedIcon style={{ fontSize: '32px', color: '#b31b1b' }} />
          </IconButton>
        </div>
      </Zoom>
    </Modal>
  );
}

export default ImagePreview;
