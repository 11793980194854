import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TimerWrap = styled(Box)(({ theme, size }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: size || '55px',
  height: size || '55px',
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f9f9f9',
  borderRadius: '5px',
  boxShadow: '0px 1px 4px 0px #00000033',
}));

const RaffleTimer = ({ targetDate, spacingBetweenBlock, boxSize }) => {
  const calculateTimeLeft = () => {
    const now = Date.now();
    const difference = new Date(targetDate).getTime() - now;

    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isActive, setIsActive] = useState(true);

  const formatNumber = (num) => String(num).padStart(2, '0');
  const timeValues = [
    { label: 'DAY', value: timeLeft.days },
    { label: 'HR', value: timeLeft.hours },
    { label: 'MIN', value: timeLeft.minutes },
    { label: 'SEC', value: timeLeft.seconds },
  ];

  useEffect(() => {
    if (!isActive) return;

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (newTimeLeft.days === 0 && newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
        setIsActive(false);
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isActive, targetDate]);

  return (
    <Box display="flex" gap={spacingBetweenBlock || 1}>
      {timeValues.map((time, index) => (
        <TimerWrap key={`raffleTimerKey${index}`} size={boxSize}>
          <Typography fontSize="16px" fontWeight="400">
            {formatNumber(time.value)}
          </Typography>
          <Typography fontSize="10px" fontWeight="300" sx={{ textTransform: 'uppercase' }}>
            {time.label}
          </Typography>
        </TimerWrap>
      ))}
    </Box>
  );
};

export default RaffleTimer;
