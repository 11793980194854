export const getMyProductsChipColor = (status) => {
  switch (status) {
    case 'rejected':
      return { label: 'Rejected', color: '#FF5733' };
    case 'canceled':
      return { label: 'Cancelled', color: '#FF0000' };
    case 'pending':
      return { label: 'Pending', color: '#808080' };
    case 'accepted':
      return { label: 'Active', color: '#FFFFFF' };
    case 'unfulfilled':
      return { label: 'Unfulfilled', color: '#FFA500' };
    case 'fulfilled':
      return { label: 'Fulfilled', color: '#0000FF' };
    case 'pay':
      return { label: 'Received', color: '#84d996' };
    case 'paid':
      return { label: 'Paid', color: '#008000' };
    case 'disputed':
      return { label: 'Disputed', color: '#8B8000' };
    default:
      return { label: status, color: '#0000FF' };
  }
};

export const getMyShoppingItemsChipColor = (status) => {
  switch (status) {
    case 'rejected':
      return { label: 'Rejected', color: '#FF0000' };
    case 'pending':
      return { label: 'Pending', color: '#808080' };
    case 'accepted':
      return { label: 'Active', color: '#84d996' };
    case 'unfulfilled':
      return { label: 'Winner', color: '#0000FF' };
    case 'fulfilled':
      return { label: 'Sent', color: '#FFA500' };
    case 'finished':
      return { label: 'Received', color: '#008000' };
    case 'disputed':
      return { label: 'Disputed', color: '#8B8000' };
    default:
      return { label: status, color: '#0000FF' };
  }
};
