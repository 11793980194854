import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../../../config/axiosConfig';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Skeleton } from '@mui/material';

import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import TooltipButton from '../../../utils/ui/TooltipComponent.jsx';
import FormTemplate from '../../../utils/ui/forms/FormTemplate.jsx';
import { CustomPopover } from './StyledNavbarComponent.jsx';
import { CustomButton } from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';

import { selectUser, setUserCurrency, setUserAbbrName } from '../../../store/features/userSlice';

const SelectCurrency = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [currency, setCurrency] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const getAbbrNameForSelectedCurrency = (selectedCurrencyId, currencyArray) => {
    const selectedCurrency = currencyArray.find((currency) => currency._id === selectedCurrencyId);
    if (selectedCurrency) return selectedCurrency.abbr_name;
    return null;
  };

  const [selectedCurrency, setSelectedCurrency] = useState({
    id: user.currency,
    abbr_name: getAbbrNameForSelectedCurrency(user.currency, currency),
  });
  const [userPopover, setUserPopover] = useState(null);
  const [openSimpleForm, setOpenSimpleForm] = useState(false);

  const isOpenCurrencyPopover = Boolean(userPopover);
  const userPopoverId = isOpenCurrencyPopover ? 'popover-account' : undefined;

  const handleSelectCurrency = (id, abbr_name) => {
    setSelectedCurrency({
      id: id,
      abbr_name: abbr_name,
    });
    axios
      .put('user/me', {
        currency: id,
      })
      .then(() => {
        dispatch(setUserCurrency(id));
        dispatch(setUserAbbrName(abbr_name));
        setOpenSimpleForm(true);
      })
      .catch((error) => {
        showSnackbar(error);
      });

    setUserPopover(null);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoadingData(true);
      const response = await axios.get('currency');
      setCurrency(response.data);
    } catch (error) {
      showSnackbarRef.current(error);
    } finally {
      setLoadingData(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setSelectedCurrency((prevSelectedCurrency) => {
      const abbr_name = getAbbrNameForSelectedCurrency(prevSelectedCurrency.id, currency);
      if (abbr_name) dispatch(setUserAbbrName(abbr_name));
      return {
        ...prevSelectedCurrency,
        abbr_name,
      };
    });
  }, [currency, selectedCurrency.id, dispatch]);

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <div className="flex flex-row items-center">
        <CustomButton
          aria-describedby={userPopoverId}
          onClick={(event) => setUserPopover(event.currentTarget)}
          sx={{
            height: 32,
            borderRadius: userPopover ? '0.75rem 0.75rem 0 0' : null,
            boxShadow: '0',
            border: `1px solid #${isDarkMode ? 'fff' : '00000080'}`,
            borderBottom: userPopover ? '0' : null,
            '&:hover': {
              backgroundColor: isDarkMode ? '#000' : 'rgba(255, 255, 255, 1)',
            },
          }}
          style={{ width: 48, height: 26, minWidth: 'unset', padding: '2px 0 0 0' }}
        >
          {loadingData ? (
            <Skeleton
              animation="wave"
              height={15}
              width="100%"
              variant="text"
              sx={{
                fontSize: '10rem',
              }}
            />
          ) : (
            <>
              <Typography fontSize={14}>{selectedCurrency.abbr_name}</Typography>
            </>
          )}
        </CustomButton>
        <TooltipButton
          marginBottom={0}
          title={t(
            'Converted amount displayed in your selected currency is a representation of an average market share of correlation between your selected currency and original product currency price set by seller. Real payment amount can vary, please also consider services fees, double-conversion or other operations depending on your payment method and currency.'
          )}
        />
      </div>
      <CustomPopover
        id={userPopoverId}
        open={isOpenCurrencyPopover}
        anchorEl={userPopover}
        onClose={() => setUserPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '0 0 0.75rem 0.75rem',
            paddingTop: 0,
            width: 48,
            border: `1px solid #${isDarkMode ? 'fff' : '00000080'}`,
            borderTop: '0',
            backgroundColor: isDarkMode ? '#1e1e1e' : 'rgba(255, 255, 255, 1)',
          },
        }}
        padding={'4px 9px'}
      >
        {currency.map((currency, index) => (
          <button
            className="flex flex-row items-center pt-2"
            key={index}
            onClick={() => handleSelectCurrency(currency._id, currency.abbr_name, currency.icon)}
          >
            <Typography fontSize={14}>{currency.abbr_name}</Typography>
          </button>
        ))}
      </CustomPopover>
      <FormTemplate
        open={openSimpleForm}
        onClose={() => setOpenSimpleForm(false)}
        dialogTitle={
          <>
            <Typography component="div" mt={2} align="center" variant="subtitle1">
              {t(
                'Converted amount displayed in your selected currency is a representation of an average market share of correlation between your selected currency and original product currency price set by seller. Real payment amount can vary, please also consider services fees, double-conversion or other operations depending on your payment method and currency'
              )}
            </Typography>
          </>
        }
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default SelectCurrency;
