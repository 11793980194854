import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Rating, LinearProgress, Avatar } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import axios from '../../config/axiosConfig';
import { baseURLImage } from '../../utils/constants.js';

import { ReviewListComponent } from '../review/ReviewComponent.jsx';
import NewReviewModelComponent from './NewReviewModelComponent.jsx';

export function UserReviewComponent({ userId, authUser, showSnackbar, userAverageStars = 0 }) {
  const [reviews, setReviews] = useState([]);
  const [totalReviewsCount, setTotalReviewsCount] = useState(0);
  const [starCount, setStarCount] = useState([
    { star: 5, total: 0 },
    { star: 4, total: 0 },
    { star: 3, total: 0 },
    { star: 2, total: 0 },
    { star: 1, total: 0 },
  ]);
  const [unreviewedProduct, setUnreviewedProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modelOpen, setModelOpen] = useState(false);

  const handleModelOpen = () => setModelOpen(true);
  const handleModelClose = () => setModelOpen(false);
  const addNewReview = (review) => setReviews([...reviews, review]);
  const filterUnreviewedProduct = (up) => setUnreviewedProduct(unreviewedProduct.filter((el) => el._id !== up._id));

  const fetchReviewsData = useCallback(async () => {
    try {
      const response = await axios.get(`review/${userId}`);

      setReviews(response?.data?.reviews);
      setTotalReviewsCount(response?.data?.totalReviews);
      setStarCount(response?.data.starCount.sort((a, b) => b.star - a.star));
    } catch (error) {
      showSnackbar(error);
    } finally {
      setLoading(false);
    }
  }, [userId, showSnackbar]);

  const fetchUnreviewedItem = useCallback(async () => {
    try {
      const response = await axios.get(`product/by-user/unreviewed/${userId}`);

      setUnreviewedProduct(response?.data);
    } catch (error) {}
  }, [userId]);

  useEffect(() => {
    if (authUser) {
      fetchUnreviewedItem();
    }
    fetchReviewsData();
  }, [fetchReviewsData, fetchUnreviewedItem, authUser]);

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      sx={{ width: '90%', marginX: 'auto', flexDirection: { xs: 'column', sm: 'row' } }}
    >
      <Box display="flex" width="200px" mr="20px">
        <Box>
          <Typography variant="h6">Reviews ({totalReviewsCount})</Typography>
          <Typography sx={{ fontSize: 20, mt: 2 }}>Overall rating</Typography>
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <Typography variant="h4" fontWeight="500">
              {userAverageStars.toFixed(1)}
            </Typography>
            <Rating value={userAverageStars} precision={0.5} readOnly />
          </Box>

          <Box mt={2}>
            {starCount.map((item) => (
              <Box display="flex" alignItems="center" key={item.star}>
                <Typography mr={item.star === 1 ? '2px' : '0px'}>{item.star}</Typography>
                <StarIcon sx={{ color: '#faaf00' }} />
                <LinearProgress
                  variant="determinate"
                  value={(item.total / totalReviewsCount) * 100}
                  sx={{
                    flexGrow: 1,
                    mr: 1,
                    borderRadius: 1,
                    backgroundColor: '#d3d3d3',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#6F6F6F',
                    },
                  }}
                />
                <Typography sx={{ textDecoration: `${item.total !== 0 ? 'underline' : 'none'}` }}>
                  {item.total}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box display="flex" sx={{ width: 'calc(100% - 240px)', minWidth: '430px' }}>
        <Box width="100%">
          {!!authUser && unreviewedProduct.length > 0 && (
            <Box
              display="flex"
              mb="20px"
              alignItems="center"
              sx={{
                width: '100%',
                padding: '10px',
                borderRadius: 2,
                boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.08)',
                cursor: 'pointer',
              }}
              onClick={handleModelOpen}
            >
              <Avatar
                src={baseURLImage + authUser?.picture}
                alt={authUser?.firstName}
                sx={{ width: 35, height: 35, marginRight: '10px' }}
              />
              <Typography fontWeight={200}>Give a review....</Typography>
            </Box>
          )}
          <ReviewListComponent reviews={reviews} userId={authUser?._id} loading={loading} showSnackbar={showSnackbar} />
        </Box>
      </Box>
      {!!authUser && unreviewedProduct.length > 0 && modelOpen && (
        <NewReviewModelComponent
          open={modelOpen}
          authUser={authUser}
          handleClose={handleModelClose}
          unreviewedProduct={unreviewedProduct}
          filterUnreviewedProduct={filterUnreviewedProduct}
          addNewReview={addNewReview}
          showSnackbar={showSnackbar}
        />
      )}
    </Box>
  );
}
