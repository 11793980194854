import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../config/axiosConfig';

import { Typography, List, ListItem, ListItemText } from '@mui/material';

import { CustomButton } from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';
import FormTemplate from './FormTemplate.jsx';
import LoadingIndicator from '../../../utils/ui/LoadingIndicator.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils.js';

import verificationIcon from '../../../assets/icons/verificationIcon.svg';

const VerificationForm = ({ onClose }) => {
  const { t } = useTranslation();

  const { showSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const [verificationProcessing, setVerificationProcessing] = useState(false);
  const [successState, setSuccessState] = useState(false);

  const handleVerification = async () => {
    try {
      setVerificationProcessing(true);
      const user = await axios.put('user/me', {
        verification: 'pending',
      });
      setSuccessState(true);
      dispatch(setUser(user?.data));
    } catch (error) {
      showSnackbarRef.current(error);
    } finally {
      setVerificationProcessing(false);
    }
  };

  const isDarkMode = localStorage.getItem('theme') === 'dark';

  return (
    <>
      <FormTemplate
        open={true}
        onClose={onClose}
        title={successState ? 'Thank You for Applying!' : 'Apply for Your Blue Verification Badge!'}
        dialogContent={
          <>
            <Typography fontSize={16} fontWeight={300} textAlign={'center'}>
              {t(
                successState
                  ? 'Your application for the blue verification badge has been received.'
                  : 'Get verified and stand out with a blue badge next to your username.'
              )}
            </Typography>
            <Typography fontSize={16} fontWeight={300} textAlign={'center'}>
              {t(
                successState
                  ? "Our team will review your request, and you'll hear back from us within 7 business days."
                  : 'The verification badge shows that you are a trusted and authentic member of our community.'
              )}
            </Typography>
            {!successState && (
              <>
                {' '}
                <img
                  src={verificationIcon}
                  alt="Verification Icon"
                  style={{ width: 70, height: 70, margin: '20px 0 30px' }}
                />
                <Typography fontSize={16} fontWeight={400}>
                  {t('Before you apply:')}
                </Typography>
                <List className="flex flex-col items-center" sx={{ listStyleType: 'disc', padding: 0 }}>
                  <ListItem sx={{ padding: 0, textAlign: 'center', width: 'fit-content', display: 'list-item' }}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '16px', fontWeight: 300 }}
                      primary="Ensure your profile is fully completed."
                    />
                  </ListItem>
                  <ListItem sx={{ padding: 0, textAlign: 'center', width: 'fit-content', display: 'list-item' }}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '16px', fontWeight: 300 }}
                      primary="You must have a proven track record of positive interactions."
                    />
                  </ListItem>
                  <ListItem sx={{ padding: 0, textAlign: 'center', width: 'fit-content', display: 'list-item' }}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '16px', fontWeight: 300 }}
                      primary="Your account must be active and in good standing."
                    />
                  </ListItem>
                </List>{' '}
              </>
            )}
            <Typography fontSize={16} fontWeight={300} textAlign={'center'} mt={3}>
              {t(
                successState
                  ? 'In the meantime, keep engaging with the community.'
                  : 'Verification reviews can take up to 7 business days.'
              )}
            </Typography>
            <Typography fontSize={16} fontWeight={300} textAlign={'center'} mt={successState ? 3 : 0}>
              {t(successState ? 'We appreciate your commitment to authenticity!' : 'Ready to enhance your profile?')}
            </Typography>
          </>
        }
        dialogActions={
          <>
            <CustomButton
              variant="contained"
              sx={{ width: '35% !important' }}
              onClick={() => {
                setSuccessState(false);
                onClose();
              }}
            >
              {t(successState ? 'done' : 'cancel')}
            </CustomButton>
            {!successState && (
              <CustomButton
                variant="contained"
                sx={{ width: '35% !important', marginLeft: '25px !important' }}
                onClick={handleVerification}
              >
                <LoadingIndicator
                  isLoading={verificationProcessing}
                  style={{ color: isDarkMode ? 'white' : null }}
                  value={<> {t('apply')}</>}
                />
              </CustomButton>
            )}
          </>
        }
      />
    </>
  );
};

export default VerificationForm;
