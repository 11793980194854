export const termsAndConditionsText = [
  {
    title: 'Terms & Conditions',
    content: [
      'Outbidda, and the following terms and conditions mentioned below, constitute legally binding agreements between you and us, collectively referred to as the "Terms." These Terms govern your utilization of our website, including its subpages and subdomains for both mobile and desktop devices.',
      'Our Terms serve as the guidelines that regulate activities such as browsing, bidding, purchasing, and selling on or via the Outbidda platform. All users engaging in transactions within our online marketplace are required to accept and adhere to these Terms.',
      'To enhance clarity and relevance, we have included the Terms for both Buyer and Seller.',
    ],
  },
  {
    title: 'Buyer Terms',
    content: [
      'To make our online marketplace safe and reliable for all our users, we have some important rules for bidding and buying. We call these the Buyer Terms, which are an integral part of our overall Terms and Conditions. Please note that we have specific Terms and Conditions for both auctions and raffles to ensure a secure and trustworthy experience for all our users.',
    ],
  },
  {
    title: 'Auctions',
  },
  {
    title: 'Bidding :',
    content: [
      'Evaluating Personal Worth:',
      'Each of the distinctive items featured on Outbidda comes with its own remarkable story. Engaging in an online auction revolves around assessing the value an item holds for you individually.',
      'Evaluating Market Worth:',
      'If you prioritize the market value of an item, we recommend seeking guidance from a third-party appraiser. Acquiring items for investment or speculative purposes always carries inherent risks that you should be aware of.',
      'Binding bids:',
      "Each bid or use of the 'buy now' option is considered binding. This means you cannot modify, withdraw, or cancel your bid or 'buy now' selection. Please take extra care when placing bids, particularly in last-minute scenarios, as we cannot assume responsibility for bidding errors. By becoming the highest bidder at the auction's conclusion or by successfully using 'buy now,' you commit to completing the purchase and agree to pay for the item.",
      'Escrow Hold Policy for Bids:',
      "When you place a bid, we temporarily hold the bid amount from your bank account in escrow. This is not a charge, but a validation of your bid. If you win the auction, we will then charge this amount. If you don't win, or if someone outbids you, the amount is released back to your account. This process ensures a fair and secure bidding experience.",
      'Verify Import Regulations and Additional Expenses:',
      'As a buyer, it is your responsibility to verify whether there are any particular regulations or limitations pertaining to importing an item into your country. Additionally, you should determine whether there are any associated expenses linked to these regulations or restrictions. Please be aware that we, as well as the seller, bear no responsibility for these costs. ',
      'Sold ‘as is’:',
      "Each item has been described by the seller on its respective product page. All items are sold 'as is,' and their condition can vary significantly due to factors such as age, prior damage, restoration, repairs, and general wear and tear. It's important to note that these items are seldom in pristine condition, and they are sold in the exact state they are in at the time of sale. Please be aware that the item description and/or images may not provide a comprehensive view of its condition, and colors or shades may appear differently on screen compared to physical inspection. To aid in assessing an item's condition, condition reports may be available. If the seller does not explicitly specify the item's condition, it should not be assumed that the item is new or in like-new condition. It is your responsibility to ensure that you have requested, received, and taken into account any available condition reports.",
      'Closing the auction:',
      'The auction closes on the date shown on our website which is chosen by the seller.',
    ],
  },
  {
    title: 'Raffle',
  },
  {
    title: 'Entry :',
    content: [
      'Seller-Hosted Competition:',
      'The competition is hosted by the seller on the Outbidda platform. For any concerns or questions you can find our contact information on our website.',
      'Age Requirement:',
      'Participants must be 18 years of age or older, with eligibility subject to local laws, which may vary.',
      'The competition:',
      "To enter the competition, you need to select the number of tickets you want, provide an answer to a skill-based question, and complete the payment. Your entry will be considered valid once you've paid and received confirmation from us acknowledging receipt. Please be aware that we cannot take responsibility for incomplete, delayed, damaged, or unreceived entries, regardless of the reason.",
      'Single Answer Selection for Each Order:',
      'You may choose only one answer for each raffle, and this same answer will be applied to all tickets purchased within that raffle.',
      'Entry Compliance and Exclusion',
      'To enter, it is imperative that you adhere to both these terms and conditions and any additional competition prerequisites outlined on the respective competition page of our website. We retain the discretion to disqualify entries that, in our judgment, fail to meet these criteria.',
      'Automatic Entry Cancellation for Payment Reversals:',
      'Should any payment made by you be subject to a chargeback or reversal, your entry will be automatically invalidated.',
      'Closing the Raffle:',
      'The competition closes on the date shown on our website which is chosen by the seller.',
      'Sold ‘as is’:',
      "Each item has been described by the seller on its respective product page. All items are sold 'as is,' and their condition can vary significantly due to factors such as age, prior damage, restoration, repairs, and general wear and tear. It's important to note that these items are seldom in pristine condition, and they are sold in the exact state they are in at the time of sale. Please be aware that the item description and/or images may not provide a comprehensive view of its condition, and colors or shades may appear differently on screen compared to physical inspection. To aid in assessing an item's condition, condition reports may be available. If the seller does not explicitly specify the item's condition, it should not be assumed that the item is new or in like-new condition. It is your responsibility to ensure that you have requested, received, and taken into account any available condition reports.",
      'Evaluating Personal Worth:',
      'Each of the distinctive items featured on Outbidda comes with its own remarkable story. Engaging in an online raffles revolves around assessing the value an item holds for you individually.',
      'Evaluating Market Worth:',
      'If you prioritize the market value of an item, we recommend seeking guidance from a third-party appraiser. Acquiring items for investment or speculative purposes always carries inherent risks that you should be aware of.',
      'Verify Import Regulations and Additional Expenses:',
      'As a buyer, it is your responsibility to verify whether there are any particular regulations or limitations pertaining to importing an item into your country. Additionally, you should determine whether there are any associated expenses linked to these regulations or restrictions. Please be aware that we, as well as the seller, bear no responsibility for these costs. ',
      'Winners:',
      'After the closing of the raffle the Outbidda platform will randomly select the winner from among those who have provided the correct answer to the competition question. The winner will receive notification through the email addresses they provided to us, and prizes will be dispatched to the postal address you furnished. In the event that we are unable to reach the winner using the provided email address or if prize delivery encounters insurmountable difficulties despite our reasonable efforts, the prize will become void, and we retain the right to offer it to an alternate winner. Furthermore, all participants to the raffle will also receive an email notification letting them know that the competition is over and the winner has been selected. ',
    ],
  },
  {
    title: 'Shipping or pickup for Buyer',
    content: [
      'Get in Touch with the Seller:',
      "Once you've completed the payment for the item of an auction or won a raffle, you can communicate with the seller. This allows you to inquire about the item, discuss shipping details, or arrange for pick-up. You can initiate contact with the seller via email. The email of the seller will be displayed in the order confirmation email. ",
      'Verify Your Address:',
      'Please double-check that the address in your account is accurate, as the seller will use this address for shipping your item. Ensure it is a physical address and not a PO box.',
      'Delayed Delivery or Pick-Up:',
      "In the event your item does not arrive as scheduled or the seller fails to make it available for pick-up, please reach out to us and the seller to work together on a resolution. It's important to note that the indicated delivery time is not a legal deadline; therefore, if the item does not arrive on or before that date, the order will not be automatically voided. Cancellation of an order is only an option if you are unable to find a resolution, and you have formally notified the seller of the issue, allowing them a reasonable timeframe to ship or make the item available for pick-up.",
      'Addressing Shipping Issues:',
      'In the event of shipping-related problems, please communicate with the seller to work towards a solution. In certain situations, we may provide assistance to both you and the seller in resolving the matter. To address shipping issues, either the seller, the shipping company, or our team may contact you to request documentation, photographs, or additional information. Your cooperation and prompt provision of such information are crucial for a thorough investigation and resolution of the issue. If, following an investigation by the shipping company, the issue remains unresolved, we will determine an appropriate solution. This may involve options such as canceling the sale and issuing a refund to you or compensating the seller.',
      'Begin by inspecting your item.',
      'Upon receiving your item, take the time to assess its attributes, properties, and functionality. Approach this examination as you would when evaluating a product in a physical store.',
      'Handle with Caution:',
      'Many of the unique items sold on our online marketplace are fragile. Please exercise care when unpacking or inspecting your item, as well as any specialized packaging it may arrive in. Should you decide to refund your order and the item sustains any damage that diminishes its value, you will be responsible for compensating the seller for the damage. This does not apply to damage that was pre-existing or occurred during shipping. ',
      'Assurance of Product Conformity for Sellers:',
      'If you are a consumer in the EU or UK buying from a professional seller, you are entitled to a statutory warranty from the seller. This warranty covers a minimum duration of one year for any item purchased through our online marketplace. For additional information, please refer to our EU & UK Consumer Rights Policy.',
      'Examining Discrepancies:',
      "In cases where the item does not match the seller's description, it is crucial for you to furnish both us and the seller with comprehensive details promptly to facilitate an effective investigation of your claim",
      'Resolving the Issue.',
      'Should the investigation confirm that the item differs from the description, you may be eligible for several remedies. Depending on the situation, options may range from repair or replacement, to a price reduction, or even annulling the sale as a final measure, where the seller may be required to reimburse your losses. Note that some of these remedies are exclusive to consumers purchasing from business entities that are selling through Outbidda.',
      'Cancellation Rights:',
      "As a citizen of the EU or UK, if you purchase an item from a business entity, you have the option to cancel your purchase within 14 days of receiving the item. It's important to inform both the seller and us within this 14-day window. Please be aware that some exceptions exist. Additionally, you will need to arrange and cover the costs for the return shipping or transportation of the item.",
      'Shipping for Returns.',
      "Typically, the responsibility for return shipping expenses falls on you. Additionally, it's your duty to ensure that the item is properly insured during its return journey. If you opt not to insure the item, you might have to bear any loss or damage costs yourself. To minimize damage, we suggest securely packing the item, preferably using the original packaging. Moreover, it's advisable to verify if there are any particular shipping guidelines relevant to your item.",
    ],
  },
  {
    title: 'Your Responsibilities as a Buyer',
    content: [
      'Timely Payment for Your Purchase:',
      "Once you've placed the winning bid or utilized the 'Buy Now' option, it's essential to complete the payment for your item, along with any related charges, within a 3-day timeframe.",
      'Avoid Initiating Chargebacks:',
      'Should you make a payment and then request a chargeback despite the seller meeting their obligations, we reserve the right to suspend your account, pursue legal action, and/or seek compensation for damages.',
      'Cancel Orders for Valid Reasons Only:',
      'Repeated cancellations of orders may lead to the suspension of your bidding privileges. Further, such actions could result in the suspension of your account and the imposition of penalties.',
      'Adherence to Our Terms and Conditions:',
      'It is crucial that you follow our Terms and Conditions, which include these Buyer Terms as well as our various guidelines and policies.',
    ],
  },
  {
    title: 'Failing to Meet Your Responsibilities as a Buyer',
    content: [
      'Outcomes for failing to meet your responsibilities:',
      'Failure to meet your obligations to us or a seller may lead to the suspension of your bidding rights, account, or access to our services, along with possible sale cancellation or other actions as specified in our Terms & Conditions. Typically, such situations will immediately place you in default. If not, we will issue a notice of default. Should any of these actions be necessary, we will generally notify you by email, providing the rationale for the measure taken.',
      'Right to Seek Reimbursement for Damages:',
      'Your non-compliance with your responsibilities often leads to financial losses for us. We are entitled to seek reparation for these losses. This may involve out-of-court measures, such as employing a debt collection agency, or taking legal action. In such situations, we reserve the right to demand statutory interest from the date you defaulted and any costs associated with the collection of the debt, whether in or out of court.',
      'Fraud Reporting Protocol',
      'If your actions are deemed fraudulent, we reserve the right to report such activities to domestic or international law enforcement authorities.',
    ],
  },
  {
    title: 'Seller Terms',
    content: [
      'We facilitate the transition of your cherished items to their new owners by linking you with enthusiastic and committed bidders and buyers. In order to maintain a secure and dependable marketplace for all of our users, we have established essential guidelines for sellers. These guidelines, known as the Seller Terms, are an essential component of our overall Terms. Please note that these Terms and Conditions apply to both auctions and raffles to ensure a secure and trustworthy experience for all our users. Additionally, as a seller, you have the opportunity to conduct auctions and raffles through the Outbidda platform as a business entity. For those opting for this approach, please note that there are specific terms and conditions that apply exclusively to business transactions on our platform.',
      'Supplying Information About Your Item:',
      'As a seller, you bear responsibility for your listings. When you submit an item, please ensure that you offer a comprehensive and precise description, along with any pertinent details. The information you provide must include, but is not limited to:',
      [
        'Comprehensive item condition and classification, preferably supported by documentation.',
        'Country of origin information, including whether the item is currently in its country of origin and, if relevant, the date it left its country of origin.',
        "High-quality photographs and, when possible, additional (audio-)visual material providing an accurate representation of the item's current condition, features, and unique characteristics. This should include any imperfections, defects, or shortcomings.",
        'Provenance and authenticity details for the item, along with any applicable certificate of authenticity.',
        'Documentation required for item registration, transfer, and/or export, if applicable.',
        'An estimate of shipping costs or duties, if applicable.',
        'Any additional terms or conditions pertaining to the item or the offer, such as requirements for item pickup.',
      ],
      'Accuracy in Item Descriptions:',
      'Every item you list for sale must match the description, images, and other content you provide in your listing. If there is a discrepancy, a buyer may have the right to seek remedies such as repairs, replacements, partial refunds, or, in certain instances, cancel the sale and pursue damages and/or costs from you.',
      'Modification of Item Descriptions and Item images:',
      'You acknowledge our right to make alterations to the item description or/and item images or completely remove your auction and/or raffle if the item description or/and item images is containing any kind of inappropriate content. ',
      'Your Accountability for Accurate Item Descriptions and Item Images:',
      'You are accountable for providing a precise and comprehensive item description and Item images. You ensure that the final description used accurately represents the item. In the event of an incorrect, incomplete, or inaccurate item description and item image, you accept responsibility and may be held liable by third parties, including the buyer. We are not liable for any damages or costs arising from inaccuracies or omissions in the item description.',
      'User Content Guidelines:',
      'User content refers to all information and documents that you submit to us when listing an item for sale (or subsequently), including but not limited to photographs, audiovisual material, item descriptions, authenticity certifications, specifications, opinions, messages, offers, and announcements. By using our online marketplace, you acknowledge and accept that you are solely responsible and liable for all user content you upload or provide. You also agree that any user content you submit:',
      [
        'Must not be deceptive, inappropriate, or false.',
        'Must not contain discriminatory, derogatory, offensive, or morally objectionable content, as determined by our discretion.',
        'Must not include chain letters, junk mail, spam, or links to external websites.',
        'Must not harm the reputation of other users, Outbidda, our affiliated companies, or our employees.',
        'Must comply with all applicable laws, regulations, and our Terms and Condition.',
        'Must not infringe upon the rights of third parties or otherwise violate the rights of third parties or Outbidda.',
      ],
      'User Content License:',
      'When you submit or upload user content to our online marketplace, you provide us with a free, unrestricted, worldwide, non-exclusive, perpetual, and irrevocable license to share your user content with the public and/or reproduce it (including translation) for any and all purposes. This license remains in effect even if your account is suspended, deleted, or if you choose to discontinue using our online marketplace. You retain ownership and intellectual property rights to your user content, but you agree not to assert any moral rights against us for utilizing your user content.',
      'How We Utilize Your User Content:',
      'The license enables us to advertise your items and promote our online marketplace in any format and through any channel, both online and offline.',
      'You confirm legal authority to sell the item:',
      'By listing and selling an item, you assure that you will not:',
      [
        'Violate any laws or regulations, including economic sanctions laws and regulations.',
        'Infringe upon the rights of third parties, including contractual rights and intellectual property rights.',
        'Engage in unlawful or fraudulent activities towards third parties or Outbidda.',
        'Offer your item as part of a fraudulent transaction for money laundering purposes. You guarantee that every sale you conduct will be a legitimate and authentic transaction. ',
      ],
      'You agree to exclusively list your items with us:',
      'Any items you offer for sale on our online marketplace should not be simultaneously listed on other sales channels, such as your physical shop or other online platforms. You commit to removing these items from other websites and applications if they are listed for sale on our platform.',
      'Withdrawing an item is allowed:',
      'You can withdraw an item any time after you have submitted your auction or raffle. For raffles any tickets sold will be refunded to the buyers, if you choose to withdraw your raffle. ',
      'Marketing Your Listings:',
      'You can market your listings and Outbidda using legitimate methods, including sharing them on your social media platforms, as long as these promotions do not create the impression of an affiliation with our business or services and maintain respect for our brand.',
      'Prohibited Influence on Sales:',
      'Engaging in shill bidding or any other activities aimed at artificially influencing bids or demand for your item is strictly prohibited. Such actions may result in your account suspension, penalties, or other appropriate measures being taken. ',
      'Seller Fees:',
      'To ensure that your valuable item finds a new owner among our dedicated and committed bidders and buyers, we apply a seller fee. This fee amounts to 5% of the final purchase price of an auction and 5% of each ticket sold from your raffle, excluding any applicable VAT. The seller fee is subtracted from your payment following a successful sale and is non-refundable.',
      'Cancellation of Transactions Due to Non-Payment:',
      'In cases where the buyer fails to make payment, we may issue payment reminders. However, if the buyer does not make the required payment, regardless of any reminders sent by Outbidda, we reserve the right to cancel the transaction.',
    ],
  },
  {
    title: 'Business seller terms',
    content: [
      'As a professional seller, adhering to all applicable legal and regulatory requirements is essential: ',
      'It is your responsibility to understand and fulfill your tax obligations. This encompasses a range of taxes such as Value Added Tax (VAT), goods and services taxes, as well as paying income tax on your sales.',
      'As a professional seller, you are subject to extra duties: ',
      'Under relevant laws and regulations, you must meet specific responsibilities and obligations to both us and our customers. It is expected that you will honor all of these requirements. For instance, when purchasing from you, consumers are entitled to extra protections and rights.',
      'Cancellation Policy for EU Consumers.',
      'For professional sellers, irrespective of where you are based, consumers in the EU are occasionally entitled to withdraw from a purchase within 14 days of receipt of the item. If specific criteria are satisfied, you are required by law to honor these cancellation requests. For further details, please refer to our EU & UK Consumer Rights Policy.',
    ],
  },
  {
    title: 'Shipping or pickup for Seller',
    content: [
      'Shipping Liability Disclaimer for Outbidda:',
      'Outbidda does not handle the shipping of items nor are we a shipping company. Consequently, we cannot be held accountable or liable for any damages that occur during the shipping or transportation of items.',
      'Agreement with Shipping or Transport Company:',
      'By opting for shipping or transportation of your item, you are entering into a service agreement directly with the respective shipping or transport company. In the event of any issues during the shipping or transport process, it is advisable to directly contact the company involved for an investigation. We strongly suggest thoroughly reviewing the terms and conditions of the chosen shipping or transport provider.',
      'Requirement for Valid Tracking Information on Shipments:',
      "It's mandatory to ship items with valid tracking information. We highly advise opting for registered and/or insured shipping methods that correspond to your item's value. You bear the responsibility for any damage or loss of your item during shipping until its successful delivery.",
      'Timely Shipping Post-Payment:',
      "If you haven't agreed on a pickup with the buyer, it's required to dispatch the item within three business days following the payment date.",
      'Proper Preparation for Shipping or Transport:',
      "It's your duty to meticulously pack or ready your item for shipment. Ensuring the item arrives in good condition is your responsibility. As the sender, the buyer may hold you accountable for any damage resulting from inadequate packaging or handling during transit.",
      'Reimburse Overpaid Shipping or Transport Charges:',
      'Should the real shipping or transport expenses be lower than the amount you initially quoted, you are obligated to return the difference to the buyer. In certain situations, we may handle this refund for you and subtract the relevant amount from your upcoming payouts.',
      'Direct Shipping Requirement (drop shipping):',
      'You are not permitted to delegate the shipping of the item to a third party like the manufacturer, wholesaler, retailer, or a fulfillment center, which would then directly dispatch the item to the buyer.',
      'Shipping from an Alternate Address:',
      "Should you dispatch the item from an address other than the one listed in your account, you are responsible for any additional costs that wouldn't have been incurred if it were shipped from your registered address. This includes expenses like import taxes and levies.",
      'Shipping to Countries Not on the Supported List:',
      'In the event you ship or transport an item to a country not included in our list of supported nations, it is likely you will not receive payment for that item. For the latest information on supported countries, please get in touch with us.',
      'Delay in Shipping an Item:',
      'The delivery date you suggest or the one indicated by the tracking information from the shipping company is not legally binding. This implies that the buyer does not have the automatic right to void the transaction if the item arrives later than this date. However, failure to ship or transport the item promptly may lead to the buyer, or us acting on their behalf, issuing a default notice along with a reasonable deadline for dispatch. If you fail to meet this deadline, the sales contract may be terminated.',
    ],
  },
  {
    title: 'Payout for seller',
    content: [
      "Payment Provider's Terms Regulate Payouts:",
      'Our payment transactions are securely managed through Stripe, our chosen payment provider. All payment processing activities abide by the terms set by this provider. As a seller on Outbidda, your agreement to these terms, which may be updated periodically, is a requisite for conducting sales.',
      'Applicability of Exchange Rates:',
      'The exchange rate for your item is determined by our payment providers based on the rate in effect on the date your listing becomes active. Consequently, your payout may vary in your local currency, either increasing or decreasing. Additionally, either we or our payment providers may round the purchase price to the nearest whole currency unit, either up or down, before issuing your payment.',
      "Adherence to Payment Provider's Terms and Instructions:",
      'It is essential to comply with the guidelines and requirements of our payment provider, Stripe. For instance, the receipt of your payment may hinge on providing all necessary details as requested by Stripe. Should the payment provider block a payment in line with their terms, we reserve the right to cancel the transaction. We cannot be held accountable for any losses or damages stemming from such a cancellation, nor for any shortcomings on the part of the payment service provider in executing payment services.',
      "Holding Payout Due to Buyer's Claim:",
      'In the event a buyer raises a dispute regarding your item and your payment for this item has not yet been processed, we may hold your payout until the claim is fully resolved.',
      'Holding Payout Due to Various Other Reasons:',
      'Your payout might be put on hold if a third party informs us of a problem related to your item or its sale, or if you do not meet your responsibilities to Outbidda and/or a buyer.',
      'Right to Withhold Payment:',
      'Should you fail to comply with our Terms, we maintain the right to withhold payment for the concerned items. In such instances, you acknowledge and agree to relinquish any claims against us regarding these payments.',
    ],
  },
  {
    title: 'Your Responsibilities as a Seller',
    content: [
      'Liability for Your Items:',
      'You bear complete responsibility for the sales of your items and are accountable to Outbidda and/or the buyers if there is a breach of these Terms, any sales agreement, or relevant laws.',
      'Authenticity of Your Items:',
      'You assure that all items you sell are genuine and not counterfeit which will violate any copyright, trademark, or other intellectual property rights of third parties.',
      'Ownership of User Content:',
      "Ensure that the user content you post is your own or you have the necessary permissions to use it. For instance, replicating another seller's item description is not permitted.",
      "Avoid Disruption of Other Sellers' Listings:",
      "You are prohibited from engaging in actions that disrupt other sellers' listings on Outbidda or manipulate your own listings to your advantage. This includes, but is not limited to, placing bids on other sellers’ items and then failing to pay or canceling the sale.",
      'Timely Delivery of Your Item:',
      'After selling an item and receiving payment from the buyer, you are expected to promptly ship or transport the item to them. Refusing to deliver the item without a legitimate cause (like a force majeure event) can lead to severe repercussions. Typically, failure to deliver an item to a buyer will incur a penalty.',
      'Collaborate with the Buyer to Resolve Issues:',
      'If there are shipping issues or problems with the item, we encourage you to seek a resolution with the buyer. Legal obligations often require you to offer solutions like repairs or replacements if the item is not as described. Offering a partial refund can also be an effective way to settle a dispute. In cases where you and the buyer cannot reach an agreement, we may intervene with reasonable efforts to address the claim for both parties.',
      'Adhere to Economic Sanctions Laws:',
      'Ensure compliance with all economic sanctions laws and regulations that apply to Outbidda. This includes avoiding the use of any financial institutions or third parties that could lead to or cause a breach of these laws.',
      'Exclusive Availability on Our Platform:',
      "Items listed for sale on our online marketplace must be exclusively available here and not simultaneously listed elsewhere. They should remain solely accessible through our platform for the duration of the listing's active period.",
      'Conformity Guarantee for Business Sellers:',
      'As a seller operating as a business entity within the EU or UK, you may be required to offer a statutory warranty to consumers.',
    ],
  },
  {
    title: 'Failing to Meet Your Responsibilities as a Seller',
    content: [
      'Outcomes for failing to meet your responsibilities:',
      'Failure to meet your obligations to us or a seller may lead to the suspension of your bidding rights, account, or access to our services, along with possible sale cancellation or other actions as specified in our Terms & Conditions. Typically, such situations will immediately place you in default. If not, we will issue a notice of default. Should any of these actions be necessary, we will generally notify you by email, providing the rationale for the measure taken.',
      'Seller Account Limitations',
      'In the event of an account suspension or restriction, we will make an effort to provide you with the reasons. However, should we opt to permanently restrict your account access (i.e., terminate your account), we will endeavor to notify you of the grounds at least 30 days prior to the termination. Please note that this notice period may not apply under certain circumstances, including but not limited to:',
      [
        'Compliance with a legal or regulatory mandate necessitating the termination of our services to you.',
        'Recurrent violations of our Terms on your part.',
      ],
      'Right to Seek Reimbursement for Damages:',
      'Your non-compliance with your responsibilities often leads to financial losses for us. We are entitled to seek reparation for these losses. This may involve out-of-court measures, such as employing a debt collection agency, or taking legal action. In such situations, we reserve the right to demand statutory interest from the date you defaulted and any costs associated with the collection of the debt, whether in or out of court.',
      'Fraud Reporting Protocol',
      'If your actions are deemed fraudulent, we reserve the right to report such activities to domestic or international law enforcement authorities.',
    ],
  },
];
