import store from '../store/store';
import axios from 'axios';
import { selectAccessToken, selectRefreshToken, setAccessToken, setRefreshToken } from '../store/features/authSlice';
import { setUser } from '../store/features/userSlice';
import { baseURL } from '../utils/constants';

const axiosInstance = axios.create({ baseURL, timeout: 60000 });

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = selectAccessToken(store.getState());

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !error.response?.config?.url.includes('sign-in')) {
      originalRequest._retry = true;

      try {
        const currentRefreshToken = selectRefreshToken(store.getState());
        const response = await axios.post(
          baseURL + 'auth/refresh-token',
          {},
          {
            headers: {
              accept: 'application/json',
              'refresh-token': currentRefreshToken,
            },
          }
        );
        const { accessToken, refreshToken: newRefreshToken } = response.data;

        store.dispatch(setAccessToken(accessToken));
        store.dispatch(setRefreshToken(newRefreshToken));

        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError?.response?.data?.message ?? refreshError);
        store.dispatch(setUser(null));
        store.dispatch(setAccessToken(null));
        store.dispatch(setRefreshToken(null));
        if (!window.location.search.includes('to')) window.location.href = `/login`;

        return Promise.reject({
          response: {
            data: { message: 'Your session has expired. Please re-login.' },
          },
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
